unit Test;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.DBCtrls,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCGridCell, VCL.TMSFNCGridOptions, VCL.TMSFNCCustomControl,
  VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGrid, VCL.TMSFNCGridDatabaseAdapter, VCL.TMSFNCCustomComponent,
  VCL.TMSFNCDataBinding, WEBLib.ExtCtrls, Vcl.Grids;

type
  TFrmTest = class(TForm)
    WebResponsiveGridPanel1: TResponsiveGridPanel;
    WebPanel1: TPanel;
    XD_Orderguide: TXDataWebDataSet;
    XD_OrderguideCust: TIntegerField;
    XD_OrderguideProd: TIntegerField;
    XD_OrderguideLastDate: TDateTimeField;
    XD_OrderguidePrice: TFloatField;
    XD_OrderguideProdUnit: TStringField;
    XD_Orderguidesize: TStringField;
    XD_Orderguidebrand: TStringField;
    XD_OrderguideDescr: TStringField;
    WDS_Orderguide: TDataSource;
    WebDBGrid1: TDBGrid;
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FrmTest: TFrmTest;

implementation

Uses Data;

{$R *.dfm}

procedure TFrmTest.WebFormShow(Sender: TObject);
begin
  XD_Orderguide.QueryString := '$filter=(Cust eq '''+Dm.XD_UserDatacuscode.AsString+''')';
  XD_Orderguide.Load;
end;

procedure TFrmTest.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebResponsiveGridPanel1 := TResponsiveGridPanel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  XD_Orderguide := TXDataWebDataSet.Create(Self);
  XD_OrderguideCust := TIntegerField.Create(Self);
  XD_OrderguideProd := TIntegerField.Create(Self);
  XD_OrderguideLastDate := TDateTimeField.Create(Self);
  XD_OrderguidePrice := TFloatField.Create(Self);
  XD_OrderguideProdUnit := TStringField.Create(Self);
  XD_Orderguidesize := TStringField.Create(Self);
  XD_Orderguidebrand := TStringField.Create(Self);
  XD_OrderguideDescr := TStringField.Create(Self);
  WDS_Orderguide := TDataSource.Create(Self);

  WebResponsiveGridPanel1.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  XD_Orderguide.BeforeLoadDFMValues;
  XD_OrderguideCust.BeforeLoadDFMValues;
  XD_OrderguideProd.BeforeLoadDFMValues;
  XD_OrderguideLastDate.BeforeLoadDFMValues;
  XD_OrderguidePrice.BeforeLoadDFMValues;
  XD_OrderguideProdUnit.BeforeLoadDFMValues;
  XD_Orderguidesize.BeforeLoadDFMValues;
  XD_Orderguidebrand.BeforeLoadDFMValues;
  XD_OrderguideDescr.BeforeLoadDFMValues;
  WDS_Orderguide.BeforeLoadDFMValues;
  try
    Name := 'FrmTest';
    Width := 1104;
    Height := 838;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebResponsiveGridPanel1.SetParentComponent(Self);
    WebResponsiveGridPanel1.Name := 'WebResponsiveGridPanel1';
    WebResponsiveGridPanel1.Left := 0;
    WebResponsiveGridPanel1.Top := 0;
    WebResponsiveGridPanel1.Width := 1104;
    WebResponsiveGridPanel1.Height := 838;
    WebResponsiveGridPanel1.HeightPercent := 100.000000000000000000;
    WebResponsiveGridPanel1.WidthPercent := 100.000000000000000000;
    WebResponsiveGridPanel1.Align := alClient;
    WebResponsiveGridPanel1.ControlCollection.Clear;
    with WebResponsiveGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebPanel1;
    end;
    WebResponsiveGridPanel1.Color := clWhite;
    WebResponsiveGridPanel1.ElementFont := efCSS;
    WebResponsiveGridPanel1.Layout.Clear;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebPanel1.SetParentComponent(WebResponsiveGridPanel1);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 673;
    WebPanel1.Height := 609;
    WebPanel1.ElementClassName := 'card';
    WebPanel1.HeightPercent := 100.000000000000000000;
    WebPanel1.WidthPercent := 100.000000000000000000;
    WebPanel1.Align := alClient;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ElementBodyClassName := 'card-body';
    WebPanel1.ElementFont := efCSS;
    WebDBGrid1.SetParentComponent(WebPanel1);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 3;
    WebDBGrid1.Top := 16;
    WebDBGrid1.Width := 547;
    WebDBGrid1.Height := 542;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Prod';
      Title := 'Prod';
      Width := 54;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'ProdUnit';
      Title := 'Unit';
      Width := 43;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'size';
      Title := 'Size';
      Width := 55;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'brand';
      Title := 'Brand';
      Width := 56;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Descr';
      Title := 'Description';
      Width := 207;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Price';
      Title := 'Price';
    end;
    with WebDBGrid1.Columns.Add do
    begin
      Title := 'Qty';
      Width := 39;
    end;
    WebDBGrid1.DataSource := WDS_Orderguide;
    WebDBGrid1.ElementFont := efCSS;
    WebDBGrid1.FixedCols := 0;
    WebDBGrid1.Font.Charset := DEFAULT_CHARSET;
    WebDBGrid1.Font.Color := clWindowText;
    WebDBGrid1.Font.Height := -13;
    WebDBGrid1.Font.Name := 'Tahoma';
    WebDBGrid1.Font.Style := [];
    WebDBGrid1.ParentFont := False;
    WebDBGrid1.TabOrder := 0;
    WebDBGrid1.HeightPercent := 100.000000000000000000;
    WebDBGrid1.WidthPercent := 100.000000000000000000;
    WebDBGrid1.ColWidths[0] := 54;
    WebDBGrid1.ColWidths[1] := 43;
    WebDBGrid1.ColWidths[2] := 55;
    WebDBGrid1.ColWidths[3] := 56;
    WebDBGrid1.ColWidths[4] := 207;
    WebDBGrid1.ColWidths[5] := 64;
    WebDBGrid1.ColWidths[6] := 39;
    XD_Orderguide.SetParentComponent(Self);
    XD_Orderguide.Name := 'XD_Orderguide';
    XD_Orderguide.EntitySetName := 'XS_CustOrderGuide';
    XD_Orderguide.Connection := Dm.ApiConnection;
    XD_Orderguide.Left := 728;
    XD_Orderguide.Top := 664;
    XD_OrderguideCust.SetParentComponent(XD_Orderguide);
    XD_OrderguideCust.Name := 'XD_OrderguideCust';
    XD_OrderguideCust.FieldName := 'Cust';
    XD_OrderguideCust.Required := True;
    XD_OrderguideProd.SetParentComponent(XD_Orderguide);
    XD_OrderguideProd.Name := 'XD_OrderguideProd';
    XD_OrderguideProd.FieldName := 'Prod';
    XD_OrderguideProd.Required := True;
    XD_OrderguideLastDate.SetParentComponent(XD_Orderguide);
    XD_OrderguideLastDate.Name := 'XD_OrderguideLastDate';
    XD_OrderguideLastDate.FieldName := 'LastDate';
    XD_OrderguidePrice.SetParentComponent(XD_Orderguide);
    XD_OrderguidePrice.Name := 'XD_OrderguidePrice';
    XD_OrderguidePrice.FieldName := 'Price';
    XD_OrderguidePrice.DisplayFormat := '0.00';
    XD_OrderguideProdUnit.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdUnit.Name := 'XD_OrderguideProdUnit';
    XD_OrderguideProdUnit.FieldName := 'ProdUnit';
    XD_OrderguideProdUnit.Size := 3;
    XD_Orderguidesize.SetParentComponent(XD_Orderguide);
    XD_Orderguidesize.Name := 'XD_Orderguidesize';
    XD_Orderguidesize.FieldName := 'size';
    XD_Orderguidesize.Size := 8;
    XD_Orderguidebrand.SetParentComponent(XD_Orderguide);
    XD_Orderguidebrand.Name := 'XD_Orderguidebrand';
    XD_Orderguidebrand.FieldName := 'brand';
    XD_Orderguidebrand.Size := 6;
    XD_OrderguideDescr.SetParentComponent(XD_Orderguide);
    XD_OrderguideDescr.Name := 'XD_OrderguideDescr';
    XD_OrderguideDescr.FieldName := 'Descr';
    XD_OrderguideDescr.Size := 25;
    WDS_Orderguide.SetParentComponent(Self);
    WDS_Orderguide.Name := 'WDS_Orderguide';
    WDS_Orderguide.DataSet := XD_Orderguide;
    WDS_Orderguide.Left := 784;
    WDS_Orderguide.Top := 688;
  finally
    WebResponsiveGridPanel1.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    XD_Orderguide.AfterLoadDFMValues;
    XD_OrderguideCust.AfterLoadDFMValues;
    XD_OrderguideProd.AfterLoadDFMValues;
    XD_OrderguideLastDate.AfterLoadDFMValues;
    XD_OrderguidePrice.AfterLoadDFMValues;
    XD_OrderguideProdUnit.AfterLoadDFMValues;
    XD_Orderguidesize.AfterLoadDFMValues;
    XD_Orderguidebrand.AfterLoadDFMValues;
    XD_OrderguideDescr.AfterLoadDFMValues;
    WDS_Orderguide.AfterLoadDFMValues;
  end;
end;

end.
