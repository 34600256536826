unit Catalog;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.DB, WEBLib.Grids, WEBLib.DBCtrls, Vcl.Controls,
  WEBLib.ExtCtrls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.Buttons;

type
  TFrmCatalog = class(TForm)
    XD_Catalog: TXDataWebDataSet;
    XD_CatalogProd: TIntegerField;
    XD_CatalogUnit: TStringField;
    XD_CatalogSize: TStringField;
    XD_CatalogBrand: TStringField;
    XD_CatalogDescription: TStringField;
    XD_Catalogcuscode: TIntegerField;
    XD_CatalogSellPrice: TFloatField;
    XD_CatalogCWFlag: TStringField;
    XD_CatalogWeight: TIntegerField;
    XD_CatalogCat: TIntegerField;
    XD_CatalogServings: TIntegerField;
    XD_CatalogServSize: TIntegerField;
    XD_CatalogServUnit: TStringField;
    XD_CatalogOH: TIntegerField;
    XD_CatalogDueDate: TIntegerField;
    XD_CatalogUPC: TIntegerField;
    XD_CatalogManuf: TStringField;
    XD_CatalogLoc: TStringField;
    XD_CatalogPhoto1: TStringField;
    XD_CatalogPhoto2: TStringField;
    XD_CatalogPhoto3: TStringField;
    XD_CatalogPhoto4: TStringField;
    XD_CatalogAllocatedQty: TIntegerField;
    WDS_Catalog: TDataSource;
    WebPanel1: TPanel;
    catGrid: TDBResponsiveGrid;
    PanelFooter: TPanel;
    WebButton1: TButton;
    WebButton2: TButton;
    catAddToOGBtn: TButton;
    WebButton5: TButton;
    WebButton6: TButton;
    WebButton7: TButton;
    WebButton8: TButton;
    WebButton9: TButton;
    MenuBtn: TSpeedButton;
    WebButton4: TButton;
    PanelSearch: TPanel;
    editSearch: TEdit;
    WebButton10: TButton;
    MenuPnl: TPanel;
    PanelHeader: TPanel;
    XD_Orderguide: TXDataWebDataSet;
    XD_OrderguideCust: TIntegerField;
    XD_OrderguideProd: TIntegerField;
    XD_OrderguideQty: TIntegerField;
    XD_OrderguideProdUnit: TStringField;
    XD_OrderguideProdSize: TStringField;
    XD_OrderguideProdBrand: TStringField;
    XD_OrderguideProdDescription: TStringField;
    XD_OrderguideProdCWFlag: TStringField;
    XD_OrderguideProdServing: TIntegerField;
    XD_OrderguideProdServingSize: TStringField;
    XD_OrderguideProdServingUnit: TStringField;
    XD_OrderguideProdOnHand: TIntegerField;
    XD_OrderguideProdDueDate: TIntegerField;
    XD_OrderguideProdSlot: TStringField;
    XD_OrderguideProdCat: TIntegerField;
    XD_OrderguideYTDUnits: TIntegerField;
    XD_OrderguideMTDUnits: TIntegerField;
    XD_OrderguidePrice: TFloatField;
    WDS_Orderguide: TDataSource;
    XD_OrderguideLastPurch: TDateTimeField;
    WebButton3: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure editSearchClick(Sender: TObject);
    procedure editSearchChange(Sender: TObject);
    procedure WebButton10Click(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure catAddToOGBtnClick(Sender: TObject);
    procedure MenuBtnClick(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure XD_OrderguideAfterOpen(DataSet: TDataSet);
    procedure XD_OrderguideAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormResize(Sender: TObject);
    procedure WebButton8Click(Sender: TObject);
    procedure WebButton6Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FrmCatalog: TFrmCatalog;
  StartPos:Integer=50;
  Limit:Integer=50;

implementation

Uses Data,Pricebook,Orderguide,Statement,AccountInfo;

{$R *.dfm}

procedure TFrmCatalog.catAddToOGBtnClick(Sender: TObject);
begin
  XD_Orderguide.QueryString := '$filter=(Cust eq '''+Dm.XD_CustCust.AsString+''' and Prod eq '''+XD_CatalogProd.AsString+''')';
  XD_Orderguide.Load;
end;

procedure TFrmCatalog.editSearchChange(Sender: TObject);
begin
  XD_Catalog.Close;
  XD_Catalog.QueryString := '$filter='
      +'(cuscode eq '''+IntToStr(CurrentCustNumber)+''' and '
      +'(contains(Prod,'''+editSearch.Text+''') or contains(Description,'''+editSearch.Text+''') or contains(Brand,'''+editSearch.Text+''')))';
  XD_Catalog.Load;
end;

procedure TFrmCatalog.editSearchClick(Sender: TObject);
begin
  editSearch.SelectAll;
end;

procedure TFrmCatalog.MenuBtnClick(Sender: TObject);
begin
  MenuPnl.Left := MenuBtn.Left;
  MenuPnl.Top := MenuBtn.Top + MenuBtn.Height + 5;
  MenuPnl.BringToFront;
  if MenuPnl.Visible then MenuPnl.Visible := False
    else MenuPnl.Visible := True;
end;

procedure TFrmCatalog.WebButton10Click(Sender: TObject);
begin
  StartPos := 1;
  XD_Catalog.Close;
  XD_Catalog.QueryString := '$filter=(cuscode eq '''+IntToStr(CurrentCustNumber)+''')&$top='+IntToStr(Limit)+'&$skip='+IntToStr(StartPos);
  XD_Catalog.Load;
end;

procedure TFrmCatalog.WebButton1Click(Sender: TObject);
begin
  if StartPos > 0 then
  begin
    StartPos := StartPos - Limit;
    XD_Catalog.Close;
    XD_Catalog.QueryString := '$filter=(cuscode eq '''+IntToStr(CurrentCustNumber)+''')&$top='+IntToStr(Limit)+'&$skip='+IntToStr(StartPos);
    XD_Catalog.Load;
  end;
end;

procedure TFrmCatalog.WebButton2Click(Sender: TObject);
begin
  StartPos := StartPos + Limit;
  XD_Catalog.Close;
  XD_Catalog.QueryString := '$filter=(cuscode eq '''+IntToStr(CurrentCustNumber)+''')&$top='+IntToStr(Limit)+'&$skip='+IntToStr(StartPos);
  XD_Catalog.Load;
end;

procedure TFrmCatalog.WebButton4Click(Sender: TObject);
begin
  Application.CreateForm(TFrmPricebook, FrmPricebook);
end;

procedure TFrmCatalog.WebButton5Click(Sender: TObject);
begin
  Application.CreateForm(TFrmOrderguide, FrmOrderguide);
end;

procedure TFrmCatalog.WebButton6Click(Sender: TObject);
begin
  Application.CreateForm(TFrmAccountInfo, FrmAccountInfo);
end;

procedure TFrmCatalog.WebButton8Click(Sender: TObject);
begin
  Application.CreateForm(TFrmStatement, FrmStatement);
end;

procedure TFrmCatalog.WebFormResize(Sender: TObject);
begin
  catGrid.Options.ItemWidthMin := catGrid.Width;
end;

procedure TFrmCatalog.WebFormShow(Sender: TObject);
begin
  XD_Catalog.QueryString := '$filter=(cuscode eq '''+IntToStr(CurrentCustNumber)+''')&$top='+IntToStr(Limit)+'&$skip='+IntToStr(StartPos);
  XD_Catalog.Load;
end;

procedure TFrmCatalog.XD_OrderguideAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
begin
  Application.CreateForm(TFrmOrderguide, FrmOrderguide);
end;

procedure TFrmCatalog.XD_OrderguideAfterOpen(DataSet: TDataSet);
begin
  if XD_Orderguide.RecordCount = 0 then
  begin
    XD_Orderguide.Append;
    XD_OrderguideCust.AsInteger := XD_Catalogcuscode.AsInteger;
    XD_OrderguideProd.AsInteger := XD_CatalogProd.AsInteger;
    XD_OrderguideQty.AsInteger := 0;
    XD_OrderguideProdUnit.AsString := XD_CatalogUnit.AsString;
    XD_OrderguideProdSize.AsString := XD_CatalogSize.AsString;
    XD_OrderguideProdBrand.AsString := XD_CatalogBrand.AsString;
    XD_OrderguideProdDescription.AsString := XD_CatalogDescription.AsString;
    XD_OrderguideProdCWFlag.AsString := XD_CatalogCWFlag.AsString;
    XD_OrderguideProdServing.AsInteger := XD_CatalogServings.AsInteger;
    XD_OrderguideProdServingSize.AsString := XD_CatalogServSize.AsString;
    XD_OrderguideProdServingUnit.AsString := XD_CatalogServUnit.AsString;
    XD_OrderguideProdOnHand.AsInteger := XD_CatalogOH.AsInteger;
    XD_OrderguideProdDueDate.AsString := XD_CatalogDueDate.AsString;
    XD_OrderguideProdSlot.AsString := '';
    XD_OrderguideProdCat.AsInteger := XD_CatalogCat.AsInteger;
    XD_OrderguideYTDUnits.AsInteger := 0;
    XD_OrderguideMTDUnits.AsInteger := 0;
    XD_OrderguidePrice.AsFloat := XD_CatalogSellPrice.AsFloat;
    XD_Orderguide.Post;
    XD_Orderguide.ApplyUpdates;
  end
  else
  begin
    showmessage('Product Already in Orderguide');
    XD_Orderguide.Close;
  end;
end;

procedure TFrmCatalog.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  catGrid := TDBResponsiveGrid.Create('catGrid');
  PanelFooter := TPanel.Create('catPanelFooter');
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  catAddToOGBtn := TButton.Create(Self);
  PanelHeader := TPanel.Create('catPanelHeader');
  MenuBtn := TSpeedButton.Create(Self);
  PanelSearch := TPanel.Create('catPanelSearch');
  editSearch := TEdit.Create(Self);
  WebButton10 := TButton.Create(Self);
  MenuPnl := TPanel.Create('catPanelMenu');
  WebButton5 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);
  WebButton9 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  XD_Catalog := TXDataWebDataSet.Create(Self);
  XD_CatalogProd := TIntegerField.Create(Self);
  XD_CatalogUnit := TStringField.Create(Self);
  XD_CatalogSize := TStringField.Create(Self);
  XD_CatalogBrand := TStringField.Create(Self);
  XD_CatalogDescription := TStringField.Create(Self);
  XD_Catalogcuscode := TIntegerField.Create(Self);
  XD_CatalogSellPrice := TFloatField.Create(Self);
  XD_CatalogCWFlag := TStringField.Create(Self);
  XD_CatalogWeight := TIntegerField.Create(Self);
  XD_CatalogCat := TIntegerField.Create(Self);
  XD_CatalogServings := TIntegerField.Create(Self);
  XD_CatalogServSize := TIntegerField.Create(Self);
  XD_CatalogServUnit := TStringField.Create(Self);
  XD_CatalogOH := TIntegerField.Create(Self);
  XD_CatalogDueDate := TIntegerField.Create(Self);
  XD_CatalogUPC := TIntegerField.Create(Self);
  XD_CatalogManuf := TStringField.Create(Self);
  XD_CatalogLoc := TStringField.Create(Self);
  XD_CatalogPhoto1 := TStringField.Create(Self);
  XD_CatalogPhoto2 := TStringField.Create(Self);
  XD_CatalogPhoto3 := TStringField.Create(Self);
  XD_CatalogPhoto4 := TStringField.Create(Self);
  XD_CatalogAllocatedQty := TIntegerField.Create(Self);
  WDS_Catalog := TDataSource.Create(Self);
  XD_Orderguide := TXDataWebDataSet.Create(Self);
  XD_OrderguideCust := TIntegerField.Create(Self);
  XD_OrderguideProd := TIntegerField.Create(Self);
  XD_OrderguideQty := TIntegerField.Create(Self);
  XD_OrderguideProdUnit := TStringField.Create(Self);
  XD_OrderguideProdSize := TStringField.Create(Self);
  XD_OrderguideProdBrand := TStringField.Create(Self);
  XD_OrderguideProdDescription := TStringField.Create(Self);
  XD_OrderguideProdCWFlag := TStringField.Create(Self);
  XD_OrderguideProdServing := TIntegerField.Create(Self);
  XD_OrderguideProdServingSize := TStringField.Create(Self);
  XD_OrderguideProdServingUnit := TStringField.Create(Self);
  XD_OrderguideProdOnHand := TIntegerField.Create(Self);
  XD_OrderguideProdDueDate := TIntegerField.Create(Self);
  XD_OrderguideProdSlot := TStringField.Create(Self);
  XD_OrderguideProdCat := TIntegerField.Create(Self);
  XD_OrderguideYTDUnits := TIntegerField.Create(Self);
  XD_OrderguideMTDUnits := TIntegerField.Create(Self);
  XD_OrderguidePrice := TFloatField.Create(Self);
  XD_OrderguideLastPurch := TDateTimeField.Create(Self);
  WDS_Orderguide := TDataSource.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  catGrid.BeforeLoadDFMValues;
  PanelFooter.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  catAddToOGBtn.BeforeLoadDFMValues;
  PanelHeader.BeforeLoadDFMValues;
  MenuBtn.BeforeLoadDFMValues;
  PanelSearch.BeforeLoadDFMValues;
  editSearch.BeforeLoadDFMValues;
  WebButton10.BeforeLoadDFMValues;
  MenuPnl.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  WebButton9.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  XD_Catalog.BeforeLoadDFMValues;
  XD_CatalogProd.BeforeLoadDFMValues;
  XD_CatalogUnit.BeforeLoadDFMValues;
  XD_CatalogSize.BeforeLoadDFMValues;
  XD_CatalogBrand.BeforeLoadDFMValues;
  XD_CatalogDescription.BeforeLoadDFMValues;
  XD_Catalogcuscode.BeforeLoadDFMValues;
  XD_CatalogSellPrice.BeforeLoadDFMValues;
  XD_CatalogCWFlag.BeforeLoadDFMValues;
  XD_CatalogWeight.BeforeLoadDFMValues;
  XD_CatalogCat.BeforeLoadDFMValues;
  XD_CatalogServings.BeforeLoadDFMValues;
  XD_CatalogServSize.BeforeLoadDFMValues;
  XD_CatalogServUnit.BeforeLoadDFMValues;
  XD_CatalogOH.BeforeLoadDFMValues;
  XD_CatalogDueDate.BeforeLoadDFMValues;
  XD_CatalogUPC.BeforeLoadDFMValues;
  XD_CatalogManuf.BeforeLoadDFMValues;
  XD_CatalogLoc.BeforeLoadDFMValues;
  XD_CatalogPhoto1.BeforeLoadDFMValues;
  XD_CatalogPhoto2.BeforeLoadDFMValues;
  XD_CatalogPhoto3.BeforeLoadDFMValues;
  XD_CatalogPhoto4.BeforeLoadDFMValues;
  XD_CatalogAllocatedQty.BeforeLoadDFMValues;
  WDS_Catalog.BeforeLoadDFMValues;
  XD_Orderguide.BeforeLoadDFMValues;
  XD_OrderguideCust.BeforeLoadDFMValues;
  XD_OrderguideProd.BeforeLoadDFMValues;
  XD_OrderguideQty.BeforeLoadDFMValues;
  XD_OrderguideProdUnit.BeforeLoadDFMValues;
  XD_OrderguideProdSize.BeforeLoadDFMValues;
  XD_OrderguideProdBrand.BeforeLoadDFMValues;
  XD_OrderguideProdDescription.BeforeLoadDFMValues;
  XD_OrderguideProdCWFlag.BeforeLoadDFMValues;
  XD_OrderguideProdServing.BeforeLoadDFMValues;
  XD_OrderguideProdServingSize.BeforeLoadDFMValues;
  XD_OrderguideProdServingUnit.BeforeLoadDFMValues;
  XD_OrderguideProdOnHand.BeforeLoadDFMValues;
  XD_OrderguideProdDueDate.BeforeLoadDFMValues;
  XD_OrderguideProdSlot.BeforeLoadDFMValues;
  XD_OrderguideProdCat.BeforeLoadDFMValues;
  XD_OrderguideYTDUnits.BeforeLoadDFMValues;
  XD_OrderguideMTDUnits.BeforeLoadDFMValues;
  XD_OrderguidePrice.BeforeLoadDFMValues;
  XD_OrderguideLastPurch.BeforeLoadDFMValues;
  WDS_Orderguide.BeforeLoadDFMValues;
  try
    Name := 'FrmCatalog';
    Width := 1005;
    Height := 792;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 60;
    WebPanel1.Width := 1005;
    WebPanel1.Height := 672;
    WebPanel1.ElementClassName := 'card';
    WebPanel1.HeightPercent := 100.000000000000000000;
    WebPanel1.WidthPercent := 100.000000000000000000;
    WebPanel1.Align := alClient;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ElementBodyClassName := 'card-body';
    WebPanel1.ElementFont := efCSS;
    catGrid.SetParentComponent(WebPanel1);
    catGrid.Name := 'catGrid';
    catGrid.Left := 0;
    catGrid.Top := 0;
    catGrid.Width := 1005;
    catGrid.Height := 672;
    catGrid.HeightPercent := 100.000000000000000000;
    catGrid.WidthPercent := 100.000000000000000000;
    catGrid.Align := alClient;
    catGrid.ElementFont := efCSS;
    catGrid.ItemIndex := 0;
    catGrid.Options.ItemBorderColor := clBlack;
    catGrid.Options.ItemGap := 0;
    catGrid.Options.ItemHeight := 80;
    catGrid.Options.ItemSelectedColor := clHighlight;
    catGrid.Options.ItemSelectedBorderColor := clBlack;
    catGrid.Options.ItemSelectedTextColor := clWhite;
    catGrid.Options.ItemTemplate := '(%Prod%) (%Unit%) (%Size%) <IMG src="https://sales.mymvf.com/img/tmb/(%Prod%)_t.jpg" style="float:right;width:75px;height:75px;" onerror="this.style.display='#39'none'#39'"> <br> (%Brand%) (%Description%) <br> Price:<b>(%SellPrice%)</b> O/H:<b>(%OH%)</b>';
    catGrid.Options.ItemWidthMin := 325;
    catGrid.DataSource := WDS_Catalog;
    PanelFooter.SetParentComponent(Self);
    PanelFooter.Name := 'PanelFooter';
    PanelFooter.Left := 0;
    PanelFooter.Top := 732;
    PanelFooter.Width := 1005;
    PanelFooter.Height := 60;
    PanelFooter.ElementClassName := 'card';
    PanelFooter.HeightPercent := 100.000000000000000000;
    PanelFooter.WidthPercent := 100.000000000000000000;
    PanelFooter.Align := alBottom;
    PanelFooter.BorderColor := clSilver;
    PanelFooter.BorderStyle := bsSingle;
    PanelFooter.ChildOrder := 1;
    PanelFooter.ElementBodyClassName := 'card-body';
    PanelFooter.ElementFont := efCSS;
    WebButton1.SetParentComponent(PanelFooter);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 216;
    WebButton1.Top := 9;
    WebButton1.Width := 49;
    WebButton1.Height := 35;
    WebButton1.Caption := '<--';
    WebButton1.ElementClassName := 'btn btn-dark';
    WebButton1.ElementFont := efCSS;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(PanelFooter);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 271;
    WebButton2.Top := 9;
    WebButton2.Width := 50;
    WebButton2.Height := 35;
    WebButton2.Caption := '-->';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn btn-dark';
    WebButton2.ElementFont := efCSS;
    WebButton2.HeightStyle := ssAuto;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    catAddToOGBtn.SetParentComponent(PanelFooter);
    catAddToOGBtn.Name := 'catAddToOGBtn';
    catAddToOGBtn.Left := 3;
    catAddToOGBtn.Top := 6;
    catAddToOGBtn.Width := 182;
    catAddToOGBtn.Height := 43;
    catAddToOGBtn.Caption := 'Add to OrderGuide';
    catAddToOGBtn.ChildOrder := 2;
    catAddToOGBtn.ElementClassName := 'btn btn-info';
    catAddToOGBtn.ElementFont := efCSS;
    catAddToOGBtn.HeightPercent := 100.000000000000000000;
    catAddToOGBtn.WidthPercent := 100.000000000000000000;
    SetEvent(catAddToOGBtn, Self, 'OnClick', 'catAddToOGBtnClick');
    PanelHeader.SetParentComponent(Self);
    PanelHeader.Name := 'PanelHeader';
    PanelHeader.Left := 0;
    PanelHeader.Top := 0;
    PanelHeader.Width := 1005;
    PanelHeader.Height := 60;
    PanelHeader.ElementClassName := 'card';
    PanelHeader.HeightPercent := 100.000000000000000000;
    PanelHeader.WidthPercent := 100.000000000000000000;
    PanelHeader.Align := alTop;
    PanelHeader.BorderColor := clSilver;
    PanelHeader.BorderStyle := bsSingle;
    PanelHeader.ChildOrder := 2;
    PanelHeader.ElementBodyClassName := 'card-body';
    PanelHeader.ElementFont := efCSS;
    MenuBtn.SetParentComponent(PanelHeader);
    MenuBtn.Name := 'MenuBtn';
    MenuBtn.Left := 6;
    MenuBtn.Top := 10;
    MenuBtn.Width := 43;
    MenuBtn.Height := 42;
    MenuBtn.Color := clNone;
    MenuBtn.ElementClassName := 'btn btn-dark';
    MenuBtn.ElementFont := efCSS;
    MenuBtn.HeightPercent := 100.000000000000000000;
    MenuBtn.MaterialGlyph := 'menu';
    MenuBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MenuBtn, Self, 'OnClick', 'MenuBtnClick');
    PanelSearch.SetParentComponent(PanelHeader);
    PanelSearch.Name := 'PanelSearch';
    PanelSearch.Left := 698;
    PanelSearch.Top := 0;
    PanelSearch.Width := 307;
    PanelSearch.Height := 60;
    PanelSearch.ElementClassName := 'card border-0';
    PanelSearch.HeightPercent := 100.000000000000000000;
    PanelSearch.WidthPercent := 100.000000000000000000;
    PanelSearch.Align := alRight;
    PanelSearch.BorderColor := clSilver;
    PanelSearch.BorderStyle := bsSingle;
    PanelSearch.ChildOrder := 1;
    PanelSearch.ElementBodyClassName := 'card-body';
    PanelSearch.ElementFont := efCSS;
    editSearch.SetParentComponent(PanelSearch);
    editSearch.Name := 'editSearch';
    editSearch.Left := 7;
    editSearch.Top := 17;
    editSearch.Width := 214;
    editSearch.Height := 22;
    editSearch.ChildOrder := 1;
    editSearch.ElementClassName := 'form-control';
    editSearch.ElementFont := efCSS;
    editSearch.HeightPercent := 100.000000000000000000;
    editSearch.Text := 'Search Catalog Here....';
    editSearch.WidthPercent := 100.000000000000000000;
    SetEvent(editSearch, Self, 'OnChange', 'editSearchChange');
    SetEvent(editSearch, Self, 'OnClick', 'editSearchClick');
    WebButton10.SetParentComponent(PanelSearch);
    WebButton10.Name := 'WebButton10';
    WebButton10.Left := 227;
    WebButton10.Top := 7;
    WebButton10.Width := 73;
    WebButton10.Height := 47;
    WebButton10.Caption := 'Reset';
    WebButton10.ChildOrder := 2;
    WebButton10.ElementClassName := 'btn btn-warning';
    WebButton10.ElementFont := efCSS;
    WebButton10.HeightStyle := ssAuto;
    WebButton10.HeightPercent := 100.000000000000000000;
    WebButton10.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton10, Self, 'OnClick', 'WebButton10Click');
    MenuPnl.SetParentComponent(Self);
    MenuPnl.Name := 'MenuPnl';
    MenuPnl.Left := 8;
    MenuPnl.Top := 66;
    MenuPnl.Width := 275;
    MenuPnl.Height := 506;
    MenuPnl.ElementClassName := 'card';
    MenuPnl.HeightStyle := ssPercent;
    MenuPnl.HeightPercent := 80.000000000000000000;
    MenuPnl.WidthPercent := 100.000000000000000000;
    MenuPnl.BorderColor := clSilver;
    MenuPnl.BorderStyle := bsSingle;
    MenuPnl.ChildOrder := 2;
    MenuPnl.ElementBodyClassName := 'card-body';
    MenuPnl.ElementFont := efCSS;
    MenuPnl.Visible := False;
    WebButton5.SetParentComponent(MenuPnl);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 12;
    WebButton5.Top := 16;
    WebButton5.Width := 249;
    WebButton5.Height := 33;
    WebButton5.Caption := 'OrderGuide';
    WebButton5.ChildOrder := 1;
    WebButton5.ElementClassName := 'btn btn-success';
    WebButton5.ElementFont := efCSS;
    WebButton5.HeightStyle := ssAuto;
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton6.SetParentComponent(MenuPnl);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 12;
    WebButton6.Top := 94;
    WebButton6.Width := 249;
    WebButton6.Height := 33;
    WebButton6.Caption := 'Account Info';
    WebButton6.ChildOrder := 1;
    WebButton6.ElementClassName := 'btn btn-success';
    WebButton6.ElementFont := efCSS;
    WebButton6.HeightStyle := ssAuto;
    WebButton6.HeightPercent := 100.000000000000000000;
    WebButton6.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
    WebButton7.SetParentComponent(MenuPnl);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 12;
    WebButton7.Top := 133;
    WebButton7.Width := 249;
    WebButton7.Height := 33;
    WebButton7.Caption := 'Payment';
    WebButton7.ChildOrder := 1;
    WebButton7.ElementClassName := 'btn btn-success';
    WebButton7.ElementFont := efCSS;
    WebButton7.HeightStyle := ssAuto;
    WebButton7.HeightPercent := 100.000000000000000000;
    WebButton7.WidthPercent := 100.000000000000000000;
    WebButton8.SetParentComponent(MenuPnl);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 12;
    WebButton8.Top := 172;
    WebButton8.Width := 249;
    WebButton8.Height := 33;
    WebButton8.Caption := 'Statement';
    WebButton8.ChildOrder := 1;
    WebButton8.ElementClassName := 'btn btn-success';
    WebButton8.ElementFont := efCSS;
    WebButton8.HeightStyle := ssAuto;
    WebButton8.HeightPercent := 100.000000000000000000;
    WebButton8.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton8, Self, 'OnClick', 'WebButton8Click');
    WebButton9.SetParentComponent(MenuPnl);
    WebButton9.Name := 'WebButton9';
    WebButton9.Left := 12;
    WebButton9.Top := 211;
    WebButton9.Width := 249;
    WebButton9.Height := 33;
    WebButton9.Caption := 'Reports';
    WebButton9.ChildOrder := 1;
    WebButton9.ElementClassName := 'btn btn-success';
    WebButton9.ElementFont := efCSS;
    WebButton9.HeightStyle := ssAuto;
    WebButton9.HeightPercent := 100.000000000000000000;
    WebButton9.WidthPercent := 100.000000000000000000;
    WebButton4.SetParentComponent(MenuPnl);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 12;
    WebButton4.Top := 55;
    WebButton4.Width := 249;
    WebButton4.Height := 33;
    WebButton4.Caption := 'Category Listing';
    WebButton4.ChildOrder := 5;
    WebButton4.ElementClassName := 'btn btn-success';
    WebButton4.ElementFont := efCSS;
    WebButton4.HeightStyle := ssAuto;
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton3.SetParentComponent(MenuPnl);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 12;
    WebButton3.Top := 451;
    WebButton3.Width := 249;
    WebButton3.Height := 33;
    WebButton3.Caption := 'Logout';
    WebButton3.ChildOrder := 1;
    WebButton3.ElementClassName := 'btn btn-success';
    WebButton3.ElementFont := efCSS;
    WebButton3.HeightStyle := ssAuto;
    WebButton3.HeightPercent := 100.000000000000000000;
    WebButton3.WidthPercent := 100.000000000000000000;
    XD_Catalog.SetParentComponent(Self);
    XD_Catalog.Name := 'XD_Catalog';
    XD_Catalog.EntitySetName := 'CustCatalog';
    XD_Catalog.Connection := Dm.ApiConnection;
    XD_Catalog.Left := 816;
    XD_Catalog.Top := 680;
    XD_CatalogProd.SetParentComponent(XD_Catalog);
    XD_CatalogProd.Name := 'XD_CatalogProd';
    XD_CatalogProd.FieldName := 'Prod';
    XD_CatalogProd.Required := True;
    XD_CatalogUnit.SetParentComponent(XD_Catalog);
    XD_CatalogUnit.Name := 'XD_CatalogUnit';
    XD_CatalogUnit.FieldName := 'Unit';
    XD_CatalogUnit.Size := 6;
    XD_CatalogSize.SetParentComponent(XD_Catalog);
    XD_CatalogSize.Name := 'XD_CatalogSize';
    XD_CatalogSize.FieldName := 'Size';
    XD_CatalogSize.Size := 6;
    XD_CatalogBrand.SetParentComponent(XD_Catalog);
    XD_CatalogBrand.Name := 'XD_CatalogBrand';
    XD_CatalogBrand.FieldName := 'Brand';
    XD_CatalogBrand.Size := 6;
    XD_CatalogDescription.SetParentComponent(XD_Catalog);
    XD_CatalogDescription.Name := 'XD_CatalogDescription';
    XD_CatalogDescription.FieldName := 'Description';
    XD_CatalogDescription.Size := 25;
    XD_Catalogcuscode.SetParentComponent(XD_Catalog);
    XD_Catalogcuscode.Name := 'XD_Catalogcuscode';
    XD_Catalogcuscode.FieldName := 'cuscode';
    XD_CatalogSellPrice.SetParentComponent(XD_Catalog);
    XD_CatalogSellPrice.Name := 'XD_CatalogSellPrice';
    XD_CatalogSellPrice.FieldName := 'SellPrice';
    XD_CatalogSellPrice.DisplayFormat := '0.00';
    XD_CatalogCWFlag.SetParentComponent(XD_Catalog);
    XD_CatalogCWFlag.Name := 'XD_CatalogCWFlag';
    XD_CatalogCWFlag.FieldName := 'CWFlag';
    XD_CatalogCWFlag.Size := 255;
    XD_CatalogWeight.SetParentComponent(XD_Catalog);
    XD_CatalogWeight.Name := 'XD_CatalogWeight';
    XD_CatalogWeight.FieldName := 'Weight';
    XD_CatalogCat.SetParentComponent(XD_Catalog);
    XD_CatalogCat.Name := 'XD_CatalogCat';
    XD_CatalogCat.FieldName := 'Cat';
    XD_CatalogServings.SetParentComponent(XD_Catalog);
    XD_CatalogServings.Name := 'XD_CatalogServings';
    XD_CatalogServings.FieldName := 'Servings';
    XD_CatalogServSize.SetParentComponent(XD_Catalog);
    XD_CatalogServSize.Name := 'XD_CatalogServSize';
    XD_CatalogServSize.FieldName := 'ServSize';
    XD_CatalogServUnit.SetParentComponent(XD_Catalog);
    XD_CatalogServUnit.Name := 'XD_CatalogServUnit';
    XD_CatalogServUnit.FieldName := 'ServUnit';
    XD_CatalogServUnit.Size := 255;
    XD_CatalogOH.SetParentComponent(XD_Catalog);
    XD_CatalogOH.Name := 'XD_CatalogOH';
    XD_CatalogOH.FieldName := 'OH';
    XD_CatalogDueDate.SetParentComponent(XD_Catalog);
    XD_CatalogDueDate.Name := 'XD_CatalogDueDate';
    XD_CatalogDueDate.FieldName := 'DueDate';
    XD_CatalogUPC.SetParentComponent(XD_Catalog);
    XD_CatalogUPC.Name := 'XD_CatalogUPC';
    XD_CatalogUPC.FieldName := 'UPC';
    XD_CatalogManuf.SetParentComponent(XD_Catalog);
    XD_CatalogManuf.Name := 'XD_CatalogManuf';
    XD_CatalogManuf.FieldName := 'Manuf';
    XD_CatalogManuf.Size := 255;
    XD_CatalogLoc.SetParentComponent(XD_Catalog);
    XD_CatalogLoc.Name := 'XD_CatalogLoc';
    XD_CatalogLoc.FieldName := 'Loc';
    XD_CatalogLoc.Size := 255;
    XD_CatalogPhoto1.SetParentComponent(XD_Catalog);
    XD_CatalogPhoto1.Name := 'XD_CatalogPhoto1';
    XD_CatalogPhoto1.FieldName := 'Photo1';
    XD_CatalogPhoto1.Size := 255;
    XD_CatalogPhoto2.SetParentComponent(XD_Catalog);
    XD_CatalogPhoto2.Name := 'XD_CatalogPhoto2';
    XD_CatalogPhoto2.FieldName := 'Photo2';
    XD_CatalogPhoto2.Size := 255;
    XD_CatalogPhoto3.SetParentComponent(XD_Catalog);
    XD_CatalogPhoto3.Name := 'XD_CatalogPhoto3';
    XD_CatalogPhoto3.FieldName := 'Photo3';
    XD_CatalogPhoto3.Size := 255;
    XD_CatalogPhoto4.SetParentComponent(XD_Catalog);
    XD_CatalogPhoto4.Name := 'XD_CatalogPhoto4';
    XD_CatalogPhoto4.FieldName := 'Photo4';
    XD_CatalogPhoto4.Size := 255;
    XD_CatalogAllocatedQty.SetParentComponent(XD_Catalog);
    XD_CatalogAllocatedQty.Name := 'XD_CatalogAllocatedQty';
    XD_CatalogAllocatedQty.FieldName := 'AllocatedQty';
    WDS_Catalog.SetParentComponent(Self);
    WDS_Catalog.Name := 'WDS_Catalog';
    WDS_Catalog.DataSet := XD_Catalog;
    WDS_Catalog.Left := 888;
    WDS_Catalog.Top := 680;
    XD_Orderguide.SetParentComponent(Self);
    XD_Orderguide.Name := 'XD_Orderguide';
    XD_Orderguide.AfterApplyUpdates := XD_OrderguideAfterApplyUpdates;
    XD_Orderguide.AfterOpen := XD_OrderguideAfterOpen;
    XD_Orderguide.EntitySetName := 'ZC_CustOrderguide';
    XD_Orderguide.Connection := Dm.ApiConnection;
    XD_Orderguide.Left := 800;
    XD_Orderguide.Top := 624;
    XD_OrderguideCust.SetParentComponent(XD_Orderguide);
    XD_OrderguideCust.Name := 'XD_OrderguideCust';
    XD_OrderguideCust.FieldName := 'Cust';
    XD_OrderguideCust.Required := True;
    XD_OrderguideProd.SetParentComponent(XD_Orderguide);
    XD_OrderguideProd.Name := 'XD_OrderguideProd';
    XD_OrderguideProd.FieldName := 'Prod';
    XD_OrderguideProd.Required := True;
    XD_OrderguideQty.SetParentComponent(XD_Orderguide);
    XD_OrderguideQty.Name := 'XD_OrderguideQty';
    XD_OrderguideQty.FieldName := 'Qty';
    XD_OrderguideProdUnit.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdUnit.Name := 'XD_OrderguideProdUnit';
    XD_OrderguideProdUnit.FieldName := 'ProdUnit';
    XD_OrderguideProdUnit.Size := 3;
    XD_OrderguideProdSize.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdSize.Name := 'XD_OrderguideProdSize';
    XD_OrderguideProdSize.FieldName := 'ProdSize';
    XD_OrderguideProdSize.Size := 8;
    XD_OrderguideProdBrand.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdBrand.Name := 'XD_OrderguideProdBrand';
    XD_OrderguideProdBrand.FieldName := 'ProdBrand';
    XD_OrderguideProdBrand.Size := 6;
    XD_OrderguideProdDescription.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdDescription.Name := 'XD_OrderguideProdDescription';
    XD_OrderguideProdDescription.FieldName := 'ProdDescription';
    XD_OrderguideProdDescription.Size := 25;
    XD_OrderguideProdCWFlag.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdCWFlag.Name := 'XD_OrderguideProdCWFlag';
    XD_OrderguideProdCWFlag.FieldName := 'ProdCWFlag';
    XD_OrderguideProdCWFlag.Size := 1;
    XD_OrderguideProdServing.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdServing.Name := 'XD_OrderguideProdServing';
    XD_OrderguideProdServing.FieldName := 'ProdServing';
    XD_OrderguideProdServingSize.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdServingSize.Name := 'XD_OrderguideProdServingSize';
    XD_OrderguideProdServingSize.FieldName := 'ProdServingSize';
    XD_OrderguideProdServingSize.Size := 3;
    XD_OrderguideProdServingUnit.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdServingUnit.Name := 'XD_OrderguideProdServingUnit';
    XD_OrderguideProdServingUnit.FieldName := 'ProdServingUnit';
    XD_OrderguideProdServingUnit.Size := 2;
    XD_OrderguideProdOnHand.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdOnHand.Name := 'XD_OrderguideProdOnHand';
    XD_OrderguideProdOnHand.FieldName := 'ProdOnHand';
    XD_OrderguideProdDueDate.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdDueDate.Name := 'XD_OrderguideProdDueDate';
    XD_OrderguideProdDueDate.FieldName := 'ProdDueDate';
    XD_OrderguideProdSlot.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdSlot.Name := 'XD_OrderguideProdSlot';
    XD_OrderguideProdSlot.FieldName := 'ProdSlot';
    XD_OrderguideProdSlot.Size := 6;
    XD_OrderguideProdCat.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdCat.Name := 'XD_OrderguideProdCat';
    XD_OrderguideProdCat.FieldName := 'ProdCat';
    XD_OrderguideYTDUnits.SetParentComponent(XD_Orderguide);
    XD_OrderguideYTDUnits.Name := 'XD_OrderguideYTDUnits';
    XD_OrderguideYTDUnits.FieldName := 'YTDUnits';
    XD_OrderguideMTDUnits.SetParentComponent(XD_Orderguide);
    XD_OrderguideMTDUnits.Name := 'XD_OrderguideMTDUnits';
    XD_OrderguideMTDUnits.FieldName := 'MTDUnits';
    XD_OrderguidePrice.SetParentComponent(XD_Orderguide);
    XD_OrderguidePrice.Name := 'XD_OrderguidePrice';
    XD_OrderguidePrice.FieldName := 'Price';
    XD_OrderguideLastPurch.SetParentComponent(XD_Orderguide);
    XD_OrderguideLastPurch.Name := 'XD_OrderguideLastPurch';
    XD_OrderguideLastPurch.FieldName := 'LastPurch';
    WDS_Orderguide.SetParentComponent(Self);
    WDS_Orderguide.Name := 'WDS_Orderguide';
    WDS_Orderguide.DataSet := XD_Orderguide;
    WDS_Orderguide.Left := 880;
    WDS_Orderguide.Top := 608;
  finally
    WebPanel1.AfterLoadDFMValues;
    catGrid.AfterLoadDFMValues;
    PanelFooter.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    catAddToOGBtn.AfterLoadDFMValues;
    PanelHeader.AfterLoadDFMValues;
    MenuBtn.AfterLoadDFMValues;
    PanelSearch.AfterLoadDFMValues;
    editSearch.AfterLoadDFMValues;
    WebButton10.AfterLoadDFMValues;
    MenuPnl.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    WebButton9.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    XD_Catalog.AfterLoadDFMValues;
    XD_CatalogProd.AfterLoadDFMValues;
    XD_CatalogUnit.AfterLoadDFMValues;
    XD_CatalogSize.AfterLoadDFMValues;
    XD_CatalogBrand.AfterLoadDFMValues;
    XD_CatalogDescription.AfterLoadDFMValues;
    XD_Catalogcuscode.AfterLoadDFMValues;
    XD_CatalogSellPrice.AfterLoadDFMValues;
    XD_CatalogCWFlag.AfterLoadDFMValues;
    XD_CatalogWeight.AfterLoadDFMValues;
    XD_CatalogCat.AfterLoadDFMValues;
    XD_CatalogServings.AfterLoadDFMValues;
    XD_CatalogServSize.AfterLoadDFMValues;
    XD_CatalogServUnit.AfterLoadDFMValues;
    XD_CatalogOH.AfterLoadDFMValues;
    XD_CatalogDueDate.AfterLoadDFMValues;
    XD_CatalogUPC.AfterLoadDFMValues;
    XD_CatalogManuf.AfterLoadDFMValues;
    XD_CatalogLoc.AfterLoadDFMValues;
    XD_CatalogPhoto1.AfterLoadDFMValues;
    XD_CatalogPhoto2.AfterLoadDFMValues;
    XD_CatalogPhoto3.AfterLoadDFMValues;
    XD_CatalogPhoto4.AfterLoadDFMValues;
    XD_CatalogAllocatedQty.AfterLoadDFMValues;
    WDS_Catalog.AfterLoadDFMValues;
    XD_Orderguide.AfterLoadDFMValues;
    XD_OrderguideCust.AfterLoadDFMValues;
    XD_OrderguideProd.AfterLoadDFMValues;
    XD_OrderguideQty.AfterLoadDFMValues;
    XD_OrderguideProdUnit.AfterLoadDFMValues;
    XD_OrderguideProdSize.AfterLoadDFMValues;
    XD_OrderguideProdBrand.AfterLoadDFMValues;
    XD_OrderguideProdDescription.AfterLoadDFMValues;
    XD_OrderguideProdCWFlag.AfterLoadDFMValues;
    XD_OrderguideProdServing.AfterLoadDFMValues;
    XD_OrderguideProdServingSize.AfterLoadDFMValues;
    XD_OrderguideProdServingUnit.AfterLoadDFMValues;
    XD_OrderguideProdOnHand.AfterLoadDFMValues;
    XD_OrderguideProdDueDate.AfterLoadDFMValues;
    XD_OrderguideProdSlot.AfterLoadDFMValues;
    XD_OrderguideProdCat.AfterLoadDFMValues;
    XD_OrderguideYTDUnits.AfterLoadDFMValues;
    XD_OrderguideMTDUnits.AfterLoadDFMValues;
    XD_OrderguidePrice.AfterLoadDFMValues;
    XD_OrderguideLastPurch.AfterLoadDFMValues;
    WDS_Orderguide.AfterLoadDFMValues;
  end;
end;

end.
