unit Pricebook;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, Vcl.Controls, WEBLib.Grids, WEBLib.DBCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, WEBLib.Buttons;

type
  TFrmPricebook = class(TForm)
    WebDBResponsiveGrid1: TDBResponsiveGrid;
    XDS_PBIndex: TXDataWebDataSet;
    WDS_PBIndex: TDataSource;
    XDS_PBIndexidxNum: TFloatField;
    XDS_PBIndexidxunit: TStringField;
    XDS_PBIndexprodNum: TIntegerField;
    XDS_PBIndexidxDesc: TStringField;
    WebPanel1: TPanel;
    WebButton1: TButton;
    XD_Catalog: TXDataWebDataSet;
    XD_CatalogProd: TIntegerField;
    XD_CatalogUnit: TStringField;
    XD_CatalogSize: TStringField;
    XD_CatalogBrand: TStringField;
    XD_CatalogDescription: TStringField;
    XD_Catalogcuscode: TIntegerField;
    XD_CatalogSellPrice: TFloatField;
    XD_CatalogCWFlag: TStringField;
    XD_CatalogWeight: TIntegerField;
    XD_CatalogCat: TIntegerField;
    XD_CatalogServings: TIntegerField;
    XD_CatalogServSize: TIntegerField;
    XD_CatalogServUnit: TStringField;
    XD_CatalogOH: TIntegerField;
    XD_CatalogDueDate: TIntegerField;
    XD_CatalogUPC: TIntegerField;
    XD_CatalogManuf: TStringField;
    XD_CatalogLoc: TStringField;
    XD_CatalogPhoto1: TStringField;
    XD_CatalogPhoto2: TStringField;
    XD_CatalogPhoto3: TStringField;
    XD_CatalogPhoto4: TStringField;
    XD_CatalogAllocatedQty: TIntegerField;
    WDS_Catalog: TDataSource;
    XD_CatalogNotes: TStringField;
    XD_CatalogRecNo: TIntegerField;
    WebPanel2: TPanel;
    MenuBtn: TSpeedButton;
    MenuPnl: TPanel;
    WebButton5: TButton;
    WebButton6: TButton;
    WebButton7: TButton;
    WebButton8: TButton;
    WebButton9: TButton;
    WebButton4: TButton;
    WebButton2: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure XD_CatalogAfterOpen(DataSet: TDataSet);
    procedure WebFormResize(Sender: TObject);
    procedure MenuBtnClick(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton8Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FrmPricebook: TFrmPricebook;

implementation

Uses Data,Orderguide,Catalog,Statement,AccountInfo;

{$R *.dfm}

procedure TFrmPricebook.MenuBtnClick(Sender: TObject);
begin
  MenuPnl.Left := MenuBtn.Left;
  MenuPnl.Top := MenuBtn.Top + MenuBtn.Height + 5;
  MenuPnl.BringToFront;
  if MenuPnl.Visible then MenuPnl.Visible := False
    else MenuPnl.Visible := True;
end;

procedure TFrmPricebook.WebButton1Click(Sender: TObject);
begin
  XD_Catalog.QueryString := '$filter=(prod ge '''+XDS_PBIndexprodNum.AsString+''')&$top=1';
  XD_Catalog.Load;
end;

procedure TFrmPricebook.WebButton4Click(Sender: TObject);
begin
  Application.CreateForm(TFrmCatalog, FrmCatalog);
end;

procedure TFrmPricebook.WebButton5Click(Sender: TObject);
begin
  Application.CreateForm(TFrmOrderguide, FrmOrderguide);
end;

procedure TFrmPricebook.WebButton8Click(Sender: TObject);
begin
  Application.CreateForm(TFrmStatement, FrmStatement);
end;

procedure TFrmPricebook.WebFormResize(Sender: TObject);
begin
  WebDBResponsiveGrid1.Options.ItemWidthMin := WebDBResponsiveGrid1.Width;
end;

procedure TFrmPricebook.WebFormShow(Sender: TObject);
begin
  XDS_PBIndex.Load;
end;

procedure TFrmPricebook.XD_CatalogAfterOpen(DataSet: TDataSet);
begin
  Application.CreateForm(TFrmCatalog, FrmCatalog);
  Catalog.StartPos := XD_CatalogRecNo.AsInteger;
end;

procedure TFrmPricebook.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebDBResponsiveGrid1 := TDBResponsiveGrid.Create('pbGrid');
  WebPanel1 := TPanel.Create('pbPanelFooter');
  WebButton1 := TButton.Create(Self);
  WebPanel2 := TPanel.Create('pbPanelHeader');
  MenuBtn := TSpeedButton.Create(Self);
  MenuPnl := TPanel.Create('pbPanelMenu');
  WebButton5 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);
  WebButton9 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  XDS_PBIndex := TXDataWebDataSet.Create(Self);
  XDS_PBIndexidxNum := TFloatField.Create(Self);
  XDS_PBIndexidxunit := TStringField.Create(Self);
  XDS_PBIndexprodNum := TIntegerField.Create(Self);
  XDS_PBIndexidxDesc := TStringField.Create(Self);
  WDS_PBIndex := TDataSource.Create(Self);
  XD_Catalog := TXDataWebDataSet.Create(Self);
  XD_CatalogProd := TIntegerField.Create(Self);
  XD_CatalogUnit := TStringField.Create(Self);
  XD_CatalogSize := TStringField.Create(Self);
  XD_CatalogBrand := TStringField.Create(Self);
  XD_CatalogDescription := TStringField.Create(Self);
  XD_Catalogcuscode := TIntegerField.Create(Self);
  XD_CatalogSellPrice := TFloatField.Create(Self);
  XD_CatalogCWFlag := TStringField.Create(Self);
  XD_CatalogWeight := TIntegerField.Create(Self);
  XD_CatalogCat := TIntegerField.Create(Self);
  XD_CatalogServings := TIntegerField.Create(Self);
  XD_CatalogServSize := TIntegerField.Create(Self);
  XD_CatalogServUnit := TStringField.Create(Self);
  XD_CatalogOH := TIntegerField.Create(Self);
  XD_CatalogDueDate := TIntegerField.Create(Self);
  XD_CatalogUPC := TIntegerField.Create(Self);
  XD_CatalogManuf := TStringField.Create(Self);
  XD_CatalogLoc := TStringField.Create(Self);
  XD_CatalogPhoto1 := TStringField.Create(Self);
  XD_CatalogPhoto2 := TStringField.Create(Self);
  XD_CatalogPhoto3 := TStringField.Create(Self);
  XD_CatalogPhoto4 := TStringField.Create(Self);
  XD_CatalogAllocatedQty := TIntegerField.Create(Self);
  XD_CatalogNotes := TStringField.Create(Self);
  XD_CatalogRecNo := TIntegerField.Create(Self);
  WDS_Catalog := TDataSource.Create(Self);

  WebDBResponsiveGrid1.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  MenuBtn.BeforeLoadDFMValues;
  MenuPnl.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  WebButton9.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  XDS_PBIndex.BeforeLoadDFMValues;
  XDS_PBIndexidxNum.BeforeLoadDFMValues;
  XDS_PBIndexidxunit.BeforeLoadDFMValues;
  XDS_PBIndexprodNum.BeforeLoadDFMValues;
  XDS_PBIndexidxDesc.BeforeLoadDFMValues;
  WDS_PBIndex.BeforeLoadDFMValues;
  XD_Catalog.BeforeLoadDFMValues;
  XD_CatalogProd.BeforeLoadDFMValues;
  XD_CatalogUnit.BeforeLoadDFMValues;
  XD_CatalogSize.BeforeLoadDFMValues;
  XD_CatalogBrand.BeforeLoadDFMValues;
  XD_CatalogDescription.BeforeLoadDFMValues;
  XD_Catalogcuscode.BeforeLoadDFMValues;
  XD_CatalogSellPrice.BeforeLoadDFMValues;
  XD_CatalogCWFlag.BeforeLoadDFMValues;
  XD_CatalogWeight.BeforeLoadDFMValues;
  XD_CatalogCat.BeforeLoadDFMValues;
  XD_CatalogServings.BeforeLoadDFMValues;
  XD_CatalogServSize.BeforeLoadDFMValues;
  XD_CatalogServUnit.BeforeLoadDFMValues;
  XD_CatalogOH.BeforeLoadDFMValues;
  XD_CatalogDueDate.BeforeLoadDFMValues;
  XD_CatalogUPC.BeforeLoadDFMValues;
  XD_CatalogManuf.BeforeLoadDFMValues;
  XD_CatalogLoc.BeforeLoadDFMValues;
  XD_CatalogPhoto1.BeforeLoadDFMValues;
  XD_CatalogPhoto2.BeforeLoadDFMValues;
  XD_CatalogPhoto3.BeforeLoadDFMValues;
  XD_CatalogPhoto4.BeforeLoadDFMValues;
  XD_CatalogAllocatedQty.BeforeLoadDFMValues;
  XD_CatalogNotes.BeforeLoadDFMValues;
  XD_CatalogRecNo.BeforeLoadDFMValues;
  WDS_Catalog.BeforeLoadDFMValues;
  try
    Name := 'FrmPricebook';
    Width := 801;
    Height := 806;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebDBResponsiveGrid1.SetParentComponent(Self);
    WebDBResponsiveGrid1.Name := 'WebDBResponsiveGrid1';
    WebDBResponsiveGrid1.Left := 0;
    WebDBResponsiveGrid1.Top := 60;
    WebDBResponsiveGrid1.Width := 801;
    WebDBResponsiveGrid1.Height := 676;
    WebDBResponsiveGrid1.HeightStyle := ssPercent;
    WebDBResponsiveGrid1.WidthStyle := ssPercent;
    WebDBResponsiveGrid1.HeightPercent := 100.000000000000000000;
    WebDBResponsiveGrid1.WidthPercent := 100.000000000000000000;
    WebDBResponsiveGrid1.Align := alClient;
    WebDBResponsiveGrid1.ElementFont := efCSS;
    WebDBResponsiveGrid1.ItemIndex := 0;
    WebDBResponsiveGrid1.Options.ItemBorderColor := clBlack;
    WebDBResponsiveGrid1.Options.ItemGap := 0;
    WebDBResponsiveGrid1.Options.ItemHeight := 25;
    WebDBResponsiveGrid1.Options.ItemSelectedColor := clHighlight;
    WebDBResponsiveGrid1.Options.ItemSelectedBorderColor := clBlack;
    WebDBResponsiveGrid1.Options.ItemSelectedTextColor := clWhite;
    WebDBResponsiveGrid1.Options.ItemPadding := 1;
    WebDBResponsiveGrid1.Options.ItemTemplate := '<div><b>(%idxDesc%)</b> </div>';
    WebDBResponsiveGrid1.Options.ItemWidthMin := 300;
    WebDBResponsiveGrid1.DataSource := WDS_PBIndex;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 736;
    WebPanel1.Width := 801;
    WebPanel1.Height := 70;
    WebPanel1.ElementClassName := 'card';
    WebPanel1.HeightPercent := 100.000000000000000000;
    WebPanel1.WidthPercent := 100.000000000000000000;
    WebPanel1.Align := alBottom;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebPanel1.ElementBodyClassName := 'card-body';
    WebPanel1.ElementFont := efCSS;
    WebButton1.SetParentComponent(WebPanel1);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 16;
    WebButton1.Top := 16;
    WebButton1.Width := 161;
    WebButton1.Height := 33;
    WebButton1.Caption := 'Find in Catalog';
    WebButton1.ElementClassName := 'btn btn-info';
    WebButton1.ElementFont := efCSS;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 0;
    WebPanel2.Width := 801;
    WebPanel2.Height := 60;
    WebPanel2.ElementClassName := 'card';
    WebPanel2.HeightPercent := 100.000000000000000000;
    WebPanel2.WidthPercent := 100.000000000000000000;
    WebPanel2.Align := alTop;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 2;
    WebPanel2.ElementBodyClassName := 'card-body';
    WebPanel2.ElementFont := efCSS;
    MenuBtn.SetParentComponent(WebPanel2);
    MenuBtn.Name := 'MenuBtn';
    MenuBtn.Left := 6;
    MenuBtn.Top := 10;
    MenuBtn.Width := 43;
    MenuBtn.Height := 42;
    MenuBtn.Color := clNone;
    MenuBtn.ElementClassName := 'btn btn-dark';
    MenuBtn.ElementFont := efCSS;
    MenuBtn.HeightPercent := 100.000000000000000000;
    MenuBtn.MaterialGlyph := 'menu';
    MenuBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MenuBtn, Self, 'OnClick', 'MenuBtnClick');
    MenuPnl.SetParentComponent(Self);
    MenuPnl.Name := 'MenuPnl';
    MenuPnl.Left := 8;
    MenuPnl.Top := 58;
    MenuPnl.Width := 275;
    MenuPnl.Height := 506;
    MenuPnl.ElementClassName := 'card';
    MenuPnl.HeightStyle := ssPercent;
    MenuPnl.HeightPercent := 80.000000000000000000;
    MenuPnl.WidthPercent := 100.000000000000000000;
    MenuPnl.BorderColor := clSilver;
    MenuPnl.BorderStyle := bsSingle;
    MenuPnl.ChildOrder := 2;
    MenuPnl.ElementBodyClassName := 'card-body';
    MenuPnl.ElementFont := efCSS;
    MenuPnl.Visible := False;
    WebButton5.SetParentComponent(MenuPnl);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 12;
    WebButton5.Top := 16;
    WebButton5.Width := 249;
    WebButton5.Height := 33;
    WebButton5.Caption := 'OrderGuide';
    WebButton5.ChildOrder := 1;
    WebButton5.ElementClassName := 'btn btn-success';
    WebButton5.ElementFont := efCSS;
    WebButton5.HeightStyle := ssAuto;
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton6.SetParentComponent(MenuPnl);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 12;
    WebButton6.Top := 94;
    WebButton6.Width := 249;
    WebButton6.Height := 33;
    WebButton6.Caption := 'Account Info';
    WebButton6.ChildOrder := 1;
    WebButton6.ElementClassName := 'btn btn-success';
    WebButton6.ElementFont := efCSS;
    WebButton6.HeightStyle := ssAuto;
    WebButton6.HeightPercent := 100.000000000000000000;
    WebButton6.WidthPercent := 100.000000000000000000;
    WebButton7.SetParentComponent(MenuPnl);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 12;
    WebButton7.Top := 133;
    WebButton7.Width := 249;
    WebButton7.Height := 33;
    WebButton7.Caption := 'Payment';
    WebButton7.ChildOrder := 1;
    WebButton7.ElementClassName := 'btn btn-success';
    WebButton7.ElementFont := efCSS;
    WebButton7.HeightStyle := ssAuto;
    WebButton7.HeightPercent := 100.000000000000000000;
    WebButton7.WidthPercent := 100.000000000000000000;
    WebButton8.SetParentComponent(MenuPnl);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 12;
    WebButton8.Top := 172;
    WebButton8.Width := 249;
    WebButton8.Height := 33;
    WebButton8.Caption := 'Statement';
    WebButton8.ChildOrder := 1;
    WebButton8.ElementClassName := 'btn btn-success';
    WebButton8.ElementFont := efCSS;
    WebButton8.HeightStyle := ssAuto;
    WebButton8.HeightPercent := 100.000000000000000000;
    WebButton8.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton8, Self, 'OnClick', 'WebButton8Click');
    WebButton9.SetParentComponent(MenuPnl);
    WebButton9.Name := 'WebButton9';
    WebButton9.Left := 12;
    WebButton9.Top := 211;
    WebButton9.Width := 249;
    WebButton9.Height := 33;
    WebButton9.Caption := 'Reports';
    WebButton9.ChildOrder := 1;
    WebButton9.ElementClassName := 'btn btn-success';
    WebButton9.ElementFont := efCSS;
    WebButton9.HeightStyle := ssAuto;
    WebButton9.HeightPercent := 100.000000000000000000;
    WebButton9.WidthPercent := 100.000000000000000000;
    WebButton4.SetParentComponent(MenuPnl);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 12;
    WebButton4.Top := 55;
    WebButton4.Width := 249;
    WebButton4.Height := 33;
    WebButton4.Caption := 'Catalog';
    WebButton4.ChildOrder := 5;
    WebButton4.ElementClassName := 'btn btn-success';
    WebButton4.ElementFont := efCSS;
    WebButton4.HeightStyle := ssAuto;
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton2.SetParentComponent(MenuPnl);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 12;
    WebButton2.Top := 451;
    WebButton2.Width := 249;
    WebButton2.Height := 33;
    WebButton2.Caption := 'Logout';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn btn-success';
    WebButton2.ElementFont := efCSS;
    WebButton2.HeightStyle := ssAuto;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    XDS_PBIndex.SetParentComponent(Self);
    XDS_PBIndex.Name := 'XDS_PBIndex';
    XDS_PBIndex.EntitySetName := 'prbkindx';
    XDS_PBIndex.Connection := Dm.ApiConnection;
    XDS_PBIndex.Left := 560;
    XDS_PBIndex.Top := 536;
    XDS_PBIndexidxNum.SetParentComponent(XDS_PBIndex);
    XDS_PBIndexidxNum.Name := 'XDS_PBIndexidxNum';
    XDS_PBIndexidxNum.FieldName := 'idxNum';
    XDS_PBIndexidxNum.Required := True;
    XDS_PBIndexidxunit.SetParentComponent(XDS_PBIndex);
    XDS_PBIndexidxunit.Name := 'XDS_PBIndexidxunit';
    XDS_PBIndexidxunit.FieldName := 'idxunit';
    XDS_PBIndexidxunit.Size := 3;
    XDS_PBIndexprodNum.SetParentComponent(XDS_PBIndex);
    XDS_PBIndexprodNum.Name := 'XDS_PBIndexprodNum';
    XDS_PBIndexprodNum.FieldName := 'prodNum';
    XDS_PBIndexidxDesc.SetParentComponent(XDS_PBIndex);
    XDS_PBIndexidxDesc.Name := 'XDS_PBIndexidxDesc';
    XDS_PBIndexidxDesc.FieldName := 'idxDesc';
    XDS_PBIndexidxDesc.Size := 25;
    WDS_PBIndex.SetParentComponent(Self);
    WDS_PBIndex.Name := 'WDS_PBIndex';
    WDS_PBIndex.DataSet := XDS_PBIndex;
    WDS_PBIndex.Left := 648;
    WDS_PBIndex.Top := 560;
    XD_Catalog.SetParentComponent(Self);
    XD_Catalog.Name := 'XD_Catalog';
    XD_Catalog.AfterOpen := XD_CatalogAfterOpen;
    XD_Catalog.EntitySetName := 'CustCatalog';
    XD_Catalog.Connection := Dm.ApiConnection;
    XD_Catalog.Left := 629;
    XD_Catalog.Top := 648;
    XD_CatalogProd.SetParentComponent(XD_Catalog);
    XD_CatalogProd.Name := 'XD_CatalogProd';
    XD_CatalogProd.FieldName := 'Prod';
    XD_CatalogProd.Required := True;
    XD_CatalogUnit.SetParentComponent(XD_Catalog);
    XD_CatalogUnit.Name := 'XD_CatalogUnit';
    XD_CatalogUnit.FieldName := 'Unit';
    XD_CatalogUnit.Size := 6;
    XD_CatalogSize.SetParentComponent(XD_Catalog);
    XD_CatalogSize.Name := 'XD_CatalogSize';
    XD_CatalogSize.FieldName := 'Size';
    XD_CatalogSize.Size := 6;
    XD_CatalogBrand.SetParentComponent(XD_Catalog);
    XD_CatalogBrand.Name := 'XD_CatalogBrand';
    XD_CatalogBrand.FieldName := 'Brand';
    XD_CatalogBrand.Size := 6;
    XD_CatalogDescription.SetParentComponent(XD_Catalog);
    XD_CatalogDescription.Name := 'XD_CatalogDescription';
    XD_CatalogDescription.FieldName := 'Description';
    XD_CatalogDescription.Size := 25;
    XD_Catalogcuscode.SetParentComponent(XD_Catalog);
    XD_Catalogcuscode.Name := 'XD_Catalogcuscode';
    XD_Catalogcuscode.FieldName := 'cuscode';
    XD_CatalogSellPrice.SetParentComponent(XD_Catalog);
    XD_CatalogSellPrice.Name := 'XD_CatalogSellPrice';
    XD_CatalogSellPrice.FieldName := 'SellPrice';
    XD_CatalogCWFlag.SetParentComponent(XD_Catalog);
    XD_CatalogCWFlag.Name := 'XD_CatalogCWFlag';
    XD_CatalogCWFlag.FieldName := 'CWFlag';
    XD_CatalogCWFlag.Size := 255;
    XD_CatalogWeight.SetParentComponent(XD_Catalog);
    XD_CatalogWeight.Name := 'XD_CatalogWeight';
    XD_CatalogWeight.FieldName := 'Weight';
    XD_CatalogCat.SetParentComponent(XD_Catalog);
    XD_CatalogCat.Name := 'XD_CatalogCat';
    XD_CatalogCat.FieldName := 'Cat';
    XD_CatalogServings.SetParentComponent(XD_Catalog);
    XD_CatalogServings.Name := 'XD_CatalogServings';
    XD_CatalogServings.FieldName := 'Servings';
    XD_CatalogServSize.SetParentComponent(XD_Catalog);
    XD_CatalogServSize.Name := 'XD_CatalogServSize';
    XD_CatalogServSize.FieldName := 'ServSize';
    XD_CatalogServUnit.SetParentComponent(XD_Catalog);
    XD_CatalogServUnit.Name := 'XD_CatalogServUnit';
    XD_CatalogServUnit.FieldName := 'ServUnit';
    XD_CatalogServUnit.Size := 255;
    XD_CatalogOH.SetParentComponent(XD_Catalog);
    XD_CatalogOH.Name := 'XD_CatalogOH';
    XD_CatalogOH.FieldName := 'OH';
    XD_CatalogDueDate.SetParentComponent(XD_Catalog);
    XD_CatalogDueDate.Name := 'XD_CatalogDueDate';
    XD_CatalogDueDate.FieldName := 'DueDate';
    XD_CatalogUPC.SetParentComponent(XD_Catalog);
    XD_CatalogUPC.Name := 'XD_CatalogUPC';
    XD_CatalogUPC.FieldName := 'UPC';
    XD_CatalogManuf.SetParentComponent(XD_Catalog);
    XD_CatalogManuf.Name := 'XD_CatalogManuf';
    XD_CatalogManuf.FieldName := 'Manuf';
    XD_CatalogManuf.Size := 255;
    XD_CatalogLoc.SetParentComponent(XD_Catalog);
    XD_CatalogLoc.Name := 'XD_CatalogLoc';
    XD_CatalogLoc.FieldName := 'Loc';
    XD_CatalogLoc.Size := 255;
    XD_CatalogPhoto1.SetParentComponent(XD_Catalog);
    XD_CatalogPhoto1.Name := 'XD_CatalogPhoto1';
    XD_CatalogPhoto1.FieldName := 'Photo1';
    XD_CatalogPhoto1.Size := 255;
    XD_CatalogPhoto2.SetParentComponent(XD_Catalog);
    XD_CatalogPhoto2.Name := 'XD_CatalogPhoto2';
    XD_CatalogPhoto2.FieldName := 'Photo2';
    XD_CatalogPhoto2.Size := 255;
    XD_CatalogPhoto3.SetParentComponent(XD_Catalog);
    XD_CatalogPhoto3.Name := 'XD_CatalogPhoto3';
    XD_CatalogPhoto3.FieldName := 'Photo3';
    XD_CatalogPhoto3.Size := 255;
    XD_CatalogPhoto4.SetParentComponent(XD_Catalog);
    XD_CatalogPhoto4.Name := 'XD_CatalogPhoto4';
    XD_CatalogPhoto4.FieldName := 'Photo4';
    XD_CatalogPhoto4.Size := 255;
    XD_CatalogAllocatedQty.SetParentComponent(XD_Catalog);
    XD_CatalogAllocatedQty.Name := 'XD_CatalogAllocatedQty';
    XD_CatalogAllocatedQty.FieldName := 'AllocatedQty';
    XD_CatalogNotes.SetParentComponent(XD_Catalog);
    XD_CatalogNotes.Name := 'XD_CatalogNotes';
    XD_CatalogNotes.FieldName := 'Notes';
    XD_CatalogNotes.Size := 255;
    XD_CatalogRecNo.SetParentComponent(XD_Catalog);
    XD_CatalogRecNo.Name := 'XD_CatalogRecNo';
    XD_CatalogRecNo.FieldName := 'RecNo';
    WDS_Catalog.SetParentComponent(Self);
    WDS_Catalog.Name := 'WDS_Catalog';
    WDS_Catalog.DataSet := XD_Catalog;
    WDS_Catalog.Left := 701;
    WDS_Catalog.Top := 656;
  finally
    WebDBResponsiveGrid1.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    MenuBtn.AfterLoadDFMValues;
    MenuPnl.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    WebButton9.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    XDS_PBIndex.AfterLoadDFMValues;
    XDS_PBIndexidxNum.AfterLoadDFMValues;
    XDS_PBIndexidxunit.AfterLoadDFMValues;
    XDS_PBIndexprodNum.AfterLoadDFMValues;
    XDS_PBIndexidxDesc.AfterLoadDFMValues;
    WDS_PBIndex.AfterLoadDFMValues;
    XD_Catalog.AfterLoadDFMValues;
    XD_CatalogProd.AfterLoadDFMValues;
    XD_CatalogUnit.AfterLoadDFMValues;
    XD_CatalogSize.AfterLoadDFMValues;
    XD_CatalogBrand.AfterLoadDFMValues;
    XD_CatalogDescription.AfterLoadDFMValues;
    XD_Catalogcuscode.AfterLoadDFMValues;
    XD_CatalogSellPrice.AfterLoadDFMValues;
    XD_CatalogCWFlag.AfterLoadDFMValues;
    XD_CatalogWeight.AfterLoadDFMValues;
    XD_CatalogCat.AfterLoadDFMValues;
    XD_CatalogServings.AfterLoadDFMValues;
    XD_CatalogServSize.AfterLoadDFMValues;
    XD_CatalogServUnit.AfterLoadDFMValues;
    XD_CatalogOH.AfterLoadDFMValues;
    XD_CatalogDueDate.AfterLoadDFMValues;
    XD_CatalogUPC.AfterLoadDFMValues;
    XD_CatalogManuf.AfterLoadDFMValues;
    XD_CatalogLoc.AfterLoadDFMValues;
    XD_CatalogPhoto1.AfterLoadDFMValues;
    XD_CatalogPhoto2.AfterLoadDFMValues;
    XD_CatalogPhoto3.AfterLoadDFMValues;
    XD_CatalogPhoto4.AfterLoadDFMValues;
    XD_CatalogAllocatedQty.AfterLoadDFMValues;
    XD_CatalogNotes.AfterLoadDFMValues;
    XD_CatalogRecNo.AfterLoadDFMValues;
    WDS_Catalog.AfterLoadDFMValues;
  end;
end;

end.