unit AccountInfo;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Buttons, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.DBCtrls, WEBLib.ExtCtrls;

type
  TFrmAccountInfo = class(TForm)
    HeaderPnl: TPanel;
    HeaderInfoPnl: TPanel;
    WebDBLabel1: TDBLabel;
    WebDBLabel2: TDBLabel;
    MenuBtn: TSpeedButton;
    MenuPnl: TPanel;
    WebButton5: TButton;
    WebButton7: TButton;
    WebButton8: TButton;
    WebButton9: TButton;
    WebButton4: TButton;
    WebButton1: TButton;
    BGPnl: TPanel;
    InfoPnl: TPanel;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    procedure MenuBtnClick(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton8Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FrmAccountInfo: TFrmAccountInfo;

implementation

Uses Data,Catalog,Orderguide,Statement;

{$R *.dfm}

procedure TFrmAccountInfo.MenuBtnClick(Sender: TObject);
begin
  MenuPnl.Left := MenuBtn.Left;
  MenuPnl.Top := MenuBtn.Top + MenuBtn.Height + 5;
  MenuPnl.BringToFront;
  if MenuPnl.Visible then MenuPnl.Visible := False
    else MenuPnl.Visible := True;
end;

procedure TFrmAccountInfo.WebButton4Click(Sender: TObject);
begin
  Application.CreateForm(TFrmCatalog, FrmCatalog);
end;

procedure TFrmAccountInfo.WebButton5Click(Sender: TObject);
begin
  Application.CreateForm(TFrmOrderguide, FrmOrderguide);
end;

procedure TFrmAccountInfo.WebButton8Click(Sender: TObject);
begin
  Application.CreateForm(TFrmStatement, FrmStatement);
end;

procedure TFrmAccountInfo.WebFormResize(Sender: TObject);
begin
  InfoPnl.Left := Round((BGPnl.Left/2) - (InfoPnl.Left/2));
  //InfoPnl.Top :=
end;

procedure TFrmAccountInfo.LoadDFMValues;
begin
  inherited LoadDFMValues;

  HeaderPnl := TPanel.Create('infoPanelHeaderBG');
  HeaderInfoPnl := TPanel.Create('infoPanelCustInfo');
  WebDBLabel1 := TDBLabel.Create(Self);
  WebDBLabel2 := TDBLabel.Create(Self);
  MenuBtn := TSpeedButton.Create(Self);
  BGPnl := TPanel.Create('infoBGPanel');
  InfoPnl := TPanel.Create('infoEditPanel');
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  MenuPnl := TPanel.Create('infoPanelMenu');
  WebButton5 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);
  WebButton9 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);

  HeaderPnl.BeforeLoadDFMValues;
  HeaderInfoPnl.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  WebDBLabel2.BeforeLoadDFMValues;
  MenuBtn.BeforeLoadDFMValues;
  BGPnl.BeforeLoadDFMValues;
  InfoPnl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  MenuPnl.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  WebButton9.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'FrmAccountInfo';
    Width := 373;
    Height := 781;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    HeaderPnl.SetParentComponent(Self);
    HeaderPnl.Name := 'HeaderPnl';
    HeaderPnl.Left := 0;
    HeaderPnl.Top := 0;
    HeaderPnl.Width := 373;
    HeaderPnl.Height := 66;
    HeaderPnl.ElementClassName := 'card';
    HeaderPnl.HeightPercent := 100.000000000000000000;
    HeaderPnl.WidthPercent := 100.000000000000000000;
    HeaderPnl.Align := alTop;
    HeaderPnl.BorderColor := clSilver;
    HeaderPnl.BorderStyle := bsNone;
    HeaderPnl.ChildOrder := 2;
    HeaderPnl.ElementBodyClassName := 'card-body';
    HeaderPnl.ElementFont := efCSS;
    HeaderInfoPnl.SetParentComponent(HeaderPnl);
    HeaderInfoPnl.Name := 'HeaderInfoPnl';
    HeaderInfoPnl.Left := 80;
    HeaderInfoPnl.Top := 0;
    HeaderInfoPnl.Width := 293;
    HeaderInfoPnl.Height := 66;
    HeaderInfoPnl.ElementClassName := 'card border-0';
    HeaderInfoPnl.HeightStyle := ssPercent;
    HeaderInfoPnl.HeightPercent := 100.000000000000000000;
    HeaderInfoPnl.WidthPercent := 100.000000000000000000;
    HeaderInfoPnl.Align := alRight;
    HeaderInfoPnl.BorderColor := clSilver;
    HeaderInfoPnl.BorderStyle := bsNone;
    HeaderInfoPnl.ChildOrder := 1;
    HeaderInfoPnl.ElementBodyClassName := 'card-body';
    HeaderInfoPnl.ElementFont := efCSS;
    WebDBLabel1.SetParentComponent(HeaderInfoPnl);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 3;
    WebDBLabel1.Top := 6;
    WebDBLabel1.Width := 40;
    WebDBLabel1.Height := 18;
    WebDBLabel1.Caption := '99999';
    WebDBLabel1.ElementFont := efCSS;
    WebDBLabel1.HeightStyle := ssAuto;
    WebDBLabel1.HeightPercent := 100.000000000000000000;
    WebDBLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel1.DataField := 'Cust';
    WebDBLabel1.DataSource := Dm.WDS_Cust;
    WebDBLabel2.SetParentComponent(HeaderInfoPnl);
    WebDBLabel2.Name := 'WebDBLabel2';
    WebDBLabel2.Left := 56;
    WebDBLabel2.Top := 6;
    WebDBLabel2.Width := 143;
    WebDBLabel2.Height := 18;
    WebDBLabel2.Caption := 'Customer Name Here';
    WebDBLabel2.ElementFont := efCSS;
    WebDBLabel2.HeightStyle := ssAuto;
    WebDBLabel2.HeightPercent := 100.000000000000000000;
    WebDBLabel2.WidthPercent := 100.000000000000000000;
    WebDBLabel2.DataField := 'CustName';
    WebDBLabel2.DataSource := Dm.WDS_Cust;
    MenuBtn.SetParentComponent(HeaderPnl);
    MenuBtn.Name := 'MenuBtn';
    MenuBtn.Left := 4;
    MenuBtn.Top := 6;
    MenuBtn.Width := 43;
    MenuBtn.Height := 42;
    MenuBtn.Color := clNone;
    MenuBtn.ElementClassName := 'btn btn-dark';
    MenuBtn.ElementFont := efCSS;
    MenuBtn.HeightPercent := 100.000000000000000000;
    MenuBtn.MaterialGlyph := 'menu';
    MenuBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MenuBtn, Self, 'OnClick', 'MenuBtnClick');
    BGPnl.SetParentComponent(Self);
    BGPnl.Name := 'BGPnl';
    BGPnl.Left := 0;
    BGPnl.Top := 66;
    BGPnl.Width := 373;
    BGPnl.Height := 715;
    BGPnl.ElementClassName := 'card';
    BGPnl.HeightPercent := 100.000000000000000000;
    BGPnl.WidthPercent := 100.000000000000000000;
    BGPnl.Align := alClient;
    BGPnl.BorderColor := clSilver;
    BGPnl.BorderStyle := bsSingle;
    BGPnl.ChildOrder := 2;
    BGPnl.ElementBodyClassName := 'card-body';
    BGPnl.ElementFont := efCSS;
    InfoPnl.SetParentComponent(BGPnl);
    InfoPnl.Name := 'InfoPnl';
    InfoPnl.Left := 16;
    InfoPnl.Top := 18;
    InfoPnl.Width := 345;
    InfoPnl.Height := 679;
    InfoPnl.ElementClassName := 'card';
    InfoPnl.HeightPercent := 100.000000000000000000;
    InfoPnl.WidthPercent := 100.000000000000000000;
    InfoPnl.BorderColor := clSilver;
    InfoPnl.BorderStyle := bsSingle;
    InfoPnl.ElementBodyClassName := 'card-body';
    InfoPnl.ElementFont := efCSS;
    WebLabel1.SetParentComponent(InfoPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 16;
    WebLabel1.Width := 174;
    WebLabel1.Height := 18;
    WebLabel1.Caption := 'Editable Account Info Here';
    WebLabel1.ElementClassName := 'h2';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(InfoPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 16;
    WebLabel2.Top := 56;
    WebLabel2.Width := 71;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'WebLabel2';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.HeightStyle := ssAuto;
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    MenuPnl.SetParentComponent(Self);
    MenuPnl.Name := 'MenuPnl';
    MenuPnl.Left := 0;
    MenuPnl.Top := 54;
    MenuPnl.Width := 275;
    MenuPnl.Height := 506;
    MenuPnl.ElementClassName := 'card';
    MenuPnl.HeightStyle := ssPercent;
    MenuPnl.HeightPercent := 80.000000000000000000;
    MenuPnl.WidthPercent := 100.000000000000000000;
    MenuPnl.BorderColor := clSilver;
    MenuPnl.BorderStyle := bsSingle;
    MenuPnl.ChildOrder := 2;
    MenuPnl.ElementBodyClassName := 'card-body';
    MenuPnl.ElementFont := efCSS;
    MenuPnl.Visible := False;
    WebButton5.SetParentComponent(MenuPnl);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 12;
    WebButton5.Top := 17;
    WebButton5.Width := 249;
    WebButton5.Height := 33;
    WebButton5.Caption := 'OrderGuide';
    WebButton5.ChildOrder := 1;
    WebButton5.ElementClassName := 'btn btn-success';
    WebButton5.ElementFont := efCSS;
    WebButton5.HeightStyle := ssAuto;
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton7.SetParentComponent(MenuPnl);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 12;
    WebButton7.Top := 97;
    WebButton7.Width := 249;
    WebButton7.Height := 33;
    WebButton7.Caption := 'Payment';
    WebButton7.ChildOrder := 1;
    WebButton7.ElementClassName := 'btn btn-success';
    WebButton7.ElementFont := efCSS;
    WebButton7.HeightStyle := ssAuto;
    WebButton7.HeightPercent := 100.000000000000000000;
    WebButton7.WidthPercent := 100.000000000000000000;
    WebButton8.SetParentComponent(MenuPnl);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 12;
    WebButton8.Top := 137;
    WebButton8.Width := 249;
    WebButton8.Height := 33;
    WebButton8.Caption := 'Statement';
    WebButton8.ChildOrder := 1;
    WebButton8.ElementClassName := 'btn btn-success';
    WebButton8.ElementFont := efCSS;
    WebButton8.HeightStyle := ssAuto;
    WebButton8.HeightPercent := 100.000000000000000000;
    WebButton8.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton8, Self, 'OnClick', 'WebButton8Click');
    WebButton9.SetParentComponent(MenuPnl);
    WebButton9.Name := 'WebButton9';
    WebButton9.Left := 12;
    WebButton9.Top := 177;
    WebButton9.Width := 249;
    WebButton9.Height := 33;
    WebButton9.Caption := 'Reports';
    WebButton9.ChildOrder := 1;
    WebButton9.ElementClassName := 'btn btn-success';
    WebButton9.ElementFont := efCSS;
    WebButton9.HeightStyle := ssAuto;
    WebButton9.HeightPercent := 100.000000000000000000;
    WebButton9.WidthPercent := 100.000000000000000000;
    WebButton4.SetParentComponent(MenuPnl);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 12;
    WebButton4.Top := 57;
    WebButton4.Width := 249;
    WebButton4.Height := 33;
    WebButton4.Caption := 'Catalog';
    WebButton4.ChildOrder := 1;
    WebButton4.ElementClassName := 'btn btn-success';
    WebButton4.ElementFont := efCSS;
    WebButton4.HeightStyle := ssAuto;
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton1.SetParentComponent(MenuPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 12;
    WebButton1.Top := 451;
    WebButton1.Width := 249;
    WebButton1.Height := 33;
    WebButton1.Caption := 'Logout';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn btn-success';
    WebButton1.ElementFont := efCSS;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
  finally
    HeaderPnl.AfterLoadDFMValues;
    HeaderInfoPnl.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    WebDBLabel2.AfterLoadDFMValues;
    MenuBtn.AfterLoadDFMValues;
    BGPnl.AfterLoadDFMValues;
    InfoPnl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    MenuPnl.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    WebButton9.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.