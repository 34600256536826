program CustomerApp;

uses
  Vcl.Forms,
  WEBLib.Forms,
  Login in 'Login.pas'{*.html},
  Data in 'Data.pas',
  Landing in 'Landing.pas'{*.html},
  Orderguide in 'Orderguide.pas'{*.html},
  Test in 'Test.pas'{*.html},
  Catalog in 'Catalog.pas'{*.html},
  Pricebook in 'Pricebook.pas'{*.html},
  Statement in 'Statement.pas'{*.html},
  AccountInfo in 'AccountInfo.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TFrmLogin, FrmLogin);
  Application.CreateForm(TDm, Dm);
  //Application.CreateForm(TFrmAccountInfo, FrmAccountInfo);
  //Application.CreateForm(TFrmStatement, FrmStatement);
  //Application.CreateForm(TFrmPricebook, FrmPricebook);
  //Application.CreateForm(TFrmCatalog, FrmCatalog);
  //Application.CreateForm(TFrmTest, FrmTest);
  //Application.CreateForm(TFrmLanding, FrmLanding);
  //Application.CreateForm(TFrmOrderguide, FrmOrderguide);
  Application.Run;
end.
