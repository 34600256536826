unit Login;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB;

type
  TFrmLogin = class(TForm)
    pwd1: TEdit;
    uname1: TEdit;
    btnLogin: TButton;
    XD_UserData: TXDataWebDataSet;
    XD_UserDatauser: TStringField;
    XD_UserDataUserTypeID: TIntegerField;
    XD_UserDataSRNumber: TIntegerField;
    XD_UserDatapass: TStringField;
    XD_UserDatanextord: TIntegerField;
    XD_UserDatalastlogin: TDateTimeField;
    XD_UserDatacuscode: TIntegerField;
    XD_UserDataemail: TStringField;
    XD_UserDatahas_multi_accounts: TIntegerField;
    XD_UserDataOrderGuideShowEntries: TIntegerField;
    XD_UserDataCatalogLength: TIntegerField;
    XD_UserDataInfoRequested: TStringField;
    XD_UserDataInfoRequestedDate: TDateTimeField;
    XD_Cust: TXDataWebDataSet;
    XD_CustCust: TIntegerField;
    XD_CustCustName: TStringField;
    XD_CustSRNum: TIntegerField;
    XD_CustAddress1: TStringField;
    XD_CustAddress2: TStringField;
    XD_CustCity: TStringField;
    XD_CustState: TStringField;
    XD_CustTermsExplained: TStringField;
    XD_CustLatitude: TFloatField;
    XD_CustLongitude: TFloatField;
    WDS_Cust: TDataSource;
    procedure btnLoginClick(Sender: TObject);
    procedure XD_UserDataAfterOpen(DataSet: TDataSet);
    procedure XD_CustAfterOpen(DataSet: TDataSet);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FrmLogin: TFrmLogin;

implementation

Uses Data,Landing,Orderguide;

{$R *.dfm}

procedure TFrmLogin.btnLoginClick(Sender: TObject);
begin
  if uname1.text = '' then
  begin
    ShowMessage('Username Cannot be Blank');
    Exit;
  end;
  if pwd1.text = '' then
  begin
    ShowMessage('Password Cannot be Blank');
    Exit;
  end;
  XD_UserData.QueryString := '$filter=(user eq ''' +
    uname1.Text + ''' and pass eq '''+pwd1.Text+''')';
  XD_UserData.Load;
end;

procedure TFrmLogin.XD_CustAfterOpen(DataSet: TDataSet);
begin
  //Need to check for credit hold here
  Application.CreateForm(TFrmOrderguide, FrmOrderguide);
  //FrmOrderguide.Show;
end;

procedure TFrmLogin.XD_UserDataAfterOpen(DataSet: TDataSet);
begin
  if (XD_UserData.RecordCount > 0) and
    (XD_UserDatapass.AsString = pwd1.Text) then
  begin
    Dm.XD_UserData.QueryString := '$filter=(user eq '''+XD_UserDatauser.AsString+''' and pass eq '''+pwd1.Text+''')';
    Dm.XD_UserData.Load;
    CurrentCustNumber := XD_UserDatacuscode.AsInteger;
    XD_UserData.Close;
    Dm.XD_Cust.QueryString := '$filter=(Cust eq '''+IntToStr(CurrentCustNumber)+''')';
    Dm.XD_Cust.Load;
    XD_Cust.QueryString := '$filter=(Cust eq '''+IntToStr(CurrentCustNumber)+''')';
    XD_Cust.Load;
  end
  else
  begin
    XD_UserData.Close;
    ShowMessage('Invalid Login Info');
  end;
end;

procedure TFrmLogin.LoadDFMValues;
begin
  inherited LoadDFMValues;

  pwd1 := TEdit.Create('pwd1');
  uname1 := TEdit.Create('uname1');
  btnLogin := TButton.Create('btnLogin');
  XD_UserData := TXDataWebDataSet.Create(Self);
  XD_UserDatauser := TStringField.Create(Self);
  XD_UserDataUserTypeID := TIntegerField.Create(Self);
  XD_UserDataSRNumber := TIntegerField.Create(Self);
  XD_UserDatapass := TStringField.Create(Self);
  XD_UserDatanextord := TIntegerField.Create(Self);
  XD_UserDatalastlogin := TDateTimeField.Create(Self);
  XD_UserDatacuscode := TIntegerField.Create(Self);
  XD_UserDataemail := TStringField.Create(Self);
  XD_UserDatahas_multi_accounts := TIntegerField.Create(Self);
  XD_UserDataOrderGuideShowEntries := TIntegerField.Create(Self);
  XD_UserDataCatalogLength := TIntegerField.Create(Self);
  XD_UserDataInfoRequested := TStringField.Create(Self);
  XD_UserDataInfoRequestedDate := TDateTimeField.Create(Self);
  XD_Cust := TXDataWebDataSet.Create(Self);
  XD_CustCust := TIntegerField.Create(Self);
  XD_CustCustName := TStringField.Create(Self);
  XD_CustSRNum := TIntegerField.Create(Self);
  XD_CustAddress1 := TStringField.Create(Self);
  XD_CustAddress2 := TStringField.Create(Self);
  XD_CustCity := TStringField.Create(Self);
  XD_CustState := TStringField.Create(Self);
  XD_CustTermsExplained := TStringField.Create(Self);
  XD_CustLatitude := TFloatField.Create(Self);
  XD_CustLongitude := TFloatField.Create(Self);
  WDS_Cust := TDataSource.Create(Self);

  pwd1.BeforeLoadDFMValues;
  uname1.BeforeLoadDFMValues;
  btnLogin.BeforeLoadDFMValues;
  XD_UserData.BeforeLoadDFMValues;
  XD_UserDatauser.BeforeLoadDFMValues;
  XD_UserDataUserTypeID.BeforeLoadDFMValues;
  XD_UserDataSRNumber.BeforeLoadDFMValues;
  XD_UserDatapass.BeforeLoadDFMValues;
  XD_UserDatanextord.BeforeLoadDFMValues;
  XD_UserDatalastlogin.BeforeLoadDFMValues;
  XD_UserDatacuscode.BeforeLoadDFMValues;
  XD_UserDataemail.BeforeLoadDFMValues;
  XD_UserDatahas_multi_accounts.BeforeLoadDFMValues;
  XD_UserDataOrderGuideShowEntries.BeforeLoadDFMValues;
  XD_UserDataCatalogLength.BeforeLoadDFMValues;
  XD_UserDataInfoRequested.BeforeLoadDFMValues;
  XD_UserDataInfoRequestedDate.BeforeLoadDFMValues;
  XD_Cust.BeforeLoadDFMValues;
  XD_CustCust.BeforeLoadDFMValues;
  XD_CustCustName.BeforeLoadDFMValues;
  XD_CustSRNum.BeforeLoadDFMValues;
  XD_CustAddress1.BeforeLoadDFMValues;
  XD_CustAddress2.BeforeLoadDFMValues;
  XD_CustCity.BeforeLoadDFMValues;
  XD_CustState.BeforeLoadDFMValues;
  XD_CustTermsExplained.BeforeLoadDFMValues;
  XD_CustLatitude.BeforeLoadDFMValues;
  XD_CustLongitude.BeforeLoadDFMValues;
  WDS_Cust.BeforeLoadDFMValues;
  try
    Name := 'FrmLogin';
    Width := 461;
    Height := 311;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    pwd1.SetParentComponent(Self);
    pwd1.Name := 'pwd1';
    pwd1.Left := 104;
    pwd1.Top := 112;
    pwd1.Width := 96;
    pwd1.Height := 25;
    pwd1.ElementFont := efCSS;
    pwd1.ElementPosition := epIgnore;
    pwd1.HeightStyle := ssAuto;
    pwd1.HeightPercent := 100.000000000000000000;
    pwd1.Text := 'qwerty';
    pwd1.WidthStyle := ssAuto;
    pwd1.WidthPercent := 100.000000000000000000;
    uname1.SetParentComponent(Self);
    uname1.Name := 'uname1';
    uname1.Left := 104;
    uname1.Top := 143;
    uname1.Width := 96;
    uname1.Height := 25;
    uname1.ChildOrder := 1;
    uname1.ElementFont := efCSS;
    uname1.ElementPosition := epIgnore;
    uname1.HeightStyle := ssAuto;
    uname1.HeightPercent := 100.000000000000000000;
    uname1.Text := 'Joel1';
    uname1.WidthStyle := ssAuto;
    uname1.WidthPercent := 100.000000000000000000;
    btnLogin.SetParentComponent(Self);
    btnLogin.Name := 'btnLogin';
    btnLogin.Left := 206;
    btnLogin.Top := 128;
    btnLogin.Width := 96;
    btnLogin.Height := 25;
    btnLogin.Caption := 'Login';
    btnLogin.ChildOrder := 2;
    btnLogin.ElementFont := efCSS;
    btnLogin.ElementPosition := epIgnore;
    btnLogin.HeightStyle := ssAuto;
    btnLogin.HeightPercent := 100.000000000000000000;
    btnLogin.WidthStyle := ssAuto;
    btnLogin.WidthPercent := 100.000000000000000000;
    SetEvent(btnLogin, Self, 'OnClick', 'btnLoginClick');
    XD_UserData.SetParentComponent(Self);
    XD_UserData.Name := 'XD_UserData';
    XD_UserData.AfterOpen := XD_UserDataAfterOpen;
    XD_UserData.EntitySetName := 'userdata';
    XD_UserData.Connection := Dm.ApiConnection;
    XD_UserData.Left := 344;
    XD_UserData.Top := 64;
    XD_UserDatauser.SetParentComponent(XD_UserData);
    XD_UserDatauser.Name := 'XD_UserDatauser';
    XD_UserDatauser.FieldName := 'user';
    XD_UserDatauser.Required := True;
    XD_UserDatauser.Size := 30;
    XD_UserDataUserTypeID.SetParentComponent(XD_UserData);
    XD_UserDataUserTypeID.Name := 'XD_UserDataUserTypeID';
    XD_UserDataUserTypeID.FieldName := 'UserTypeID';
    XD_UserDataUserTypeID.Required := True;
    XD_UserDataSRNumber.SetParentComponent(XD_UserData);
    XD_UserDataSRNumber.Name := 'XD_UserDataSRNumber';
    XD_UserDataSRNumber.FieldName := 'SRNumber';
    XD_UserDataSRNumber.Required := True;
    XD_UserDatapass.SetParentComponent(XD_UserData);
    XD_UserDatapass.Name := 'XD_UserDatapass';
    XD_UserDatapass.FieldName := 'pass';
    XD_UserDatapass.Required := True;
    XD_UserDatapass.Size := 30;
    XD_UserDatanextord.SetParentComponent(XD_UserData);
    XD_UserDatanextord.Name := 'XD_UserDatanextord';
    XD_UserDatanextord.FieldName := 'nextord';
    XD_UserDatanextord.Required := True;
    XD_UserDatalastlogin.SetParentComponent(XD_UserData);
    XD_UserDatalastlogin.Name := 'XD_UserDatalastlogin';
    XD_UserDatalastlogin.FieldName := 'lastlogin';
    XD_UserDatalastlogin.Required := True;
    XD_UserDatacuscode.SetParentComponent(XD_UserData);
    XD_UserDatacuscode.Name := 'XD_UserDatacuscode';
    XD_UserDatacuscode.FieldName := 'cuscode';
    XD_UserDatacuscode.Required := True;
    XD_UserDataemail.SetParentComponent(XD_UserData);
    XD_UserDataemail.Name := 'XD_UserDataemail';
    XD_UserDataemail.FieldName := 'email';
    XD_UserDataemail.Required := True;
    XD_UserDataemail.Size := 40;
    XD_UserDatahas_multi_accounts.SetParentComponent(XD_UserData);
    XD_UserDatahas_multi_accounts.Name := 'XD_UserDatahas_multi_accounts';
    XD_UserDatahas_multi_accounts.FieldName := 'has_multi_accounts';
    XD_UserDatahas_multi_accounts.Required := True;
    XD_UserDataOrderGuideShowEntries.SetParentComponent(XD_UserData);
    XD_UserDataOrderGuideShowEntries.Name := 'XD_UserDataOrderGuideShowEntries';
    XD_UserDataOrderGuideShowEntries.FieldName := 'OrderGuideShowEntries';
    XD_UserDataOrderGuideShowEntries.Required := True;
    XD_UserDataCatalogLength.SetParentComponent(XD_UserData);
    XD_UserDataCatalogLength.Name := 'XD_UserDataCatalogLength';
    XD_UserDataCatalogLength.FieldName := 'CatalogLength';
    XD_UserDataCatalogLength.Required := True;
    XD_UserDataInfoRequested.SetParentComponent(XD_UserData);
    XD_UserDataInfoRequested.Name := 'XD_UserDataInfoRequested';
    XD_UserDataInfoRequested.FieldName := 'InfoRequested';
    XD_UserDataInfoRequested.Size := 50;
    XD_UserDataInfoRequestedDate.SetParentComponent(XD_UserData);
    XD_UserDataInfoRequestedDate.Name := 'XD_UserDataInfoRequestedDate';
    XD_UserDataInfoRequestedDate.FieldName := 'InfoRequestedDate';
    XD_Cust.SetParentComponent(Self);
    XD_Cust.Name := 'XD_Cust';
    XD_Cust.AfterOpen := XD_CustAfterOpen;
    XD_Cust.EntitySetName := 'dlcust';
    XD_Cust.Connection := Dm.ApiConnection;
    XD_Cust.Left := 328;
    XD_Cust.Top := 136;
    XD_CustCust.SetParentComponent(XD_Cust);
    XD_CustCust.Name := 'XD_CustCust';
    XD_CustCust.FieldName := 'Cust';
    XD_CustCustName.SetParentComponent(XD_Cust);
    XD_CustCustName.Name := 'XD_CustCustName';
    XD_CustCustName.FieldName := 'CustName';
    XD_CustCustName.Size := 255;
    XD_CustSRNum.SetParentComponent(XD_Cust);
    XD_CustSRNum.Name := 'XD_CustSRNum';
    XD_CustSRNum.FieldName := 'SRNum';
    XD_CustAddress1.SetParentComponent(XD_Cust);
    XD_CustAddress1.Name := 'XD_CustAddress1';
    XD_CustAddress1.FieldName := 'Address1';
    XD_CustAddress1.Size := 255;
    XD_CustAddress2.SetParentComponent(XD_Cust);
    XD_CustAddress2.Name := 'XD_CustAddress2';
    XD_CustAddress2.FieldName := 'Address2';
    XD_CustAddress2.Size := 255;
    XD_CustCity.SetParentComponent(XD_Cust);
    XD_CustCity.Name := 'XD_CustCity';
    XD_CustCity.FieldName := 'City';
    XD_CustCity.Size := 255;
    XD_CustState.SetParentComponent(XD_Cust);
    XD_CustState.Name := 'XD_CustState';
    XD_CustState.FieldName := 'State';
    XD_CustState.Size := 255;
    XD_CustTermsExplained.SetParentComponent(XD_Cust);
    XD_CustTermsExplained.Name := 'XD_CustTermsExplained';
    XD_CustTermsExplained.FieldName := 'TermsExplained';
    XD_CustTermsExplained.Size := 255;
    XD_CustLatitude.SetParentComponent(XD_Cust);
    XD_CustLatitude.Name := 'XD_CustLatitude';
    XD_CustLatitude.FieldName := 'Latitude';
    XD_CustLongitude.SetParentComponent(XD_Cust);
    XD_CustLongitude.Name := 'XD_CustLongitude';
    XD_CustLongitude.FieldName := 'Longitude';
    WDS_Cust.SetParentComponent(Self);
    WDS_Cust.Name := 'WDS_Cust';
    WDS_Cust.DataSet := XD_Cust;
    WDS_Cust.Left := 384;
    WDS_Cust.Top := 136;
  finally
    pwd1.AfterLoadDFMValues;
    uname1.AfterLoadDFMValues;
    btnLogin.AfterLoadDFMValues;
    XD_UserData.AfterLoadDFMValues;
    XD_UserDatauser.AfterLoadDFMValues;
    XD_UserDataUserTypeID.AfterLoadDFMValues;
    XD_UserDataSRNumber.AfterLoadDFMValues;
    XD_UserDatapass.AfterLoadDFMValues;
    XD_UserDatanextord.AfterLoadDFMValues;
    XD_UserDatalastlogin.AfterLoadDFMValues;
    XD_UserDatacuscode.AfterLoadDFMValues;
    XD_UserDataemail.AfterLoadDFMValues;
    XD_UserDatahas_multi_accounts.AfterLoadDFMValues;
    XD_UserDataOrderGuideShowEntries.AfterLoadDFMValues;
    XD_UserDataCatalogLength.AfterLoadDFMValues;
    XD_UserDataInfoRequested.AfterLoadDFMValues;
    XD_UserDataInfoRequestedDate.AfterLoadDFMValues;
    XD_Cust.AfterLoadDFMValues;
    XD_CustCust.AfterLoadDFMValues;
    XD_CustCustName.AfterLoadDFMValues;
    XD_CustSRNum.AfterLoadDFMValues;
    XD_CustAddress1.AfterLoadDFMValues;
    XD_CustAddress2.AfterLoadDFMValues;
    XD_CustCity.AfterLoadDFMValues;
    XD_CustState.AfterLoadDFMValues;
    XD_CustTermsExplained.AfterLoadDFMValues;
    XD_CustLatitude.AfterLoadDFMValues;
    XD_CustLongitude.AfterLoadDFMValues;
    WDS_Cust.AfterLoadDFMValues;
  end;
end;

end.