unit Data;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, Data.DB, WEBLib.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Connection;

type
  TDm = class(TDataModule)
    ApiConnection: TXDataWebConnection;
    XD_UserData: TXDataWebDataSet;
    XD_UserDatauser: TStringField;
    XD_UserDataUserTypeID: TIntegerField;
    XD_UserDataSRNumber: TIntegerField;
    XD_UserDatapass: TStringField;
    XD_UserDatanextord: TIntegerField;
    XD_UserDatalastlogin: TDateTimeField;
    XD_UserDatacuscode: TIntegerField;
    XD_UserDataemail: TStringField;
    XD_UserDatahas_multi_accounts: TIntegerField;
    XD_UserDataOrderGuideShowEntries: TIntegerField;
    XD_UserDataCatalogLength: TIntegerField;
    XD_UserDataInfoRequested: TStringField;
    XD_UserDataInfoRequestedDate: TDateTimeField;
    WDS_UserData: TDataSource;
    XD_Cust: TXDataWebDataSet;
    WDS_Cust: TDataSource;
    XD_CustCust: TIntegerField;
    XD_CustCustName: TStringField;
    XD_CustSRNum: TIntegerField;
    XD_CustAddress1: TStringField;
    XD_CustAddress2: TStringField;
    XD_CustCity: TStringField;
    XD_CustState: TStringField;
    XD_CustTermsExplained: TStringField;
    XD_CustLatitude: TFloatField;
    XD_CustLongitude: TFloatField;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Dm: TDm;
  CurrentCustNumber:Integer;

implementation

Uses Catalog,Orderguide;

{%CLASSGROUP 'Vcl.Controls.TControl'}

{$R *.dfm}

procedure TDm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ApiConnection := TXDataWebConnection.Create(Self);
  XD_UserData := TXDataWebDataSet.Create(Self);
  XD_UserDatauser := TStringField.Create(Self);
  XD_UserDataUserTypeID := TIntegerField.Create(Self);
  XD_UserDataSRNumber := TIntegerField.Create(Self);
  XD_UserDatapass := TStringField.Create(Self);
  XD_UserDatanextord := TIntegerField.Create(Self);
  XD_UserDatalastlogin := TDateTimeField.Create(Self);
  XD_UserDatacuscode := TIntegerField.Create(Self);
  XD_UserDataemail := TStringField.Create(Self);
  XD_UserDatahas_multi_accounts := TIntegerField.Create(Self);
  XD_UserDataOrderGuideShowEntries := TIntegerField.Create(Self);
  XD_UserDataCatalogLength := TIntegerField.Create(Self);
  XD_UserDataInfoRequested := TStringField.Create(Self);
  XD_UserDataInfoRequestedDate := TDateTimeField.Create(Self);
  WDS_UserData := TDataSource.Create(Self);
  XD_Cust := TXDataWebDataSet.Create(Self);
  XD_CustCust := TIntegerField.Create(Self);
  XD_CustCustName := TStringField.Create(Self);
  XD_CustSRNum := TIntegerField.Create(Self);
  XD_CustAddress1 := TStringField.Create(Self);
  XD_CustAddress2 := TStringField.Create(Self);
  XD_CustCity := TStringField.Create(Self);
  XD_CustState := TStringField.Create(Self);
  XD_CustTermsExplained := TStringField.Create(Self);
  XD_CustLatitude := TFloatField.Create(Self);
  XD_CustLongitude := TFloatField.Create(Self);
  WDS_Cust := TDataSource.Create(Self);

  ApiConnection.BeforeLoadDFMValues;
  XD_UserData.BeforeLoadDFMValues;
  XD_UserDatauser.BeforeLoadDFMValues;
  XD_UserDataUserTypeID.BeforeLoadDFMValues;
  XD_UserDataSRNumber.BeforeLoadDFMValues;
  XD_UserDatapass.BeforeLoadDFMValues;
  XD_UserDatanextord.BeforeLoadDFMValues;
  XD_UserDatalastlogin.BeforeLoadDFMValues;
  XD_UserDatacuscode.BeforeLoadDFMValues;
  XD_UserDataemail.BeforeLoadDFMValues;
  XD_UserDatahas_multi_accounts.BeforeLoadDFMValues;
  XD_UserDataOrderGuideShowEntries.BeforeLoadDFMValues;
  XD_UserDataCatalogLength.BeforeLoadDFMValues;
  XD_UserDataInfoRequested.BeforeLoadDFMValues;
  XD_UserDataInfoRequestedDate.BeforeLoadDFMValues;
  WDS_UserData.BeforeLoadDFMValues;
  XD_Cust.BeforeLoadDFMValues;
  XD_CustCust.BeforeLoadDFMValues;
  XD_CustCustName.BeforeLoadDFMValues;
  XD_CustSRNum.BeforeLoadDFMValues;
  XD_CustAddress1.BeforeLoadDFMValues;
  XD_CustAddress2.BeforeLoadDFMValues;
  XD_CustCity.BeforeLoadDFMValues;
  XD_CustState.BeforeLoadDFMValues;
  XD_CustTermsExplained.BeforeLoadDFMValues;
  XD_CustLatitude.BeforeLoadDFMValues;
  XD_CustLongitude.BeforeLoadDFMValues;
  WDS_Cust.BeforeLoadDFMValues;
  try
    Name := 'Dm';
    Height := 466;
    Width := 711;
    ApiConnection.SetParentComponent(Self);
    ApiConnection.Name := 'ApiConnection';
    ApiConnection.URL := 'https://www.mymvf.com:2002/tms/xdata';
    ApiConnection.Connected := True;
    ApiConnection.Left := 39;
    ApiConnection.Top := 16;
    XD_UserData.SetParentComponent(Self);
    XD_UserData.Name := 'XD_UserData';
    XD_UserData.EntitySetName := 'userdata';
    XD_UserData.Connection := ApiConnection;
    XD_UserData.Left := 64;
    XD_UserData.Top := 96;
    XD_UserDatauser.SetParentComponent(XD_UserData);
    XD_UserDatauser.Name := 'XD_UserDatauser';
    XD_UserDatauser.FieldName := 'user';
    XD_UserDatauser.Required := True;
    XD_UserDatauser.Size := 30;
    XD_UserDataUserTypeID.SetParentComponent(XD_UserData);
    XD_UserDataUserTypeID.Name := 'XD_UserDataUserTypeID';
    XD_UserDataUserTypeID.FieldName := 'UserTypeID';
    XD_UserDataUserTypeID.Required := True;
    XD_UserDataSRNumber.SetParentComponent(XD_UserData);
    XD_UserDataSRNumber.Name := 'XD_UserDataSRNumber';
    XD_UserDataSRNumber.FieldName := 'SRNumber';
    XD_UserDataSRNumber.Required := True;
    XD_UserDatapass.SetParentComponent(XD_UserData);
    XD_UserDatapass.Name := 'XD_UserDatapass';
    XD_UserDatapass.FieldName := 'pass';
    XD_UserDatapass.Required := True;
    XD_UserDatapass.Size := 30;
    XD_UserDatanextord.SetParentComponent(XD_UserData);
    XD_UserDatanextord.Name := 'XD_UserDatanextord';
    XD_UserDatanextord.FieldName := 'nextord';
    XD_UserDatanextord.Required := True;
    XD_UserDatalastlogin.SetParentComponent(XD_UserData);
    XD_UserDatalastlogin.Name := 'XD_UserDatalastlogin';
    XD_UserDatalastlogin.FieldName := 'lastlogin';
    XD_UserDatalastlogin.Required := True;
    XD_UserDatacuscode.SetParentComponent(XD_UserData);
    XD_UserDatacuscode.Name := 'XD_UserDatacuscode';
    XD_UserDatacuscode.FieldName := 'cuscode';
    XD_UserDatacuscode.Required := True;
    XD_UserDataemail.SetParentComponent(XD_UserData);
    XD_UserDataemail.Name := 'XD_UserDataemail';
    XD_UserDataemail.FieldName := 'email';
    XD_UserDataemail.Required := True;
    XD_UserDataemail.Size := 40;
    XD_UserDatahas_multi_accounts.SetParentComponent(XD_UserData);
    XD_UserDatahas_multi_accounts.Name := 'XD_UserDatahas_multi_accounts';
    XD_UserDatahas_multi_accounts.FieldName := 'has_multi_accounts';
    XD_UserDatahas_multi_accounts.Required := True;
    XD_UserDataOrderGuideShowEntries.SetParentComponent(XD_UserData);
    XD_UserDataOrderGuideShowEntries.Name := 'XD_UserDataOrderGuideShowEntries';
    XD_UserDataOrderGuideShowEntries.FieldName := 'OrderGuideShowEntries';
    XD_UserDataOrderGuideShowEntries.Required := True;
    XD_UserDataCatalogLength.SetParentComponent(XD_UserData);
    XD_UserDataCatalogLength.Name := 'XD_UserDataCatalogLength';
    XD_UserDataCatalogLength.FieldName := 'CatalogLength';
    XD_UserDataCatalogLength.Required := True;
    XD_UserDataInfoRequested.SetParentComponent(XD_UserData);
    XD_UserDataInfoRequested.Name := 'XD_UserDataInfoRequested';
    XD_UserDataInfoRequested.FieldName := 'InfoRequested';
    XD_UserDataInfoRequested.Size := 50;
    XD_UserDataInfoRequestedDate.SetParentComponent(XD_UserData);
    XD_UserDataInfoRequestedDate.Name := 'XD_UserDataInfoRequestedDate';
    XD_UserDataInfoRequestedDate.FieldName := 'InfoRequestedDate';
    WDS_UserData.SetParentComponent(Self);
    WDS_UserData.Name := 'WDS_UserData';
    WDS_UserData.DataSet := XD_UserData;
    WDS_UserData.Left := 136;
    WDS_UserData.Top := 80;
    XD_Cust.SetParentComponent(Self);
    XD_Cust.Name := 'XD_Cust';
    XD_Cust.EntitySetName := 'dlcust';
    XD_Cust.Connection := ApiConnection;
    XD_Cust.Left := 64;
    XD_Cust.Top := 160;
    XD_CustCust.SetParentComponent(XD_Cust);
    XD_CustCust.Name := 'XD_CustCust';
    XD_CustCust.FieldName := 'Cust';
    XD_CustCustName.SetParentComponent(XD_Cust);
    XD_CustCustName.Name := 'XD_CustCustName';
    XD_CustCustName.FieldName := 'CustName';
    XD_CustCustName.Size := 255;
    XD_CustSRNum.SetParentComponent(XD_Cust);
    XD_CustSRNum.Name := 'XD_CustSRNum';
    XD_CustSRNum.FieldName := 'SRNum';
    XD_CustAddress1.SetParentComponent(XD_Cust);
    XD_CustAddress1.Name := 'XD_CustAddress1';
    XD_CustAddress1.FieldName := 'Address1';
    XD_CustAddress1.Size := 255;
    XD_CustAddress2.SetParentComponent(XD_Cust);
    XD_CustAddress2.Name := 'XD_CustAddress2';
    XD_CustAddress2.FieldName := 'Address2';
    XD_CustAddress2.Size := 255;
    XD_CustCity.SetParentComponent(XD_Cust);
    XD_CustCity.Name := 'XD_CustCity';
    XD_CustCity.FieldName := 'City';
    XD_CustCity.Size := 255;
    XD_CustState.SetParentComponent(XD_Cust);
    XD_CustState.Name := 'XD_CustState';
    XD_CustState.FieldName := 'State';
    XD_CustState.Size := 255;
    XD_CustTermsExplained.SetParentComponent(XD_Cust);
    XD_CustTermsExplained.Name := 'XD_CustTermsExplained';
    XD_CustTermsExplained.FieldName := 'TermsExplained';
    XD_CustTermsExplained.Size := 255;
    XD_CustLatitude.SetParentComponent(XD_Cust);
    XD_CustLatitude.Name := 'XD_CustLatitude';
    XD_CustLatitude.FieldName := 'Latitude';
    XD_CustLongitude.SetParentComponent(XD_Cust);
    XD_CustLongitude.Name := 'XD_CustLongitude';
    XD_CustLongitude.FieldName := 'Longitude';
    WDS_Cust.SetParentComponent(Self);
    WDS_Cust.Name := 'WDS_Cust';
    WDS_Cust.DataSet := XD_Cust;
    WDS_Cust.Left := 120;
    WDS_Cust.Top := 160;
  finally
    ApiConnection.AfterLoadDFMValues;
    XD_UserData.AfterLoadDFMValues;
    XD_UserDatauser.AfterLoadDFMValues;
    XD_UserDataUserTypeID.AfterLoadDFMValues;
    XD_UserDataSRNumber.AfterLoadDFMValues;
    XD_UserDatapass.AfterLoadDFMValues;
    XD_UserDatanextord.AfterLoadDFMValues;
    XD_UserDatalastlogin.AfterLoadDFMValues;
    XD_UserDatacuscode.AfterLoadDFMValues;
    XD_UserDataemail.AfterLoadDFMValues;
    XD_UserDatahas_multi_accounts.AfterLoadDFMValues;
    XD_UserDataOrderGuideShowEntries.AfterLoadDFMValues;
    XD_UserDataCatalogLength.AfterLoadDFMValues;
    XD_UserDataInfoRequested.AfterLoadDFMValues;
    XD_UserDataInfoRequestedDate.AfterLoadDFMValues;
    WDS_UserData.AfterLoadDFMValues;
    XD_Cust.AfterLoadDFMValues;
    XD_CustCust.AfterLoadDFMValues;
    XD_CustCustName.AfterLoadDFMValues;
    XD_CustSRNum.AfterLoadDFMValues;
    XD_CustAddress1.AfterLoadDFMValues;
    XD_CustAddress2.AfterLoadDFMValues;
    XD_CustCity.AfterLoadDFMValues;
    XD_CustState.AfterLoadDFMValues;
    XD_CustTermsExplained.AfterLoadDFMValues;
    XD_CustLatitude.AfterLoadDFMValues;
    XD_CustLongitude.AfterLoadDFMValues;
    WDS_Cust.AfterLoadDFMValues;
  end;
end;

end.
