unit Landing;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TFrmLanding = class(TForm)
    WebButton1: TButton;
    WebButton2: TButton;
    WebButton3: TButton;
    procedure WebButton1Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FrmLanding: TFrmLanding;

implementation

Uses Data,Orderguide,Catalog,Pricebook;

{$R *.dfm}

procedure TFrmLanding.WebButton1Click(Sender: TObject);
begin
  Application.CreateForm(TFrmOrderguide, FrmOrderguide);
  FrmOrderguide.Show;
end;

procedure TFrmLanding.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);

  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  try
    Name := 'FrmLanding';
    Width := 640;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 32;
    WebButton1.Top := 56;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Orderguide';
    WebButton1.ElementClassName := 'btn btn-light';
    WebButton1.ElementFont := efCSS;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 32;
    WebButton2.Top := 87;
    WebButton2.Width := 96;
    WebButton2.Height := 25;
    WebButton2.Caption := 'Catalog';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn btn-light';
    WebButton2.ElementFont := efCSS;
    WebButton2.HeightStyle := ssAuto;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 32;
    WebButton3.Top := 118;
    WebButton3.Width := 96;
    WebButton3.Height := 25;
    WebButton3.Caption := 'Pricebook';
    WebButton3.ChildOrder := 2;
    WebButton3.ElementClassName := 'btn btn-light';
    WebButton3.ElementFont := efCSS;
    WebButton3.HeightStyle := ssAuto;
    WebButton3.HeightPercent := 100.000000000000000000;
    WebButton3.WidthPercent := 100.000000000000000000;
  finally
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
  end;
end;

end.
