unit Statement;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.DBCtrls, Data.DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Grids, WEBLib.Buttons;

type
  TFrmStatement = class(TForm)
    PnlHeader: TPanel;
    PnlAcctInfo: TPanel;
    WebDBLabel1: TDBLabel;
    WebDBLabel2: TDBLabel;
    WebDBLabel3: TDBLabel;
    WebDBLabel4: TDBLabel;
    WebDBLabel5: TDBLabel;
    XD_StateHead: TXDataWebDataSet;
    XD_StateHeadAuto: TIntegerField;
    XD_StateHeadCusNum: TIntegerField;
    XD_StateHeadTotal: TFloatField;
    XD_StateHeadAge1: TFloatField;
    XD_StateHeadAge2: TFloatField;
    XD_StateHeadAge3: TFloatField;
    XD_StateHeadAge4: TFloatField;
    XD_StateHeadAge5: TFloatField;
    XD_StateHeadCreditHoldFlag: TStringField;
    XD_StateDetail: TXDataWebDataSet;
    XD_StateDetailAuto: TIntegerField;
    XD_StateDetailCustNum: TIntegerField;
    XD_StateDetailInvDate: TDateTimeField;
    XD_StateDetailInvNum: TIntegerField;
    XD_StateDetailInvTyp: TStringField;
    XD_StateDetailInvRef: TStringField;
    XD_StateDetailInvAmt: TFloatField;
    XD_StateDetailInvBal: TFloatField;
    XD_StateDetailAgeing: TIntegerField;
    XD_StateDetailApplyTo: TIntegerField;
    WDS_StateHead: TDataSource;
    WDS_StateDetail: TDataSource;
    WebPanel1: TPanel;
    WebDBResponsiveGrid1: TDBResponsiveGrid;
    WebPanel2: TPanel;
    WebResponsiveGridPanel1: TResponsiveGridPanel;
    XD_StateHeadCurrent: TFloatField;
    XD_StateHeadPastDue: TFloatField;
    WebPanel3: TPanel;
    WebPanel5: TPanel;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebDBLabel6: TDBLabel;
    WebDBLabel7: TDBLabel;
    WebDBLabel8: TDBLabel;
    WebPanel6: TPanel;
    WebPanel4: TPanel;
    WebLabel4: TLabel;
    WebLabel6: TLabel;
    WebDBLabel9: TDBLabel;
    WebDBLabel11: TDBLabel;
    XD_StateDetailItemBalance: TFloatField;
    WebLabel5: TLabel;
    WebDBLabel10: TDBLabel;
    WebLabel7: TLabel;
    WebDBLabel12: TDBLabel;
    WebLabel8: TLabel;
    WebDBLabel13: TDBLabel;
    WebLabel9: TLabel;
    WebDBLabel14: TDBLabel;
    MenuBtn: TSpeedButton;
    MenuPnl: TPanel;
    WebButton5: TButton;
    WebButton6: TButton;
    WebButton7: TButton;
    WebButton8: TButton;
    WebButton9: TButton;
    WebButton4: TButton;
    WebButton1: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebResponsiveGridPanel1LayoutChange(Sender: TObject;
      ALayout: TResponsiveLayoutItem);
    procedure XD_StateHeadCalcFields(DataSet: TDataSet);
    procedure WebFormResize(Sender: TObject);
    procedure MenuBtnClick(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton6Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FrmStatement: TFrmStatement;

implementation

Uses Data,Catalog,OrderGuide,AccountInfo;

{$R *.dfm}

procedure TFrmStatement.MenuBtnClick(Sender: TObject);
begin
  MenuPnl.Left := MenuBtn.Left;
  MenuPnl.Top := MenuBtn.Top + MenuBtn.Height + 5;
  MenuPnl.BringToFront;
  if MenuPnl.Visible then MenuPnl.Visible := False
    else MenuPnl.Visible := True;
end;

procedure TFrmStatement.WebButton4Click(Sender: TObject);
begin
  Application.CreateForm(TFrmCatalog, FrmCatalog);
end;

procedure TFrmStatement.WebButton5Click(Sender: TObject);
begin
  Application.CreateForm(TFrmOrderguide, FrmOrderguide);
end;

procedure TFrmStatement.WebButton6Click(Sender: TObject);
begin
  Application.CreateForm(TFrmAccountInfo, FrmAccountInfo);
end;

procedure TFrmStatement.WebFormResize(Sender: TObject);
begin
  PnlAcctInfo.Left := Round((PnlHeader.Width/2) - (PnlAcctInfo.Width/2));
  WebPanel5.Left := Round((WebPanel3.Width/2)-(WebPanel5.Width/2));
  WebPanel4.Left := Round((WebPanel6.Width/2)-(WebPanel4.Width/2));
  WebDBResponsiveGrid1.Options.ItemWidthMin := WebDBResponsiveGrid1.Width;
end;

procedure TFrmStatement.WebFormShow(Sender: TObject);
begin
  XD_StateDetail.QueryString := '$filter=(CustNum eq '''+Dm.XD_CustCust.AsString+''')';
  XD_StateDetail.Load;
  XD_StateHead.QueryString := '$filter=(CusNum eq '''+Dm.XD_CustCust.AsString+''')';
  XD_StateHead.Load;
end;

procedure TFrmStatement.WebResponsiveGridPanel1LayoutChange(Sender: TObject;
  ALayout: TResponsiveLayoutItem);
begin
  case ALayout.Index of
  0:
    begin
      WebPanel3.Visible := True;
      WebPanel4.Visible := False;
    end;
  1:
    begin
      WebPanel3.Visible := False;
      WebPanel4.Visible := True;
    end;
  end;
end;

procedure TFrmStatement.XD_StateHeadCalcFields(DataSet: TDataSet);
begin
  XD_StateHeadCurrent.AsFloat := XD_StateHeadAge1.ASFloat+XD_StateHeadAge2.ASFloat;
  XD_StateHeadPastDue.AsFloat := XD_StateHeadAge3.ASFloat+XD_StateHeadAge4.ASFloat+XD_StateHeadAge5.ASFloat;
end;

procedure TFrmStatement.LoadDFMValues;
begin
  inherited LoadDFMValues;

  PnlHeader := TPanel.Create('statePnlHead');
  PnlAcctInfo := TPanel.Create('statePnlAcctDtl');
  WebDBLabel1 := TDBLabel.Create(Self);
  WebDBLabel2 := TDBLabel.Create(Self);
  WebDBLabel3 := TDBLabel.Create(Self);
  WebDBLabel4 := TDBLabel.Create(Self);
  WebDBLabel5 := TDBLabel.Create(Self);
  MenuBtn := TSpeedButton.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebDBResponsiveGrid1 := TDBResponsiveGrid.Create('stateGrid');
  WebPanel2 := TPanel.Create(Self);
  WebResponsiveGridPanel1 := TResponsiveGridPanel.Create(Self);
  WebPanel3 := TPanel.Create('statePnlSmBG');
  WebPanel5 := TPanel.Create('statePnlTotSm');
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebDBLabel6 := TDBLabel.Create('txtCurr');
  WebDBLabel7 := TDBLabel.Create('txtPD');
  WebDBLabel8 := TDBLabel.Create('txtBalSm');
  WebPanel6 := TPanel.Create('statePnlLgBg');
  WebPanel4 := TPanel.Create('statePnlTotLg');
  WebLabel4 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebDBLabel9 := TDBLabel.Create('txtAge1');
  WebDBLabel11 := TDBLabel.Create('txtBalLg');
  WebLabel5 := TLabel.Create(Self);
  WebDBLabel10 := TDBLabel.Create('txtAge2');
  WebLabel7 := TLabel.Create(Self);
  WebDBLabel12 := TDBLabel.Create('txtAge3');
  WebLabel8 := TLabel.Create(Self);
  WebDBLabel13 := TDBLabel.Create('txtAge4');
  WebLabel9 := TLabel.Create(Self);
  WebDBLabel14 := TDBLabel.Create('txtAge5');
  MenuPnl := TPanel.Create('statePanelMenu');
  WebButton5 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);
  WebButton9 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  XD_StateHead := TXDataWebDataSet.Create(Self);
  XD_StateHeadAuto := TIntegerField.Create(Self);
  XD_StateHeadCusNum := TIntegerField.Create(Self);
  XD_StateHeadTotal := TFloatField.Create(Self);
  XD_StateHeadAge1 := TFloatField.Create(Self);
  XD_StateHeadAge2 := TFloatField.Create(Self);
  XD_StateHeadAge3 := TFloatField.Create(Self);
  XD_StateHeadAge4 := TFloatField.Create(Self);
  XD_StateHeadAge5 := TFloatField.Create(Self);
  XD_StateHeadCreditHoldFlag := TStringField.Create(Self);
  XD_StateHeadCurrent := TFloatField.Create(Self);
  XD_StateHeadPastDue := TFloatField.Create(Self);
  XD_StateDetail := TXDataWebDataSet.Create(Self);
  XD_StateDetailAuto := TIntegerField.Create(Self);
  XD_StateDetailCustNum := TIntegerField.Create(Self);
  XD_StateDetailInvDate := TDateTimeField.Create(Self);
  XD_StateDetailInvNum := TIntegerField.Create(Self);
  XD_StateDetailInvTyp := TStringField.Create(Self);
  XD_StateDetailInvRef := TStringField.Create(Self);
  XD_StateDetailInvAmt := TFloatField.Create(Self);
  XD_StateDetailInvBal := TFloatField.Create(Self);
  XD_StateDetailAgeing := TIntegerField.Create(Self);
  XD_StateDetailApplyTo := TIntegerField.Create(Self);
  XD_StateDetailItemBalance := TFloatField.Create(Self);
  WDS_StateHead := TDataSource.Create(Self);
  WDS_StateDetail := TDataSource.Create(Self);

  PnlHeader.BeforeLoadDFMValues;
  PnlAcctInfo.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  WebDBLabel2.BeforeLoadDFMValues;
  WebDBLabel3.BeforeLoadDFMValues;
  WebDBLabel4.BeforeLoadDFMValues;
  WebDBLabel5.BeforeLoadDFMValues;
  MenuBtn.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebDBResponsiveGrid1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebResponsiveGridPanel1.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebPanel5.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebDBLabel6.BeforeLoadDFMValues;
  WebDBLabel7.BeforeLoadDFMValues;
  WebDBLabel8.BeforeLoadDFMValues;
  WebPanel6.BeforeLoadDFMValues;
  WebPanel4.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebDBLabel9.BeforeLoadDFMValues;
  WebDBLabel11.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebDBLabel10.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebDBLabel12.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebDBLabel13.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebDBLabel14.BeforeLoadDFMValues;
  MenuPnl.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  WebButton9.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  XD_StateHead.BeforeLoadDFMValues;
  XD_StateHeadAuto.BeforeLoadDFMValues;
  XD_StateHeadCusNum.BeforeLoadDFMValues;
  XD_StateHeadTotal.BeforeLoadDFMValues;
  XD_StateHeadAge1.BeforeLoadDFMValues;
  XD_StateHeadAge2.BeforeLoadDFMValues;
  XD_StateHeadAge3.BeforeLoadDFMValues;
  XD_StateHeadAge4.BeforeLoadDFMValues;
  XD_StateHeadAge5.BeforeLoadDFMValues;
  XD_StateHeadCreditHoldFlag.BeforeLoadDFMValues;
  XD_StateHeadCurrent.BeforeLoadDFMValues;
  XD_StateHeadPastDue.BeforeLoadDFMValues;
  XD_StateDetail.BeforeLoadDFMValues;
  XD_StateDetailAuto.BeforeLoadDFMValues;
  XD_StateDetailCustNum.BeforeLoadDFMValues;
  XD_StateDetailInvDate.BeforeLoadDFMValues;
  XD_StateDetailInvNum.BeforeLoadDFMValues;
  XD_StateDetailInvTyp.BeforeLoadDFMValues;
  XD_StateDetailInvRef.BeforeLoadDFMValues;
  XD_StateDetailInvAmt.BeforeLoadDFMValues;
  XD_StateDetailInvBal.BeforeLoadDFMValues;
  XD_StateDetailAgeing.BeforeLoadDFMValues;
  XD_StateDetailApplyTo.BeforeLoadDFMValues;
  XD_StateDetailItemBalance.BeforeLoadDFMValues;
  WDS_StateHead.BeforeLoadDFMValues;
  WDS_StateDetail.BeforeLoadDFMValues;
  try
    Name := 'FrmStatement';
    Width := 1123;
    Height := 757;
    Caption := 'Maplevale Farms';
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    PnlHeader.SetParentComponent(Self);
    PnlHeader.Name := 'PnlHeader';
    PnlHeader.Left := 0;
    PnlHeader.Top := 0;
    PnlHeader.Width := 1123;
    PnlHeader.Height := 77;
    PnlHeader.ElementClassName := 'card';
    PnlHeader.HeightPercent := 100.000000000000000000;
    PnlHeader.WidthPercent := 100.000000000000000000;
    PnlHeader.Align := alTop;
    PnlHeader.BorderColor := clSilver;
    PnlHeader.BorderStyle := bsSingle;
    PnlHeader.ChildOrder := 1;
    PnlHeader.ElementBodyClassName := 'card-body';
    PnlHeader.ElementFont := efCSS;
    PnlAcctInfo.SetParentComponent(PnlHeader);
    PnlAcctInfo.Name := 'PnlAcctInfo';
    PnlAcctInfo.Left := 120;
    PnlAcctInfo.Top := 1;
    PnlAcctInfo.Width := 257;
    PnlAcctInfo.Height := 76;
    PnlAcctInfo.ElementClassName := 'card border-0';
    PnlAcctInfo.HeightPercent := 100.000000000000000000;
    PnlAcctInfo.WidthPercent := 100.000000000000000000;
    PnlAcctInfo.BorderColor := clSilver;
    PnlAcctInfo.BorderStyle := bsSingle;
    PnlAcctInfo.ChildOrder := 2;
    PnlAcctInfo.ElementBodyClassName := 'card-body';
    PnlAcctInfo.ElementFont := efCSS;
    WebDBLabel1.SetParentComponent(PnlAcctInfo);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 10;
    WebDBLabel1.Top := 8;
    WebDBLabel1.Width := 90;
    WebDBLabel1.Height := 18;
    WebDBLabel1.Caption := 'WebDBLabel1';
    WebDBLabel1.ElementFont := efCSS;
    WebDBLabel1.HeightStyle := ssAuto;
    WebDBLabel1.HeightPercent := 100.000000000000000000;
    WebDBLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel1.DataField := 'Cust';
    WebDBLabel1.DataSource := Dm.WDS_Cust;
    WebDBLabel2.SetParentComponent(PnlAcctInfo);
    WebDBLabel2.Name := 'WebDBLabel2';
    WebDBLabel2.Left := 71;
    WebDBLabel2.Top := 8;
    WebDBLabel2.Width := 90;
    WebDBLabel2.Height := 18;
    WebDBLabel2.Caption := 'WebDBLabel1';
    WebDBLabel2.ElementFont := efCSS;
    WebDBLabel2.HeightStyle := ssAuto;
    WebDBLabel2.HeightPercent := 100.000000000000000000;
    WebDBLabel2.WidthPercent := 100.000000000000000000;
    WebDBLabel2.DataField := 'CustName';
    WebDBLabel2.DataSource := Dm.WDS_Cust;
    WebDBLabel3.SetParentComponent(PnlAcctInfo);
    WebDBLabel3.Name := 'WebDBLabel3';
    WebDBLabel3.Left := 40;
    WebDBLabel3.Top := 53;
    WebDBLabel3.Width := 90;
    WebDBLabel3.Height := 18;
    WebDBLabel3.Caption := 'WebDBLabel3';
    WebDBLabel3.ElementFont := efCSS;
    WebDBLabel3.HeightStyle := ssAuto;
    WebDBLabel3.HeightPercent := 100.000000000000000000;
    WebDBLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel3.DataField := 'TermsExplained';
    WebDBLabel3.DataSource := Dm.WDS_Cust;
    WebDBLabel4.SetParentComponent(PnlAcctInfo);
    WebDBLabel4.Name := 'WebDBLabel4';
    WebDBLabel4.Left := 40;
    WebDBLabel4.Top := 32;
    WebDBLabel4.Width := 90;
    WebDBLabel4.Height := 18;
    WebDBLabel4.Caption := 'WebDBLabel3';
    WebDBLabel4.ElementFont := efCSS;
    WebDBLabel4.HeightStyle := ssAuto;
    WebDBLabel4.HeightPercent := 100.000000000000000000;
    WebDBLabel4.WidthPercent := 100.000000000000000000;
    WebDBLabel4.DataField := 'City';
    WebDBLabel4.DataSource := Dm.WDS_Cust;
    WebDBLabel5.SetParentComponent(PnlAcctInfo);
    WebDBLabel5.Name := 'WebDBLabel5';
    WebDBLabel5.Left := 192;
    WebDBLabel5.Top := 32;
    WebDBLabel5.Width := 18;
    WebDBLabel5.Height := 18;
    WebDBLabel5.Caption := 'ST';
    WebDBLabel5.ElementFont := efCSS;
    WebDBLabel5.HeightStyle := ssAuto;
    WebDBLabel5.HeightPercent := 100.000000000000000000;
    WebDBLabel5.WidthPercent := 100.000000000000000000;
    WebDBLabel5.DataField := 'State';
    WebDBLabel5.DataSource := Dm.WDS_Cust;
    MenuBtn.SetParentComponent(PnlHeader);
    MenuBtn.Name := 'MenuBtn';
    MenuBtn.Left := 14;
    MenuBtn.Top := 18;
    MenuBtn.Width := 43;
    MenuBtn.Height := 42;
    MenuBtn.Color := clNone;
    MenuBtn.ElementClassName := 'btn btn-dark';
    MenuBtn.ElementFont := efCSS;
    MenuBtn.HeightPercent := 100.000000000000000000;
    MenuBtn.MaterialGlyph := 'menu';
    MenuBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MenuBtn, Self, 'OnClick', 'MenuBtnClick');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 177;
    WebPanel1.Width := 1123;
    WebPanel1.Height := 580;
    WebPanel1.ElementClassName := 'card';
    WebPanel1.HeightPercent := 100.000000000000000000;
    WebPanel1.WidthPercent := 100.000000000000000000;
    WebPanel1.Align := alClient;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 4;
    WebPanel1.ElementBodyClassName := 'card-body';
    WebPanel1.ElementFont := efCSS;
    WebDBResponsiveGrid1.SetParentComponent(WebPanel1);
    WebDBResponsiveGrid1.Name := 'WebDBResponsiveGrid1';
    WebDBResponsiveGrid1.Left := 0;
    WebDBResponsiveGrid1.Top := 0;
    WebDBResponsiveGrid1.Width := 1123;
    WebDBResponsiveGrid1.Height := 580;
    WebDBResponsiveGrid1.HeightPercent := 100.000000000000000000;
    WebDBResponsiveGrid1.WidthPercent := 100.000000000000000000;
    WebDBResponsiveGrid1.Align := alClient;
    WebDBResponsiveGrid1.ElementFont := efCSS;
    WebDBResponsiveGrid1.ItemIndex := 0;
    WebDBResponsiveGrid1.Options.ItemBorderColor := clBlack;
    WebDBResponsiveGrid1.Options.ItemHeight := 50;
    WebDBResponsiveGrid1.Options.ItemSelectedColor := clHighlight;
    WebDBResponsiveGrid1.Options.ItemSelectedBorderColor := clHighlight;
    WebDBResponsiveGrid1.Options.ItemSelectedTextColor := clWhite;
    WebDBResponsiveGrid1.Options.ItemPadding := 1;
    WebDBResponsiveGrid1.Options.ItemTemplate := '<div>(%InvNum%)  (%InvDate%) (%InvRef%) <p align=right> (%InvTyp%)    Amount:  (%InvAmt%)  Balance:  (%ItemBalance%)</p>';
    WebDBResponsiveGrid1.Options.ItemWidthMin := 300;
    WebDBResponsiveGrid1.DataSource := WDS_StateDetail;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 77;
    WebPanel2.Width := 1123;
    WebPanel2.Height := 100;
    WebPanel2.ElementClassName := 'card';
    WebPanel2.HeightPercent := 100.000000000000000000;
    WebPanel2.WidthPercent := 100.000000000000000000;
    WebPanel2.Align := alTop;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 3;
    WebPanel2.ElementBodyClassName := 'card-body';
    WebPanel2.ElementFont := efCSS;
    WebResponsiveGridPanel1.SetParentComponent(WebPanel2);
    WebResponsiveGridPanel1.Name := 'WebResponsiveGridPanel1';
    WebResponsiveGridPanel1.Left := 0;
    WebResponsiveGridPanel1.Top := 0;
    WebResponsiveGridPanel1.Width := 1123;
    WebResponsiveGridPanel1.Height := 100;
    WebResponsiveGridPanel1.HeightPercent := 100.000000000000000000;
    WebResponsiveGridPanel1.WidthPercent := 100.000000000000000000;
    WebResponsiveGridPanel1.Align := alClient;
    WebResponsiveGridPanel1.ControlCollection.Clear;
    with WebResponsiveGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebPanel3;
    end;
    with WebResponsiveGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebPanel6;
    end;
    WebResponsiveGridPanel1.Color := clWhite;
    WebResponsiveGridPanel1.ElementFont := efCSS;
    WebResponsiveGridPanel1.Layout.Clear;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 500;
    end;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    SetEvent(WebResponsiveGridPanel1, Self, 'OnLayoutChange', 'WebResponsiveGridPanel1LayoutChange');
    WebPanel3.SetParentComponent(WebResponsiveGridPanel1);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 0;
    WebPanel3.Width := 391;
    WebPanel3.Height := 100;
    WebPanel3.ElementClassName := 'card';
    WebPanel3.HeightPercent := 100.000000000000000000;
    WebPanel3.WidthPercent := 100.000000000000000000;
    WebPanel3.Align := alClient;
    WebPanel3.BorderColor := clSilver;
    WebPanel3.BorderStyle := bsSingle;
    WebPanel3.ChildOrder := 1;
    WebPanel3.ElementBodyClassName := 'card-body';
    WebPanel3.ElementFont := efCSS;
    WebPanel5.SetParentComponent(WebPanel3);
    WebPanel5.Name := 'WebPanel5';
    WebPanel5.Left := 56;
    WebPanel5.Top := 0;
    WebPanel5.Width := 297;
    WebPanel5.Height := 100;
    WebPanel5.ElementClassName := 'card border-0';
    WebPanel5.HeightPercent := 100.000000000000000000;
    WebPanel5.WidthPercent := 100.000000000000000000;
    WebPanel5.BorderColor := clSilver;
    WebPanel5.BorderStyle := bsSingle;
    WebPanel5.ElementBodyClassName := 'card-body';
    WebPanel5.ElementFont := efCSS;
    WebLabel1.SetParentComponent(WebPanel5);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 3;
    WebLabel1.Top := 6;
    WebLabel1.Width := 63;
    WebLabel1.Height := 18;
    WebLabel1.Caption := 'Current:  ';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(WebPanel5);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 3;
    WebLabel2.Top := 30;
    WebLabel2.Width := 64;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'Past Due:';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.HeightStyle := ssAuto;
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(WebPanel5);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 3;
    WebLabel3.Top := 70;
    WebLabel3.Width := 93;
    WebLabel3.Height := 18;
    WebLabel3.Caption := 'Total Balance:';
    WebLabel3.ElementFont := efCSS;
    WebLabel3.HeightStyle := ssAuto;
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel6.SetParentComponent(WebPanel5);
    WebDBLabel6.Name := 'WebDBLabel6';
    WebDBLabel6.Left := 160;
    WebDBLabel6.Top := 6;
    WebDBLabel6.Width := 90;
    WebDBLabel6.Height := 18;
    WebDBLabel6.Alignment := taRightJustify;
    WebDBLabel6.AutoSize := False;
    WebDBLabel6.Caption := 'WebDBLabel6';
    WebDBLabel6.ElementClassName := 'h6';
    WebDBLabel6.ElementFont := efCSS;
    WebDBLabel6.HeightStyle := ssAuto;
    WebDBLabel6.HeightPercent := 100.000000000000000000;
    WebDBLabel6.WidthPercent := 100.000000000000000000;
    WebDBLabel6.DataField := 'Current';
    WebDBLabel6.DataSource := WDS_StateHead;
    WebDBLabel7.SetParentComponent(WebPanel5);
    WebDBLabel7.Name := 'WebDBLabel7';
    WebDBLabel7.Left := 160;
    WebDBLabel7.Top := 30;
    WebDBLabel7.Width := 90;
    WebDBLabel7.Height := 18;
    WebDBLabel7.Alignment := taRightJustify;
    WebDBLabel7.AutoSize := False;
    WebDBLabel7.Caption := 'WebDBLabel6';
    WebDBLabel7.ElementClassName := 'h6';
    WebDBLabel7.ElementFont := efCSS;
    WebDBLabel7.HeightStyle := ssAuto;
    WebDBLabel7.HeightPercent := 100.000000000000000000;
    WebDBLabel7.WidthPercent := 100.000000000000000000;
    WebDBLabel7.DataField := 'PastDue';
    WebDBLabel7.DataSource := WDS_StateHead;
    WebDBLabel8.SetParentComponent(WebPanel5);
    WebDBLabel8.Name := 'WebDBLabel8';
    WebDBLabel8.Left := 160;
    WebDBLabel8.Top := 70;
    WebDBLabel8.Width := 90;
    WebDBLabel8.Height := 18;
    WebDBLabel8.Alignment := taRightJustify;
    WebDBLabel8.AutoSize := False;
    WebDBLabel8.Caption := 'WebDBLabel6';
    WebDBLabel8.ElementClassName := 'h6';
    WebDBLabel8.ElementFont := efCSS;
    WebDBLabel8.HeightStyle := ssAuto;
    WebDBLabel8.HeightPercent := 100.000000000000000000;
    WebDBLabel8.WidthPercent := 100.000000000000000000;
    WebDBLabel8.DataField := 'Total';
    WebDBLabel8.DataSource := WDS_StateHead;
    WebPanel6.SetParentComponent(WebResponsiveGridPanel1);
    WebPanel6.Name := 'WebPanel6';
    WebPanel6.Left := 562;
    WebPanel6.Top := 0;
    WebPanel6.Width := 558;
    WebPanel6.Height := 100;
    WebPanel6.ElementClassName := 'card';
    WebPanel6.HeightPercent := 100.000000000000000000;
    WebPanel6.WidthPercent := 100.000000000000000000;
    WebPanel6.Align := alClient;
    WebPanel6.BorderColor := clSilver;
    WebPanel6.BorderStyle := bsSingle;
    WebPanel6.ChildOrder := 1;
    WebPanel6.ElementBodyClassName := 'card-body';
    WebPanel6.ElementFont := efCSS;
    WebPanel4.SetParentComponent(WebPanel6);
    WebPanel4.Name := 'WebPanel4';
    WebPanel4.Left := 27;
    WebPanel4.Top := 0;
    WebPanel4.Width := 558;
    WebPanel4.Height := 100;
    WebPanel4.ElementClassName := 'card border-0';
    WebPanel4.HeightPercent := 100.000000000000000000;
    WebPanel4.WidthPercent := 100.000000000000000000;
    WebPanel4.Anchors := [];
    WebPanel4.BorderColor := clSilver;
    WebPanel4.BorderStyle := bsSingle;
    WebPanel4.ChildOrder := 1;
    WebPanel4.ElementBodyClassName := 'card-body';
    WebPanel4.ElementFont := efCSS;
    WebLabel4.SetParentComponent(WebPanel4);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 43;
    WebLabel4.Top := 14;
    WebLabel4.Width := 38;
    WebLabel4.Height := 18;
    WebLabel4.Caption := 'Age1:';
    WebLabel4.ElementFont := efCSS;
    WebLabel4.HeightStyle := ssAuto;
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(WebPanel4);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 163;
    WebLabel6.Top := 76;
    WebLabel6.Width := 93;
    WebLabel6.Height := 18;
    WebLabel6.Caption := 'Total Balance:';
    WebLabel6.ElementFont := efCSS;
    WebLabel6.HeightStyle := ssAuto;
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebDBLabel9.SetParentComponent(WebPanel4);
    WebDBLabel9.Name := 'WebDBLabel9';
    WebDBLabel9.Left := 24;
    WebDBLabel9.Top := 38;
    WebDBLabel9.Width := 69;
    WebDBLabel9.Height := 18;
    WebDBLabel9.Alignment := taRightJustify;
    WebDBLabel9.AutoSize := False;
    WebDBLabel9.Caption := '999999.99';
    WebDBLabel9.ElementClassName := 'h6 text-right';
    WebDBLabel9.ElementFont := efCSS;
    WebDBLabel9.HeightStyle := ssAuto;
    WebDBLabel9.HeightPercent := 100.000000000000000000;
    WebDBLabel9.WidthPercent := 100.000000000000000000;
    WebDBLabel9.DataField := 'Age1';
    WebDBLabel9.DataSource := WDS_StateHead;
    WebDBLabel11.SetParentComponent(WebPanel4);
    WebDBLabel11.Name := 'WebDBLabel11';
    WebDBLabel11.Left := 320;
    WebDBLabel11.Top := 76;
    WebDBLabel11.Width := 90;
    WebDBLabel11.Height := 18;
    WebDBLabel11.AutoSize := False;
    WebDBLabel11.Caption := 'WebDBLabel6';
    WebDBLabel11.ElementClassName := 'h6';
    WebDBLabel11.ElementFont := efCSS;
    WebDBLabel11.HeightStyle := ssAuto;
    WebDBLabel11.HeightPercent := 100.000000000000000000;
    WebDBLabel11.WidthPercent := 100.000000000000000000;
    WebDBLabel11.DataField := 'Total';
    WebDBLabel11.DataSource := WDS_StateHead;
    WebLabel5.SetParentComponent(WebPanel4);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 147;
    WebLabel5.Top := 14;
    WebLabel5.Width := 38;
    WebLabel5.Height := 18;
    WebLabel5.Caption := 'Age2:';
    WebLabel5.ElementFont := efCSS;
    WebLabel5.HeightStyle := ssAuto;
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebDBLabel10.SetParentComponent(WebPanel4);
    WebDBLabel10.Name := 'WebDBLabel10';
    WebDBLabel10.Left := 128;
    WebDBLabel10.Top := 38;
    WebDBLabel10.Width := 69;
    WebDBLabel10.Height := 18;
    WebDBLabel10.Alignment := taRightJustify;
    WebDBLabel10.AutoSize := False;
    WebDBLabel10.Caption := '999999.99';
    WebDBLabel10.ElementClassName := 'h6 text-right';
    WebDBLabel10.ElementFont := efCSS;
    WebDBLabel10.HeightStyle := ssAuto;
    WebDBLabel10.HeightPercent := 100.000000000000000000;
    WebDBLabel10.WidthPercent := 100.000000000000000000;
    WebDBLabel10.DataField := 'Age2';
    WebDBLabel10.DataSource := WDS_StateHead;
    WebLabel7.SetParentComponent(WebPanel4);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 251;
    WebLabel7.Top := 14;
    WebLabel7.Width := 38;
    WebLabel7.Height := 18;
    WebLabel7.Caption := 'Age3:';
    WebLabel7.ElementFont := efCSS;
    WebLabel7.HeightStyle := ssAuto;
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebDBLabel12.SetParentComponent(WebPanel4);
    WebDBLabel12.Name := 'WebDBLabel12';
    WebDBLabel12.Left := 232;
    WebDBLabel12.Top := 38;
    WebDBLabel12.Width := 69;
    WebDBLabel12.Height := 18;
    WebDBLabel12.Alignment := taRightJustify;
    WebDBLabel12.AutoSize := False;
    WebDBLabel12.Caption := '999999.99';
    WebDBLabel12.ElementClassName := 'h6 text-right';
    WebDBLabel12.ElementFont := efCSS;
    WebDBLabel12.HeightStyle := ssAuto;
    WebDBLabel12.HeightPercent := 100.000000000000000000;
    WebDBLabel12.WidthPercent := 100.000000000000000000;
    WebDBLabel12.DataField := 'Age3';
    WebDBLabel12.DataSource := WDS_StateHead;
    WebLabel8.SetParentComponent(WebPanel4);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 347;
    WebLabel8.Top := 14;
    WebLabel8.Width := 38;
    WebLabel8.Height := 18;
    WebLabel8.Caption := 'Age4:';
    WebLabel8.ElementFont := efCSS;
    WebLabel8.HeightStyle := ssAuto;
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebDBLabel13.SetParentComponent(WebPanel4);
    WebDBLabel13.Name := 'WebDBLabel13';
    WebDBLabel13.Left := 328;
    WebDBLabel13.Top := 38;
    WebDBLabel13.Width := 69;
    WebDBLabel13.Height := 18;
    WebDBLabel13.Alignment := taRightJustify;
    WebDBLabel13.AutoSize := False;
    WebDBLabel13.Caption := '999999.99';
    WebDBLabel13.ElementClassName := 'h6 text-right';
    WebDBLabel13.ElementFont := efCSS;
    WebDBLabel13.HeightStyle := ssAuto;
    WebDBLabel13.HeightPercent := 100.000000000000000000;
    WebDBLabel13.WidthPercent := 100.000000000000000000;
    WebDBLabel13.DataField := 'Age4';
    WebDBLabel13.DataSource := WDS_StateHead;
    WebLabel9.SetParentComponent(WebPanel4);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 451;
    WebLabel9.Top := 14;
    WebLabel9.Width := 38;
    WebLabel9.Height := 18;
    WebLabel9.Caption := 'Age5:';
    WebLabel9.ElementFont := efCSS;
    WebLabel9.HeightStyle := ssAuto;
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebDBLabel14.SetParentComponent(WebPanel4);
    WebDBLabel14.Name := 'WebDBLabel14';
    WebDBLabel14.Left := 432;
    WebDBLabel14.Top := 38;
    WebDBLabel14.Width := 69;
    WebDBLabel14.Height := 18;
    WebDBLabel14.Alignment := taRightJustify;
    WebDBLabel14.AutoSize := False;
    WebDBLabel14.Caption := '999999.99';
    WebDBLabel14.ElementClassName := 'h6 text-right';
    WebDBLabel14.ElementFont := efCSS;
    WebDBLabel14.HeightStyle := ssAuto;
    WebDBLabel14.HeightPercent := 100.000000000000000000;
    WebDBLabel14.WidthPercent := 100.000000000000000000;
    WebDBLabel14.DataField := 'Age5';
    WebDBLabel14.DataSource := WDS_StateHead;
    MenuPnl.SetParentComponent(Self);
    MenuPnl.Name := 'MenuPnl';
    MenuPnl.Left := 78;
    MenuPnl.Top := 183;
    MenuPnl.Width := 275;
    MenuPnl.Height := 506;
    MenuPnl.ElementClassName := 'card';
    MenuPnl.HeightStyle := ssPercent;
    MenuPnl.HeightPercent := 80.000000000000000000;
    MenuPnl.WidthPercent := 100.000000000000000000;
    MenuPnl.BorderColor := clSilver;
    MenuPnl.BorderStyle := bsSingle;
    MenuPnl.ChildOrder := 2;
    MenuPnl.ElementBodyClassName := 'card-body';
    MenuPnl.ElementFont := efCSS;
    MenuPnl.Visible := False;
    WebButton5.SetParentComponent(MenuPnl);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 12;
    WebButton5.Top := 15;
    WebButton5.Width := 249;
    WebButton5.Height := 33;
    WebButton5.Caption := 'OrderGuide';
    WebButton5.ChildOrder := 1;
    WebButton5.ElementClassName := 'btn btn-success';
    WebButton5.ElementFont := efCSS;
    WebButton5.HeightStyle := ssAuto;
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton6.SetParentComponent(MenuPnl);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 12;
    WebButton6.Top := 94;
    WebButton6.Width := 249;
    WebButton6.Height := 33;
    WebButton6.Caption := 'Account Info';
    WebButton6.ChildOrder := 1;
    WebButton6.ElementClassName := 'btn btn-success';
    WebButton6.ElementFont := efCSS;
    WebButton6.HeightStyle := ssAuto;
    WebButton6.HeightPercent := 100.000000000000000000;
    WebButton6.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
    WebButton7.SetParentComponent(MenuPnl);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 12;
    WebButton7.Top := 133;
    WebButton7.Width := 249;
    WebButton7.Height := 33;
    WebButton7.Caption := 'Payment';
    WebButton7.ChildOrder := 1;
    WebButton7.ElementClassName := 'btn btn-success';
    WebButton7.ElementFont := efCSS;
    WebButton7.HeightStyle := ssAuto;
    WebButton7.HeightPercent := 100.000000000000000000;
    WebButton7.WidthPercent := 100.000000000000000000;
    WebButton8.SetParentComponent(MenuPnl);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 12;
    WebButton8.Top := 172;
    WebButton8.Width := 249;
    WebButton8.Height := 33;
    WebButton8.Caption := 'Statement';
    WebButton8.ChildOrder := 1;
    WebButton8.ElementClassName := 'btn btn-success';
    WebButton8.ElementFont := efCSS;
    WebButton8.HeightStyle := ssAuto;
    WebButton8.HeightPercent := 100.000000000000000000;
    WebButton8.WidthPercent := 100.000000000000000000;
    WebButton9.SetParentComponent(MenuPnl);
    WebButton9.Name := 'WebButton9';
    WebButton9.Left := 12;
    WebButton9.Top := 211;
    WebButton9.Width := 249;
    WebButton9.Height := 33;
    WebButton9.Caption := 'Reports';
    WebButton9.ChildOrder := 1;
    WebButton9.ElementClassName := 'btn btn-success';
    WebButton9.ElementFont := efCSS;
    WebButton9.HeightStyle := ssAuto;
    WebButton9.HeightPercent := 100.000000000000000000;
    WebButton9.WidthPercent := 100.000000000000000000;
    WebButton4.SetParentComponent(MenuPnl);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 12;
    WebButton4.Top := 55;
    WebButton4.Width := 249;
    WebButton4.Height := 33;
    WebButton4.Caption := 'Catalog';
    WebButton4.ChildOrder := 1;
    WebButton4.ElementClassName := 'btn btn-success';
    WebButton4.ElementFont := efCSS;
    WebButton4.HeightStyle := ssAuto;
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton1.SetParentComponent(MenuPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 12;
    WebButton1.Top := 451;
    WebButton1.Width := 249;
    WebButton1.Height := 33;
    WebButton1.Caption := 'Logout';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn btn-success';
    WebButton1.ElementFont := efCSS;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    XD_StateHead.SetParentComponent(Self);
    XD_StateHead.Name := 'XD_StateHead';
    SetEvent(XD_StateHead, Self, 'OnCalcFields', 'XD_StateHeadCalcFields');
    XD_StateHead.EntitySetName := 'ARSalesStateTot';
    XD_StateHead.Connection := Dm.ApiConnection;
    XD_StateHead.Left := 584;
    XD_StateHead.Top := 520;
    XD_StateHeadAuto.SetParentComponent(XD_StateHead);
    XD_StateHeadAuto.Name := 'XD_StateHeadAuto';
    XD_StateHeadAuto.FieldName := 'Auto';
    XD_StateHeadCusNum.SetParentComponent(XD_StateHead);
    XD_StateHeadCusNum.Name := 'XD_StateHeadCusNum';
    XD_StateHeadCusNum.FieldName := 'CusNum';
    XD_StateHeadTotal.SetParentComponent(XD_StateHead);
    XD_StateHeadTotal.Name := 'XD_StateHeadTotal';
    XD_StateHeadTotal.FieldName := 'Total';
    XD_StateHeadTotal.DisplayFormat := '0.00';
    XD_StateHeadAge1.SetParentComponent(XD_StateHead);
    XD_StateHeadAge1.Name := 'XD_StateHeadAge1';
    XD_StateHeadAge1.FieldName := 'Age1';
    XD_StateHeadAge1.DisplayFormat := '0.00';
    XD_StateHeadAge2.SetParentComponent(XD_StateHead);
    XD_StateHeadAge2.Name := 'XD_StateHeadAge2';
    XD_StateHeadAge2.FieldName := 'Age2';
    XD_StateHeadAge2.DisplayFormat := '0.00';
    XD_StateHeadAge3.SetParentComponent(XD_StateHead);
    XD_StateHeadAge3.Name := 'XD_StateHeadAge3';
    XD_StateHeadAge3.FieldName := 'Age3';
    XD_StateHeadAge3.DisplayFormat := '0.00';
    XD_StateHeadAge4.SetParentComponent(XD_StateHead);
    XD_StateHeadAge4.Name := 'XD_StateHeadAge4';
    XD_StateHeadAge4.FieldName := 'Age4';
    XD_StateHeadAge4.DisplayFormat := '0.00';
    XD_StateHeadAge5.SetParentComponent(XD_StateHead);
    XD_StateHeadAge5.Name := 'XD_StateHeadAge5';
    XD_StateHeadAge5.FieldName := 'Age5';
    XD_StateHeadAge5.DisplayFormat := '0.00';
    XD_StateHeadCreditHoldFlag.SetParentComponent(XD_StateHead);
    XD_StateHeadCreditHoldFlag.Name := 'XD_StateHeadCreditHoldFlag';
    XD_StateHeadCreditHoldFlag.FieldName := 'CreditHoldFlag';
    XD_StateHeadCreditHoldFlag.Size := 1;
    XD_StateHeadCurrent.SetParentComponent(XD_StateHead);
    XD_StateHeadCurrent.Name := 'XD_StateHeadCurrent';
    XD_StateHeadCurrent.FieldKind := fkCalculated;
    XD_StateHeadCurrent.FieldName := 'Current';
    XD_StateHeadCurrent.DisplayFormat := '0.00';
    XD_StateHeadCurrent.Calculated := True;
    XD_StateHeadPastDue.SetParentComponent(XD_StateHead);
    XD_StateHeadPastDue.Name := 'XD_StateHeadPastDue';
    XD_StateHeadPastDue.FieldKind := fkCalculated;
    XD_StateHeadPastDue.FieldName := 'PastDue';
    XD_StateHeadPastDue.DisplayFormat := '0.00';
    XD_StateHeadPastDue.Calculated := True;
    XD_StateDetail.SetParentComponent(Self);
    XD_StateDetail.Name := 'XD_StateDetail';
    XD_StateDetail.EntitySetName := 'ARSalesState';
    XD_StateDetail.Connection := Dm.ApiConnection;
    XD_StateDetail.Left := 592;
    XD_StateDetail.Top := 576;
    XD_StateDetailAuto.SetParentComponent(XD_StateDetail);
    XD_StateDetailAuto.Name := 'XD_StateDetailAuto';
    XD_StateDetailAuto.FieldName := 'Auto';
    XD_StateDetailCustNum.SetParentComponent(XD_StateDetail);
    XD_StateDetailCustNum.Name := 'XD_StateDetailCustNum';
    XD_StateDetailCustNum.FieldName := 'CustNum';
    XD_StateDetailInvDate.SetParentComponent(XD_StateDetail);
    XD_StateDetailInvDate.Name := 'XD_StateDetailInvDate';
    XD_StateDetailInvDate.FieldName := 'InvDate';
    XD_StateDetailInvNum.SetParentComponent(XD_StateDetail);
    XD_StateDetailInvNum.Name := 'XD_StateDetailInvNum';
    XD_StateDetailInvNum.FieldName := 'InvNum';
    XD_StateDetailInvTyp.SetParentComponent(XD_StateDetail);
    XD_StateDetailInvTyp.Name := 'XD_StateDetailInvTyp';
    XD_StateDetailInvTyp.FieldName := 'InvTyp';
    XD_StateDetailInvTyp.Size := 3;
    XD_StateDetailInvRef.SetParentComponent(XD_StateDetail);
    XD_StateDetailInvRef.Name := 'XD_StateDetailInvRef';
    XD_StateDetailInvRef.FieldName := 'InvRef';
    XD_StateDetailInvRef.Size := 16;
    XD_StateDetailInvAmt.SetParentComponent(XD_StateDetail);
    XD_StateDetailInvAmt.Name := 'XD_StateDetailInvAmt';
    XD_StateDetailInvAmt.FieldName := 'InvAmt';
    XD_StateDetailInvAmt.DisplayFormat := '0.00';
    XD_StateDetailInvBal.SetParentComponent(XD_StateDetail);
    XD_StateDetailInvBal.Name := 'XD_StateDetailInvBal';
    XD_StateDetailInvBal.FieldName := 'InvBal';
    XD_StateDetailInvBal.DisplayFormat := '0.00';
    XD_StateDetailAgeing.SetParentComponent(XD_StateDetail);
    XD_StateDetailAgeing.Name := 'XD_StateDetailAgeing';
    XD_StateDetailAgeing.FieldName := 'Ageing';
    XD_StateDetailApplyTo.SetParentComponent(XD_StateDetail);
    XD_StateDetailApplyTo.Name := 'XD_StateDetailApplyTo';
    XD_StateDetailApplyTo.FieldName := 'ApplyTo';
    XD_StateDetailItemBalance.SetParentComponent(XD_StateDetail);
    XD_StateDetailItemBalance.Name := 'XD_StateDetailItemBalance';
    XD_StateDetailItemBalance.FieldName := 'ItemBalance';
    XD_StateDetailItemBalance.DisplayFormat := '0.00';
    WDS_StateHead.SetParentComponent(Self);
    WDS_StateHead.Name := 'WDS_StateHead';
    WDS_StateHead.DataSet := XD_StateHead;
    WDS_StateHead.Left := 672;
    WDS_StateHead.Top := 520;
    WDS_StateDetail.SetParentComponent(Self);
    WDS_StateDetail.Name := 'WDS_StateDetail';
    WDS_StateDetail.DataSet := XD_StateDetail;
    WDS_StateDetail.Left := 680;
    WDS_StateDetail.Top := 576;
  finally
    PnlHeader.AfterLoadDFMValues;
    PnlAcctInfo.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    WebDBLabel2.AfterLoadDFMValues;
    WebDBLabel3.AfterLoadDFMValues;
    WebDBLabel4.AfterLoadDFMValues;
    WebDBLabel5.AfterLoadDFMValues;
    MenuBtn.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebDBResponsiveGrid1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebResponsiveGridPanel1.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebPanel5.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebDBLabel6.AfterLoadDFMValues;
    WebDBLabel7.AfterLoadDFMValues;
    WebDBLabel8.AfterLoadDFMValues;
    WebPanel6.AfterLoadDFMValues;
    WebPanel4.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebDBLabel9.AfterLoadDFMValues;
    WebDBLabel11.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebDBLabel10.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebDBLabel12.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebDBLabel13.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebDBLabel14.AfterLoadDFMValues;
    MenuPnl.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    WebButton9.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    XD_StateHead.AfterLoadDFMValues;
    XD_StateHeadAuto.AfterLoadDFMValues;
    XD_StateHeadCusNum.AfterLoadDFMValues;
    XD_StateHeadTotal.AfterLoadDFMValues;
    XD_StateHeadAge1.AfterLoadDFMValues;
    XD_StateHeadAge2.AfterLoadDFMValues;
    XD_StateHeadAge3.AfterLoadDFMValues;
    XD_StateHeadAge4.AfterLoadDFMValues;
    XD_StateHeadAge5.AfterLoadDFMValues;
    XD_StateHeadCreditHoldFlag.AfterLoadDFMValues;
    XD_StateHeadCurrent.AfterLoadDFMValues;
    XD_StateHeadPastDue.AfterLoadDFMValues;
    XD_StateDetail.AfterLoadDFMValues;
    XD_StateDetailAuto.AfterLoadDFMValues;
    XD_StateDetailCustNum.AfterLoadDFMValues;
    XD_StateDetailInvDate.AfterLoadDFMValues;
    XD_StateDetailInvNum.AfterLoadDFMValues;
    XD_StateDetailInvTyp.AfterLoadDFMValues;
    XD_StateDetailInvRef.AfterLoadDFMValues;
    XD_StateDetailInvAmt.AfterLoadDFMValues;
    XD_StateDetailInvBal.AfterLoadDFMValues;
    XD_StateDetailAgeing.AfterLoadDFMValues;
    XD_StateDetailApplyTo.AfterLoadDFMValues;
    XD_StateDetailItemBalance.AfterLoadDFMValues;
    WDS_StateHead.AfterLoadDFMValues;
    WDS_StateDetail.AfterLoadDFMValues;
  end;
end;

end.
