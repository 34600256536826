unit Orderguide;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.Grids, WEBLib.DBCtrls,
  Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB, Vcl.Grids,
  WEBLib.ExtCtrls, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Menus, WEBLib.Menus,
  WEBLib.JQCtrls, WEBLib.Buttons;

type
  TFrmOrderguide = class(TForm)
    XD_Orderguide: TXDataWebDataSet;
    WDS_Orderguide: TDataSource;
    WebDBResponsiveGrid1: TDBResponsiveGrid;
    WebPanel2: TPanel;
    WebPanel3: TPanel;
    WebPanel1: TPanel;
    WebPanel4: TPanel;
    WebDBLabel5: TDBLabel;
    WebDBLabel6: TDBLabel;
    WebButton1: TButton;
    WebButton2: TButton;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    MenuBtn: TSpeedButton;
    MenuPnl: TPanel;
    WebDBLabel1: TDBLabel;
    WebDBLabel2: TDBLabel;
    WebLabel1: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebButton3: TButton;
    WebButton4: TButton;
    WebButton5: TButton;
    WebButton6: TButton;
    WebButton7: TButton;
    WebButton8: TButton;
    XD_GetTotals: TXDataWebDataSet;
    WDS_GetTotals: TDataSource;
    XD_GetTotalsCust: TIntegerField;
    XD_GetTotalsTotalDollars: TFloatField;
    XD_GetTotalsTotalPcs: TFloatField;
    XD_OrderguideCust: TIntegerField;
    XD_OrderguideProd: TIntegerField;
    XD_OrderguideQty: TIntegerField;
    XD_OrderguideProdUnit: TStringField;
    XD_OrderguideProdSize: TStringField;
    XD_OrderguideProdBrand: TStringField;
    XD_OrderguideProdDescription: TStringField;
    XD_OrderguideProdCWFlag: TStringField;
    XD_OrderguideProdServing: TIntegerField;
    XD_OrderguideProdServingSize: TStringField;
    XD_OrderguideProdServingUnit: TStringField;
    XD_OrderguideProdOnHand: TIntegerField;
    XD_OrderguideProdDueDate: TIntegerField;
    XD_OrderguideProdSlot: TStringField;
    XD_OrderguideProdCat: TIntegerField;
    XD_OrderguideYTDUnits: TIntegerField;
    XD_OrderguideMTDUnits: TIntegerField;
    XD_OrderguidePrice: TFloatField;
    XD_OrderguideLastPurch: TDateTimeField;
    WebDBLabel3: TDBLabel;
    PanelOG: TPanel;
    WebButton9: TButton;
    WebButton10: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure MenuBtnClick(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure XD_GetTotalsAfterOpen(DataSet: TDataSet);
    procedure XD_OrderguideAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebButton7Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FrmOrderguide: TFrmOrderguide;
  ButtonSender:String;
  frm: TForm;

implementation

Uses Data,Catalog,Statement,AccountInfo;

{$R *.dfm}

procedure TFrmOrderguide.WebButton1Click(Sender: TObject);
begin
  WebButton1.Enabled := False;
  WebButton2.Enabled := False;
  if XD_OrderguideQty.AsInteger > 0 then
  begin
    XD_Orderguide.Edit;
    XD_OrderguideQty.AsInteger := XD_OrderguideQty.AsInteger - 1;
    XD_Orderguide.Post;
    XD_Orderguide.ApplyUpdates;
  end
  else
  begin
    WebButton1.Enabled := True;
    WebButton2.Enabled := True;
  end;
  WebDBResponsiveGrid1.Refresh;
end;

procedure TFrmOrderguide.WebButton2Click(Sender: TObject);
begin
  WebButton1.Enabled := False;
  WebButton2.Enabled := False;
  XD_Orderguide.Edit;
  XD_OrderguideQty.AsInteger := XD_OrderguideQty.AsInteger + 1;
  XD_Orderguide.Post;
  XD_Orderguide.ApplyUpdates;
  WebDBResponsiveGrid1.Refresh;
end;

procedure TFrmOrderguide.WebButton4Click(Sender: TObject);
begin
  Application.CreateForm(TFrmCatalog, FrmCatalog);
end;

procedure TFrmOrderguide.WebButton5Click(Sender: TObject);
begin
  Application.CreateForm(TFrmAccountInfo, FrmAccountInfo);
end;

procedure TFrmOrderguide.WebButton7Click(Sender: TObject);
begin
  Application.CreateForm(TFrmStatement, FrmStatement);
end;

procedure TFrmOrderguide.WebFormCreate(Sender: TObject);
begin
  WebPanel4.Left := Round((WebPanel1.Width/2)-(WebPanel4.Width/2));
end;

procedure TFrmOrderguide.WebFormResize(Sender: TObject);
begin
  WebPanel4.Left := Round((WebPanel1.Width/2)-(WebPanel4.Width/2));
  WebDBResponsiveGrid1.Options.ItemWidthMin := WebDBResponsiveGrid1.Width;
end;

procedure TFrmOrderguide.WebFormShow(Sender: TObject);
begin
  XD_Orderguide.QueryString := '$filter=(Cust eq '''+Dm.XD_CustCust.AsString+''' and ProdDescription ne '''')';
  XD_Orderguide.Load;
  ButtonSender := '';
  XD_GetTotals.QueryString := '$filter=(Cust eq '''+Dm.XD_CustCust.AsString+''')';
  XD_GetTotals.Load;
end;

procedure TFrmOrderguide.MenuBtnClick(Sender: TObject);
begin
  MenuPnl.Left := MenuBtn.Left;
  MenuPnl.Top := MenuBtn.Top + MenuBtn.Height + 5;
  MenuPnl.BringToFront;
  if MenuPnl.Visible then MenuPnl.Visible := False
    else MenuPnl.Visible := True;
end;

procedure TFrmOrderguide.XD_GetTotalsAfterOpen(DataSet: TDataSet);
begin
  if XD_GetTotals.RecordCount > 0 then
  begin
    WebLabel5.Caption := IntToStr(XD_GetTotalsTotalPcs.AsInteger);
    WebLabel7.Caption := FloatToStrF(XD_GetTotalsTotalDollars.AsFloat,ffFixed,7,2);
  end
  else
  begin
    WebLabel5.Caption := '0';
    WebLabel7.Caption := '0.00';
  end;
end;

procedure TFrmOrderguide.XD_OrderguideAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
begin
  WebButton1.Enabled := True;
  WebButton2.Enabled := True;
  XD_GetTotals.Close;
  XD_GetTotals.QueryString := '$filter=(Cust eq '''+Dm.XD_CustCust.AsString+''')';
  XD_GetTotals.Load;
end;

procedure TFrmOrderguide.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel2 := TPanel.Create('ogPanelHeaderBG');
  WebPanel3 := TPanel.Create('ogPanelCustInfo');
  WebDBLabel1 := TDBLabel.Create(Self);
  WebDBLabel2 := TDBLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  MenuBtn := TSpeedButton.Create(Self);
  WebPanel1 := TPanel.Create('ogPanelProdDtlBG');
  WebPanel4 := TPanel.Create('ogPanelProdDtl');
  WebDBLabel5 := TDBLabel.Create(Self);
  WebDBLabel6 := TDBLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebDBLabel3 := TDBLabel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  PanelOG := TPanel.Create(Self);
  WebDBResponsiveGrid1 := TDBResponsiveGrid.Create('ogGrid');
  MenuPnl := TPanel.Create('ogPanelMenu');
  WebButton3 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);
  WebButton9 := TButton.Create(Self);
  WebButton10 := TButton.Create(Self);
  XD_Orderguide := TXDataWebDataSet.Create(Self);
  XD_OrderguideCust := TIntegerField.Create(Self);
  XD_OrderguideProd := TIntegerField.Create(Self);
  XD_OrderguideQty := TIntegerField.Create(Self);
  XD_OrderguideProdUnit := TStringField.Create(Self);
  XD_OrderguideProdSize := TStringField.Create(Self);
  XD_OrderguideProdBrand := TStringField.Create(Self);
  XD_OrderguideProdDescription := TStringField.Create(Self);
  XD_OrderguideProdCWFlag := TStringField.Create(Self);
  XD_OrderguideProdServing := TIntegerField.Create(Self);
  XD_OrderguideProdServingSize := TStringField.Create(Self);
  XD_OrderguideProdServingUnit := TStringField.Create(Self);
  XD_OrderguideProdOnHand := TIntegerField.Create(Self);
  XD_OrderguideProdDueDate := TIntegerField.Create(Self);
  XD_OrderguideProdSlot := TStringField.Create(Self);
  XD_OrderguideProdCat := TIntegerField.Create(Self);
  XD_OrderguideYTDUnits := TIntegerField.Create(Self);
  XD_OrderguideMTDUnits := TIntegerField.Create(Self);
  XD_OrderguidePrice := TFloatField.Create(Self);
  XD_OrderguideLastPurch := TDateTimeField.Create(Self);
  WDS_Orderguide := TDataSource.Create(Self);
  XD_GetTotals := TXDataWebDataSet.Create(Self);
  XD_GetTotalsCust := TIntegerField.Create(Self);
  XD_GetTotalsTotalDollars := TFloatField.Create(Self);
  XD_GetTotalsTotalPcs := TFloatField.Create(Self);
  WDS_GetTotals := TDataSource.Create(Self);

  WebPanel2.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  WebDBLabel2.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  MenuBtn.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebPanel4.BeforeLoadDFMValues;
  WebDBLabel5.BeforeLoadDFMValues;
  WebDBLabel6.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebDBLabel3.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  PanelOG.BeforeLoadDFMValues;
  WebDBResponsiveGrid1.BeforeLoadDFMValues;
  MenuPnl.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  WebButton9.BeforeLoadDFMValues;
  WebButton10.BeforeLoadDFMValues;
  XD_Orderguide.BeforeLoadDFMValues;
  XD_OrderguideCust.BeforeLoadDFMValues;
  XD_OrderguideProd.BeforeLoadDFMValues;
  XD_OrderguideQty.BeforeLoadDFMValues;
  XD_OrderguideProdUnit.BeforeLoadDFMValues;
  XD_OrderguideProdSize.BeforeLoadDFMValues;
  XD_OrderguideProdBrand.BeforeLoadDFMValues;
  XD_OrderguideProdDescription.BeforeLoadDFMValues;
  XD_OrderguideProdCWFlag.BeforeLoadDFMValues;
  XD_OrderguideProdServing.BeforeLoadDFMValues;
  XD_OrderguideProdServingSize.BeforeLoadDFMValues;
  XD_OrderguideProdServingUnit.BeforeLoadDFMValues;
  XD_OrderguideProdOnHand.BeforeLoadDFMValues;
  XD_OrderguideProdDueDate.BeforeLoadDFMValues;
  XD_OrderguideProdSlot.BeforeLoadDFMValues;
  XD_OrderguideProdCat.BeforeLoadDFMValues;
  XD_OrderguideYTDUnits.BeforeLoadDFMValues;
  XD_OrderguideMTDUnits.BeforeLoadDFMValues;
  XD_OrderguidePrice.BeforeLoadDFMValues;
  XD_OrderguideLastPurch.BeforeLoadDFMValues;
  WDS_Orderguide.BeforeLoadDFMValues;
  XD_GetTotals.BeforeLoadDFMValues;
  XD_GetTotalsCust.BeforeLoadDFMValues;
  XD_GetTotalsTotalDollars.BeforeLoadDFMValues;
  XD_GetTotalsTotalPcs.BeforeLoadDFMValues;
  WDS_GetTotals.BeforeLoadDFMValues;
  try
    Name := 'FrmOrderguide';
    Width := 1064;
    Height := 772;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 0;
    WebPanel2.Width := 1064;
    WebPanel2.Height := 66;
    WebPanel2.ElementClassName := 'card';
    WebPanel2.HeightPercent := 100.000000000000000000;
    WebPanel2.WidthPercent := 100.000000000000000000;
    WebPanel2.Align := alTop;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsNone;
    WebPanel2.ChildOrder := 2;
    WebPanel2.ElementBodyClassName := 'card-body';
    WebPanel2.ElementFont := efCSS;
    WebPanel3.SetParentComponent(WebPanel2);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 739;
    WebPanel3.Top := 0;
    WebPanel3.Width := 325;
    WebPanel3.Height := 66;
    WebPanel3.ElementClassName := 'card border-0';
    WebPanel3.HeightStyle := ssPercent;
    WebPanel3.HeightPercent := 100.000000000000000000;
    WebPanel3.WidthPercent := 100.000000000000000000;
    WebPanel3.Align := alRight;
    WebPanel3.BorderColor := clSilver;
    WebPanel3.BorderStyle := bsNone;
    WebPanel3.ChildOrder := 1;
    WebPanel3.ElementBodyClassName := 'card-body';
    WebPanel3.ElementFont := efCSS;
    WebDBLabel1.SetParentComponent(WebPanel3);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 3;
    WebDBLabel1.Top := 6;
    WebDBLabel1.Width := 40;
    WebDBLabel1.Height := 18;
    WebDBLabel1.Caption := '99999';
    WebDBLabel1.ElementFont := efCSS;
    WebDBLabel1.HeightStyle := ssAuto;
    WebDBLabel1.HeightPercent := 100.000000000000000000;
    WebDBLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel1.DataField := 'Cust';
    WebDBLabel1.DataSource := Dm.WDS_Cust;
    WebDBLabel2.SetParentComponent(WebPanel3);
    WebDBLabel2.Name := 'WebDBLabel2';
    WebDBLabel2.Left := 56;
    WebDBLabel2.Top := 6;
    WebDBLabel2.Width := 143;
    WebDBLabel2.Height := 18;
    WebDBLabel2.Caption := 'Customer Name Here';
    WebDBLabel2.ElementFont := efCSS;
    WebDBLabel2.HeightStyle := ssAuto;
    WebDBLabel2.HeightPercent := 100.000000000000000000;
    WebDBLabel2.WidthPercent := 100.000000000000000000;
    WebDBLabel2.DataField := 'CustName';
    WebDBLabel2.DataSource := Dm.WDS_Cust;
    WebLabel1.SetParentComponent(WebPanel3);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 26;
    WebLabel1.Top := 30;
    WebLabel1.Width := 83;
    WebLabel1.Height := 18;
    WebLabel1.Caption := 'Total Pieces:';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(WebPanel3);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 119;
    WebLabel5.Top := 30;
    WebLabel5.Width := 32;
    WebLabel5.Height := 18;
    WebLabel5.Caption := '0000';
    WebLabel5.ElementFont := efCSS;
    WebLabel5.HeightStyle := ssAuto;
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(WebPanel3);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 184;
    WebLabel6.Top := 30;
    WebLabel6.Width := 57;
    WebLabel6.Height := 18;
    WebLabel6.Caption := 'Est Amt:';
    WebLabel6.ElementFont := efCSS;
    WebLabel6.HeightStyle := ssAuto;
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(WebPanel3);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 247;
    WebLabel7.Top := 30;
    WebLabel7.Width := 61;
    WebLabel7.Height := 18;
    WebLabel7.Caption := '00000.00';
    WebLabel7.ElementFont := efCSS;
    WebLabel7.HeightStyle := ssAuto;
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.WidthPercent := 100.000000000000000000;
    MenuBtn.SetParentComponent(WebPanel2);
    MenuBtn.Name := 'MenuBtn';
    MenuBtn.Left := 4;
    MenuBtn.Top := 6;
    MenuBtn.Width := 43;
    MenuBtn.Height := 42;
    MenuBtn.Color := clNone;
    MenuBtn.ElementClassName := 'btn btn-dark';
    MenuBtn.ElementFont := efCSS;
    MenuBtn.HeightPercent := 100.000000000000000000;
    MenuBtn.MaterialGlyph := 'menu';
    MenuBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MenuBtn, Self, 'OnClick', 'MenuBtnClick');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 680;
    WebPanel1.Width := 1064;
    WebPanel1.Height := 92;
    WebPanel1.ElementClassName := 'card';
    WebPanel1.HeightPercent := 100.000000000000000000;
    WebPanel1.WidthPercent := 100.000000000000000000;
    WebPanel1.Align := alBottom;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 2;
    WebPanel1.ElementBodyClassName := 'card-body';
    WebPanel1.ElementFont := efCSS;
    WebPanel4.SetParentComponent(WebPanel1);
    WebPanel4.Name := 'WebPanel4';
    WebPanel4.Left := 3;
    WebPanel4.Top := 1;
    WebPanel4.Width := 375;
    WebPanel4.Height := 88;
    WebPanel4.ElementClassName := 'card border-0';
    WebPanel4.HeightStyle := ssPercent;
    WebPanel4.HeightPercent := 100.000000000000000000;
    WebPanel4.WidthPercent := 100.000000000000000000;
    WebPanel4.BorderColor := clSilver;
    WebPanel4.BorderStyle := bsSingle;
    WebPanel4.ChildOrder := 3;
    WebPanel4.ElementBodyClassName := 'card-body';
    WebPanel4.ElementFont := efCSS;
    WebDBLabel5.SetParentComponent(WebPanel4);
    WebDBLabel5.Name := 'WebDBLabel5';
    WebDBLabel5.Left := 3;
    WebDBLabel5.Top := 6;
    WebDBLabel5.Width := 70;
    WebDBLabel5.Height := 18;
    WebDBLabel5.Caption := 'Description';
    WebDBLabel5.ElementClassName := 'text-body';
    WebDBLabel5.ElementFont := efCSS;
    WebDBLabel5.HeightStyle := ssAuto;
    WebDBLabel5.HeightPercent := 100.000000000000000000;
    WebDBLabel5.WidthPercent := 100.000000000000000000;
    WebDBLabel5.DataField := 'ProdDescription';
    WebDBLabel5.DataSource := WDS_Orderguide;
    WebDBLabel6.SetParentComponent(WebPanel4);
    WebDBLabel6.Name := 'WebDBLabel6';
    WebDBLabel6.Left := 136;
    WebDBLabel6.Top := 30;
    WebDBLabel6.Width := 91;
    WebDBLabel6.Height := 18;
    WebDBLabel6.Caption := 'Last Purchase';
    WebDBLabel6.ElementClassName := 'text-body';
    WebDBLabel6.ElementFont := efCSS;
    WebDBLabel6.HeightStyle := ssAuto;
    WebDBLabel6.HeightPercent := 100.000000000000000000;
    WebDBLabel6.WidthPercent := 100.000000000000000000;
    WebDBLabel6.DataField := 'LastPurch';
    WebDBLabel6.DataSource := WDS_Orderguide;
    WebLabel2.SetParentComponent(WebPanel4);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 27;
    WebLabel2.Top := 30;
    WebLabel2.Width := 96;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'Last Purchase:';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.HeightStyle := ssAuto;
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(WebPanel4);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 47;
    WebLabel3.Top := 54;
    WebLabel3.Width := 76;
    WebLabel3.Height := 18;
    WebLabel3.Caption := 'Qty in Cart:';
    WebLabel3.ElementFont := efCSS;
    WebLabel3.HeightStyle := ssAuto;
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel3.SetParentComponent(WebPanel4);
    WebDBLabel3.Name := 'WebDBLabel3';
    WebDBLabel3.Left := 136;
    WebDBLabel3.Top := 54;
    WebDBLabel3.Width := 24;
    WebDBLabel3.Height := 18;
    WebDBLabel3.Caption := 'Qty';
    WebDBLabel3.ElementClassName := 'text-body';
    WebDBLabel3.ElementFont := efCSS;
    WebDBLabel3.HeightStyle := ssAuto;
    WebDBLabel3.HeightPercent := 100.000000000000000000;
    WebDBLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel3.DataField := 'Qty';
    WebDBLabel3.DataSource := WDS_Orderguide;
    WebButton1.SetParentComponent(WebPanel4);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 280;
    WebButton1.Top := 5;
    WebButton1.Width := 41;
    WebButton1.Height := 41;
    WebButton1.Caption := '-';
    WebButton1.ChildOrder := 7;
    WebButton1.ElementClassName := 'btn btn-primary btn-large';
    WebButton1.ElementFont := efCSS;
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -32;
    WebButton1.Font.Name := 'Tahoma';
    WebButton1.Font.Style := [];
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.ParentFont := False;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(WebPanel4);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 327;
    WebButton2.Top := 6;
    WebButton2.Width := 41;
    WebButton2.Height := 41;
    WebButton2.Caption := '+';
    WebButton2.ChildOrder := 7;
    WebButton2.ElementClassName := 'btn btn-primary btn-large';
    WebButton2.ElementFont := efCSS;
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -32;
    WebButton2.Font.Name := 'Tahoma';
    WebButton2.Font.Style := [];
    WebButton2.HeightStyle := ssAuto;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.ParentFont := False;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    PanelOG.SetParentComponent(Self);
    PanelOG.Name := 'PanelOG';
    PanelOG.Left := 0;
    PanelOG.Top := 66;
    PanelOG.Width := 1064;
    PanelOG.Height := 614;
    PanelOG.ElementClassName := 'card';
    PanelOG.HeightPercent := 100.000000000000000000;
    PanelOG.WidthPercent := 100.000000000000000000;
    PanelOG.Align := alClient;
    PanelOG.BorderColor := clSilver;
    PanelOG.BorderStyle := bsSingle;
    PanelOG.ChildOrder := 4;
    PanelOG.ElementBodyClassName := 'card-body';
    PanelOG.ElementFont := efCSS;
    WebDBResponsiveGrid1.SetParentComponent(PanelOG);
    WebDBResponsiveGrid1.Name := 'WebDBResponsiveGrid1';
    WebDBResponsiveGrid1.Left := 0;
    WebDBResponsiveGrid1.Top := 0;
    WebDBResponsiveGrid1.Width := 1064;
    WebDBResponsiveGrid1.Height := 614;
    WebDBResponsiveGrid1.ElementClassName := 'table-responsive';
    WebDBResponsiveGrid1.WidthStyle := ssPercent;
    WebDBResponsiveGrid1.HeightPercent := 100.000000000000000000;
    WebDBResponsiveGrid1.WidthPercent := 100.000000000000000000;
    WebDBResponsiveGrid1.Align := alClient;
    WebDBResponsiveGrid1.ChildOrder := 2;
    WebDBResponsiveGrid1.ElementFont := efCSS;
    WebDBResponsiveGrid1.Font.Charset := DEFAULT_CHARSET;
    WebDBResponsiveGrid1.Font.Color := clWindowText;
    WebDBResponsiveGrid1.Font.Height := -13;
    WebDBResponsiveGrid1.Font.Name := 'Tahoma';
    WebDBResponsiveGrid1.Font.Style := [];
    WebDBResponsiveGrid1.ItemIndex := 0;
    WebDBResponsiveGrid1.Options.ItemBorderColor := clBlack;
    WebDBResponsiveGrid1.Options.ItemGap := 0;
    WebDBResponsiveGrid1.Options.ItemHeight := 36;
    WebDBResponsiveGrid1.Options.ItemSelectedColor := clHighlight;
    WebDBResponsiveGrid1.Options.ItemSelectedTextColor := clWhite;
    WebDBResponsiveGrid1.Options.ItemPadding := 1;
    WebDBResponsiveGrid1.Options.ItemTemplate := '<div style="line-height:1em;"><b>(%Prod%)</b> <span style="float:right;">Price:<b> (%Price%)</b> Qty:<b> (%Qty%)</b> </span><br> (%ProdDescription%)</div>';
    WebDBResponsiveGrid1.Options.ItemWidthMin := 300;
    WebDBResponsiveGrid1.ParentFont := False;
    WebDBResponsiveGrid1.DataSource := WDS_Orderguide;
    MenuPnl.SetParentComponent(Self);
    MenuPnl.Name := 'MenuPnl';
    MenuPnl.Left := 8;
    MenuPnl.Top := 66;
    MenuPnl.Width := 275;
    MenuPnl.Height := 506;
    MenuPnl.ElementClassName := 'card';
    MenuPnl.HeightStyle := ssPercent;
    MenuPnl.HeightPercent := 80.000000000000000000;
    MenuPnl.WidthPercent := 100.000000000000000000;
    MenuPnl.BorderColor := clSilver;
    MenuPnl.BorderStyle := bsSingle;
    MenuPnl.ChildOrder := 2;
    MenuPnl.ElementBodyClassName := 'card-body';
    MenuPnl.ElementFont := efCSS;
    MenuPnl.Visible := False;
    WebButton3.SetParentComponent(MenuPnl);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 12;
    WebButton3.Top := 16;
    WebButton3.Width := 249;
    WebButton3.Height := 33;
    WebButton3.Caption := 'Order Summary';
    WebButton3.ElementClassName := 'btn btn-success';
    WebButton3.ElementFont := efCSS;
    WebButton3.HeightStyle := ssAuto;
    WebButton3.HeightPercent := 100.000000000000000000;
    WebButton3.WidthPercent := 100.000000000000000000;
    WebButton4.SetParentComponent(MenuPnl);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 12;
    WebButton4.Top := 55;
    WebButton4.Width := 249;
    WebButton4.Height := 33;
    WebButton4.Caption := 'Catalog';
    WebButton4.ChildOrder := 1;
    WebButton4.ElementClassName := 'btn btn-success';
    WebButton4.ElementFont := efCSS;
    WebButton4.HeightStyle := ssAuto;
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton5.SetParentComponent(MenuPnl);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 12;
    WebButton5.Top := 94;
    WebButton5.Width := 249;
    WebButton5.Height := 33;
    WebButton5.Caption := 'Account Info';
    WebButton5.ChildOrder := 1;
    WebButton5.ElementClassName := 'btn btn-success';
    WebButton5.ElementFont := efCSS;
    WebButton5.HeightStyle := ssAuto;
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton6.SetParentComponent(MenuPnl);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 12;
    WebButton6.Top := 133;
    WebButton6.Width := 249;
    WebButton6.Height := 33;
    WebButton6.Caption := 'Payment';
    WebButton6.ChildOrder := 1;
    WebButton6.ElementClassName := 'btn btn-success';
    WebButton6.ElementFont := efCSS;
    WebButton6.HeightStyle := ssAuto;
    WebButton6.HeightPercent := 100.000000000000000000;
    WebButton6.WidthPercent := 100.000000000000000000;
    WebButton7.SetParentComponent(MenuPnl);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 12;
    WebButton7.Top := 172;
    WebButton7.Width := 249;
    WebButton7.Height := 33;
    WebButton7.Caption := 'Statement';
    WebButton7.ChildOrder := 1;
    WebButton7.ElementClassName := 'btn btn-success';
    WebButton7.ElementFont := efCSS;
    WebButton7.HeightStyle := ssAuto;
    WebButton7.HeightPercent := 100.000000000000000000;
    WebButton7.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton7Click');
    WebButton8.SetParentComponent(MenuPnl);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 12;
    WebButton8.Top := 211;
    WebButton8.Width := 249;
    WebButton8.Height := 33;
    WebButton8.Caption := 'Reports';
    WebButton8.ChildOrder := 1;
    WebButton8.ElementClassName := 'btn btn-success';
    WebButton8.ElementFont := efCSS;
    WebButton8.HeightStyle := ssAuto;
    WebButton8.HeightPercent := 100.000000000000000000;
    WebButton8.WidthPercent := 100.000000000000000000;
    WebButton9.SetParentComponent(MenuPnl);
    WebButton9.Name := 'WebButton9';
    WebButton9.Left := 12;
    WebButton9.Top := 451;
    WebButton9.Width := 249;
    WebButton9.Height := 33;
    WebButton9.Caption := 'Logout';
    WebButton9.ChildOrder := 1;
    WebButton9.ElementClassName := 'btn btn-success';
    WebButton9.ElementFont := efCSS;
    WebButton9.HeightStyle := ssAuto;
    WebButton9.HeightPercent := 100.000000000000000000;
    WebButton9.WidthPercent := 100.000000000000000000;
    WebButton10.SetParentComponent(MenuPnl);
    WebButton10.Name := 'WebButton10';
    WebButton10.Left := 12;
    WebButton10.Top := 412;
    WebButton10.Width := 249;
    WebButton10.Height := 33;
    WebButton10.Caption := 'Submit Order';
    WebButton10.ChildOrder := 1;
    WebButton10.ElementClassName := 'btn btn-success';
    WebButton10.ElementFont := efCSS;
    WebButton10.HeightStyle := ssAuto;
    WebButton10.HeightPercent := 100.000000000000000000;
    WebButton10.WidthPercent := 100.000000000000000000;
    XD_Orderguide.SetParentComponent(Self);
    XD_Orderguide.Name := 'XD_Orderguide';
    XD_Orderguide.AfterApplyUpdates := XD_OrderguideAfterApplyUpdates;
    XD_Orderguide.EntitySetName := 'ZC_CustOrderguide';
    XD_Orderguide.Connection := Dm.ApiConnection;
    XD_Orderguide.Left := 848;
    XD_Orderguide.Top := 600;
    XD_OrderguideCust.SetParentComponent(XD_Orderguide);
    XD_OrderguideCust.Name := 'XD_OrderguideCust';
    XD_OrderguideCust.FieldName := 'Cust';
    XD_OrderguideCust.Required := True;
    XD_OrderguideProd.SetParentComponent(XD_Orderguide);
    XD_OrderguideProd.Name := 'XD_OrderguideProd';
    XD_OrderguideProd.FieldName := 'Prod';
    XD_OrderguideProd.Required := True;
    XD_OrderguideQty.SetParentComponent(XD_Orderguide);
    XD_OrderguideQty.Name := 'XD_OrderguideQty';
    XD_OrderguideQty.FieldName := 'Qty';
    XD_OrderguideProdUnit.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdUnit.Name := 'XD_OrderguideProdUnit';
    XD_OrderguideProdUnit.FieldName := 'ProdUnit';
    XD_OrderguideProdUnit.Size := 3;
    XD_OrderguideProdSize.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdSize.Name := 'XD_OrderguideProdSize';
    XD_OrderguideProdSize.FieldName := 'ProdSize';
    XD_OrderguideProdSize.Size := 8;
    XD_OrderguideProdBrand.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdBrand.Name := 'XD_OrderguideProdBrand';
    XD_OrderguideProdBrand.FieldName := 'ProdBrand';
    XD_OrderguideProdBrand.Size := 6;
    XD_OrderguideProdDescription.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdDescription.Name := 'XD_OrderguideProdDescription';
    XD_OrderguideProdDescription.FieldName := 'ProdDescription';
    XD_OrderguideProdDescription.Size := 25;
    XD_OrderguideProdCWFlag.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdCWFlag.Name := 'XD_OrderguideProdCWFlag';
    XD_OrderguideProdCWFlag.FieldName := 'ProdCWFlag';
    XD_OrderguideProdCWFlag.Size := 1;
    XD_OrderguideProdServing.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdServing.Name := 'XD_OrderguideProdServing';
    XD_OrderguideProdServing.FieldName := 'ProdServing';
    XD_OrderguideProdServingSize.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdServingSize.Name := 'XD_OrderguideProdServingSize';
    XD_OrderguideProdServingSize.FieldName := 'ProdServingSize';
    XD_OrderguideProdServingSize.Size := 3;
    XD_OrderguideProdServingUnit.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdServingUnit.Name := 'XD_OrderguideProdServingUnit';
    XD_OrderguideProdServingUnit.FieldName := 'ProdServingUnit';
    XD_OrderguideProdServingUnit.Size := 2;
    XD_OrderguideProdOnHand.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdOnHand.Name := 'XD_OrderguideProdOnHand';
    XD_OrderguideProdOnHand.FieldName := 'ProdOnHand';
    XD_OrderguideProdDueDate.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdDueDate.Name := 'XD_OrderguideProdDueDate';
    XD_OrderguideProdDueDate.FieldName := 'ProdDueDate';
    XD_OrderguideProdSlot.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdSlot.Name := 'XD_OrderguideProdSlot';
    XD_OrderguideProdSlot.FieldName := 'ProdSlot';
    XD_OrderguideProdSlot.Size := 6;
    XD_OrderguideProdCat.SetParentComponent(XD_Orderguide);
    XD_OrderguideProdCat.Name := 'XD_OrderguideProdCat';
    XD_OrderguideProdCat.FieldName := 'ProdCat';
    XD_OrderguideYTDUnits.SetParentComponent(XD_Orderguide);
    XD_OrderguideYTDUnits.Name := 'XD_OrderguideYTDUnits';
    XD_OrderguideYTDUnits.FieldName := 'YTDUnits';
    XD_OrderguideMTDUnits.SetParentComponent(XD_Orderguide);
    XD_OrderguideMTDUnits.Name := 'XD_OrderguideMTDUnits';
    XD_OrderguideMTDUnits.FieldName := 'MTDUnits';
    XD_OrderguidePrice.SetParentComponent(XD_Orderguide);
    XD_OrderguidePrice.Name := 'XD_OrderguidePrice';
    XD_OrderguidePrice.FieldName := 'Price';
    XD_OrderguidePrice.DisplayFormat := '0.00';
    XD_OrderguideLastPurch.SetParentComponent(XD_Orderguide);
    XD_OrderguideLastPurch.Name := 'XD_OrderguideLastPurch';
    XD_OrderguideLastPurch.FieldName := 'LastPurch';
    WDS_Orderguide.SetParentComponent(Self);
    WDS_Orderguide.Name := 'WDS_Orderguide';
    WDS_Orderguide.DataSet := XD_Orderguide;
    WDS_Orderguide.Left := 920;
    WDS_Orderguide.Top := 584;
    XD_GetTotals.SetParentComponent(Self);
    XD_GetTotals.Name := 'XD_GetTotals';
    XD_GetTotals.AfterOpen := XD_GetTotalsAfterOpen;
    XD_GetTotals.EntitySetName := 'XS_GetOrderTotals';
    XD_GetTotals.Connection := Dm.ApiConnection;
    XD_GetTotals.Left := 888;
    XD_GetTotals.Top := 496;
    XD_GetTotalsCust.SetParentComponent(XD_GetTotals);
    XD_GetTotalsCust.Name := 'XD_GetTotalsCust';
    XD_GetTotalsCust.FieldName := 'Cust';
    XD_GetTotalsCust.Required := True;
    XD_GetTotalsTotalDollars.SetParentComponent(XD_GetTotals);
    XD_GetTotalsTotalDollars.Name := 'XD_GetTotalsTotalDollars';
    XD_GetTotalsTotalDollars.FieldName := 'TotalDollars';
    XD_GetTotalsTotalDollars.Required := True;
    XD_GetTotalsTotalPcs.SetParentComponent(XD_GetTotals);
    XD_GetTotalsTotalPcs.Name := 'XD_GetTotalsTotalPcs';
    XD_GetTotalsTotalPcs.FieldName := 'TotalPcs';
    XD_GetTotalsTotalPcs.Required := True;
    WDS_GetTotals.SetParentComponent(Self);
    WDS_GetTotals.Name := 'WDS_GetTotals';
    WDS_GetTotals.DataSet := XD_GetTotals;
    WDS_GetTotals.Left := 952;
    WDS_GetTotals.Top := 488;
  finally
    WebPanel2.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    WebDBLabel2.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    MenuBtn.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebPanel4.AfterLoadDFMValues;
    WebDBLabel5.AfterLoadDFMValues;
    WebDBLabel6.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebDBLabel3.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    PanelOG.AfterLoadDFMValues;
    WebDBResponsiveGrid1.AfterLoadDFMValues;
    MenuPnl.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    WebButton9.AfterLoadDFMValues;
    WebButton10.AfterLoadDFMValues;
    XD_Orderguide.AfterLoadDFMValues;
    XD_OrderguideCust.AfterLoadDFMValues;
    XD_OrderguideProd.AfterLoadDFMValues;
    XD_OrderguideQty.AfterLoadDFMValues;
    XD_OrderguideProdUnit.AfterLoadDFMValues;
    XD_OrderguideProdSize.AfterLoadDFMValues;
    XD_OrderguideProdBrand.AfterLoadDFMValues;
    XD_OrderguideProdDescription.AfterLoadDFMValues;
    XD_OrderguideProdCWFlag.AfterLoadDFMValues;
    XD_OrderguideProdServing.AfterLoadDFMValues;
    XD_OrderguideProdServingSize.AfterLoadDFMValues;
    XD_OrderguideProdServingUnit.AfterLoadDFMValues;
    XD_OrderguideProdOnHand.AfterLoadDFMValues;
    XD_OrderguideProdDueDate.AfterLoadDFMValues;
    XD_OrderguideProdSlot.AfterLoadDFMValues;
    XD_OrderguideProdCat.AfterLoadDFMValues;
    XD_OrderguideYTDUnits.AfterLoadDFMValues;
    XD_OrderguideMTDUnits.AfterLoadDFMValues;
    XD_OrderguidePrice.AfterLoadDFMValues;
    XD_OrderguideLastPurch.AfterLoadDFMValues;
    WDS_Orderguide.AfterLoadDFMValues;
    XD_GetTotals.AfterLoadDFMValues;
    XD_GetTotalsCust.AfterLoadDFMValues;
    XD_GetTotalsTotalDollars.AfterLoadDFMValues;
    XD_GetTotalsTotalPcs.AfterLoadDFMValues;
    WDS_GetTotals.AfterLoadDFMValues;
  end;
end;

end.
